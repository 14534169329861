import { Redirect } from 'react-router';
import { isNullOrUndefined } from 'util';
import { useAuthenticatedState } from '../../api/api-authorization/useAuthenticatedState';
import { useSearchParams } from '../../shared/useURLSearchParams';
import { LoadingIndicator } from '../shared/LoadingIndicator';

/**
 * Default / home page.
 */
export const Home = () => {
    // If we have a redirect URL on the query string redirect there.
    const { redirectUrl } = useSearchParams();

    const { isAuthenticated } = useAuthenticatedState();

    // If we don't know if we are logged in or not, wait until we do.
    if (isNullOrUndefined(isAuthenticated)) {
        return (<LoadingIndicator fullWidth />);
    }

    // If we are not logged in, redirect to the start page.
    if (!isAuthenticated) {
        return (<Redirect to={redirectUrl ?? '/start'} />);
    }

    // If we are logged in, redirect to the user's dashboard.
    return (<Redirect to={redirectUrl ?? '/dashboard'} />);
};