import moment from "moment";
import { useCallback, useMemo, } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Table } from "reactstrap";
import { EmptyTableRows } from "../../../shared/EmptyTableRows";
import { YearCalculation } from "../../utilities/useCalculateForYear";
import { formatNumber } from "../../utilities/useFormattedNumber";
import './financialsTab.scss';

export interface FinancialsTabProps {
    inputsModel: any | undefined,
    calculationsModel: any | undefined,
    allYearCalculations: YearCalculation[],
}

/**
 * Tab for maintaining the financial table.
 * @param props
 */
export const FinancialsTab = (props: FinancialsTabProps) => {
    const {
        inputsModel, // all inputs
        calculationsModel, // holds the bottom level calculations created by the calculator from the inputs
        allYearCalculations, // holds all the calculations for all years of the proposal including year zero
    } = props;

    const { t } = useTranslation();

    // Number of years for full calculations - excludes year 0
    // this is just for the financials tab and is different from the proposal years
    const yearColumnsRequired = 30;

    // Array of items to show one on each row in the header column - the first column in the table
    const headerColumnText = useMemo(() => {
        return [
            t('financialsTab.headerColumn.startOfPeriod', 'Start of Period'),
            t('financialsTab.headerColumn.endOfPeriod', 'End of Period'),
            t('financialsTab.headerColumn.financialYear', 'Financial Year'),
            t('financialsTab.headerColumn.ppaSwitch', 'PPA Switch'),
            t('financialsTab.headerColumn.revenue', 'Revenue'),
            t('financialsTab.headerColumn.totalExported', 'Total Exported'),
            t('financialsTab.headerColumn.totalGeneration', 'Total Generation'),
            t('financialsTab.headerColumn.exportTariff', 'Export Tariff'),
            t('financialsTab.headerColumn.ppaTariff', 'PPA Tariff'),
            t('financialsTab.headerColumn.revenue', 'Revenue'),
            t('financialsTab.headerColumn.revenue', 'Revenue'),
            t('financialsTab.headerColumn.expenses', 'Expenses'),
            t('financialsTab.headerColumn.oAndM', 'O&M'),
            t('financialsTab.headerColumn.oAndMManager', 'O&M Manager'),
            t('financialsTab.headerColumn.businessRates', 'Business Rates'),
            t('financialsTab.headerColumn.transmissionFee', 'Transmission Fee'),
            t('financialsTab.headerColumn.landLease', 'Land Lease'),
            t('financialsTab.headerColumn.insurance', 'Insurance'),
            t('financialsTab.headerColumn.audit', 'Audit'),
            t('financialsTab.headerColumn.managementFee', 'Management Fee'),
            t('financialsTab.headerColumn.refurbishmentFee', 'Refurbishment Fee'),
            t('financialsTab.headerColumn.totalExpenses', 'Total Expenses'),
            t('financialsTab.headerColumn.ebitda', 'EBITDA'),
            t('financialsTab.headerColumn.depreciation', 'Depreciation'),
            t('financialsTab.headerColumn.ebit', 'EBIT'),
            t('financialsTab.headerColumn.interestExpense', 'Interest Expense'),
            t('financialsTab.headerColumn.ebt', 'EBT'),
            t('financialsTab.headerColumn.taxOutlay', 'Tax Outlay'),
            t('financialsTab.headerColumn.netIncome', 'Net Income'),
        ]
    }, [t]);

    // Array of items to show one on each row in the unit column - the second column in the table
    const unitHeaderColumnText = useMemo(() => {
        return [
            '',
            '',
            '',
            '',
            '',
            '',
            t('common.kWh', 'kWh'),
            t('common.pencekWh', 'pence/kWh'),
            t('common.pencekWh', 'pence/kWh'),
            t('common.gbp', 'GBP'),
            '',
            '',
            t('common.gbp', 'GBP'),
            t('common.gbp', 'GBP'),
            t('common.gbp', 'GBP'),
            t('common.gbp', 'GBP'),
            t('common.gbp', 'GBP'),
            t('common.gbp', 'GBP'),
            t('common.gbp', 'GBP'),
            t('common.gbp', 'GBP'),
            t('common.gbp', 'GBP'),
            t('common.gbp', 'GBP'),
            t('common.gbp', 'GBP'),
            t('common.gbp', 'GBP'),
            t('common.gbp', 'GBP'),
            t('common.gbp', 'GBP'),
            t('common.gbp', 'GBP'),
            t('common.gbp', 'GBP'),
            t('common.gbp', 'GBP'),
        ]
    }, [t]);

    // Array of all full years' data, formatted and converted to strings with '-' representing 0 for display fields
    // This is ready for this tab, the same data is formatted differently for other tabs
    const columnsYears = useMemo(() => {

        // data for the table
        let newColumnData: Array<FinancialsColumnData> = [];

        // loop round creating a column for each full year
        for (let y = 1; y <= yearColumnsRequired; y++) {

            // this year's figures that are based on base calculations and inputs
            const yearCalculations = allYearCalculations[y];

            // make up a column for this year's figures for the table
            let newColumn: FinancialsColumnData = ({
                fieldcenter_startOfPeriod: !!inputsModel?.startDate ? moment(inputsModel?.startDate).add(y - 1, 'years').format('L') : '-',
                fieldcenter_endOfPeriod: !!inputsModel?.startDate ? moment(inputsModel?.startDate ?? '').add(y, 'years').subtract(1, 'days').format('L') : '-',
                fieldcenter_financialYear: !!inputsModel?.startDate ? moment(inputsModel?.startDate ?? '').add(y - 1, 'years').format('YYYY') : '-',
                fieldcenter_ppaSwitch: inputsModel?.ppaTerm >= y ? t('common.true', 'TRUE') : t('common.false', 'FALSE'),
                headerline_revenue: '',
                totalExported: yearCalculations.totalExported !== 0 ? formatNumber(Math.round(yearCalculations.totalExported)) : '-',
                totalGeneration: yearCalculations.totalGeneration !== 0 ? formatNumber(Math.round(yearCalculations.totalGeneration)) : '-',
                exportTarrif: yearCalculations.exportTarrif !== 0 ? formatNumber((Math.round(yearCalculations.exportTarrif * 100) / 100), { isDecimals: true }) : '-',
                ppaTariff: yearCalculations.ppaTariff !== 0 ? formatNumber((Math.round(yearCalculations.ppaTariff * 100) / 100), { isDecimals: true }) : '-',
                revenue: yearCalculations.revenue !== 0 ? formatNumber((Math.round(yearCalculations.revenue))) : '-',
                totalline_revenue: yearCalculations.revenue !== 0 ? formatNumber((Math.round(yearCalculations.revenue))) : '-',
                headerline_expenses: '',
                oAndM: yearCalculations.oAndM !== 0 ? formatNumber(Math.round(yearCalculations.oAndM)) : '-',
                oAndMManager: yearCalculations.oAndMManager !== 0 ? formatNumber(Math.round(yearCalculations.oAndMManager)) : '-',
                businessRates: yearCalculations.businessRates !== 0 ? formatNumber(Math.round(yearCalculations.businessRates)) : '-',
                transmissionFee: yearCalculations.transmissionFee !== 0 ? formatNumber(Math.round(yearCalculations.transmissionFee)) : '-',
                landLease: yearCalculations.landLease !== 0 ? formatNumber(Math.round(yearCalculations.landLease)) : '-',
                insurance: yearCalculations.insurance !== 0 ? formatNumber(Math.round(yearCalculations.insurance)) : '-',
                audit: yearCalculations.audit !== 0 ? formatNumber(Math.round(yearCalculations.audit)) : '-',
                managementFee: yearCalculations.managementFee !== 0 ? formatNumber(Math.round(yearCalculations.managementFee)) : '-',
                refurbishmentFee: yearCalculations.refurbishmentFee !== 0 ? formatNumber(Math.round(yearCalculations.refurbishmentFee)) : '-',
                totalExpenses: yearCalculations.totalExpenses !== 0 ? formatNumber(Math.round(yearCalculations.totalExpenses)) : '-',
                totalline_ebitda: yearCalculations.ebitda !== 0 ? formatNumber(Math.round(yearCalculations.ebitda)) : '-',
                depreciation: inputsModel?.depreciationYears >= y && calculationsModel?.depreciation !== 0 ? formatNumber(Math.round(calculationsModel?.depreciation)) : '-',
                totalline_ebit: yearCalculations.ebit !== 0 ? formatNumber(Math.round(yearCalculations.ebit)) : '-',
                interestExpense: yearCalculations.interestExpense > 0 ? formatNumber(Math.round(yearCalculations.interestExpense)) : '-',
                totalline_ebt: yearCalculations.ebt !== 0 ? formatNumber(Math.round(yearCalculations.ebt)) : '-',
                taxOutlay: yearCalculations.taxOutlay !== 0 ? formatNumber(Math.round(yearCalculations.taxOutlay)) : '-',
                totalline_netIncome: yearCalculations.netIncome !== 0 ? formatNumber(Math.round(yearCalculations.netIncome)) : '-',
            });

            // add the new column to the table data array 
            newColumnData.push(newColumn);
        }

        // return an array for the table holding a column for each year required
        return newColumnData;
    }, [inputsModel, calculationsModel, allYearCalculations, t]);

    // a way to work out the number of rows required in each table - excludes empty rows
    const rows = useMemo(() => {
        if (columnsYears.length < 1) {
            return 0;
        }
        return Object.keys(columnsYears[0]).length;
    }, [columnsYears]);

    // empty rows to be generated - key is index of row before, value is number of empty rows required
    const emptyRowsToBeGenerated = useMemo(() => {
        return [
            { key: 3, value: 3 },
            { key: 9, value: 1 },
            { key: 10, value: 1 },
            { key: 11, value: 1 },
            { key: 12, value: 1 },
            { key: 21, value: 1 },
            { key: 22, value: 1 },
            { key: 23, value: 1 },
            { key: 24, value: 1 },
            { key: 25, value: 1 },
            { key: 26, value: 1 },
            { key: 27, value: 1 },
        ]
    }, []);

    // a populated table row of as many columns as needed together with empty rows as appropriate
    const PopulatedTableRow = useCallback((props: { fullFinancialYearColumns: number, columnDataFieldIndex: number }) => {
        const {
            fullFinancialYearColumns, columnDataFieldIndex
        } = props;
        const emptyRows = emptyRowsToBeGenerated.find(item => item.key === columnDataFieldIndex)?.value ?? 0;


        // check for any of the special cases defined by the prefix to the field name
        // we can use special line prefixes on the tr, or the td if they are to be applied selectively and not every cell on the row
        let thisRowClassName = '';
        if (Object.keys(columnsYears[0])[columnDataFieldIndex].startsWith('totalline_')) {
            /* total line has colored background and bold text and double underline */
            thisRowClassName = 'totalline';
        } else if (Object.keys(columnsYears[0])[columnDataFieldIndex].startsWith('headerline_')) {
            /* header with bold text */
            thisRowClassName = 'headerline'
        }
        if (columnDataFieldIndex === Object.keys(columnsYears[0]).length - 1 && thisRowClassName === 'totalline') {
            /* last row of table which we know is a total line */
            thisRowClassName = 'totallinefinal'
        }

        // apply special field prefixes to the td when required 
        let thisFieldClassName = 'yearColumn';
        if (Object.keys(columnsYears[0])[columnDataFieldIndex].startsWith('fieldcenter_')) {
            thisFieldClassName += ' fieldcenter';
        }

        return (
            <>
                <tr className={thisRowClassName}>
                    {/* header column */}
                    <td>{headerColumnText[columnDataFieldIndex]}</td>
                    {/* sub-header column */}
                    <td>{unitHeaderColumnText[columnDataFieldIndex]}</td>
                    {/* year zero column */}
                    <td className={thisFieldClassName} >{!inputsModel?.startDate || columnDataFieldIndex !== 1 ? '' : moment(inputsModel?.startDate ?? '').subtract(1, 'days').format('L')}</td>
                    {
                        /* full financial column for a year */
                        [...Array(fullFinancialYearColumns)].map((value: undefined, index) => {
                            return (
                                <td className={thisFieldClassName} key={index}>
                                    {Object.values(columnsYears[index])[columnDataFieldIndex]}
                                </td>
                            );
                        })
                    }
                </tr>
                <EmptyTableRows rows={emptyRows} columns={33} />
            </>
        )
    }, [headerColumnText, unitHeaderColumnText, inputsModel, columnsYears, emptyRowsToBeGenerated, ]);

    /** UI
     **/
    return (
        <>
            <Row className="financials-container">
                <Col>
                    <div className="financials">
                        <div className="table-responsive">

                            {/* Table */}
                            <Table className="table-striped table-bordered">
                                <thead className="thead-dark">
                                    <tr>
                                        <th className="headerColumn">{t('financialsTab.headerColumn.year', 'Year')}</th>
                                        <th className="unitColumn"> {/*intentionally blank*/}</th>
                                        {
                                            /* one column for each of the numbered years plus a first one for year 0 */
                                            [...Array(yearColumnsRequired + 1)].map((value: undefined, index) => {
                                                return (
                                                    <th className="yearColumn" key={index}>
                                                        {index}
                                                    </th>
                                                );
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        [...Array(rows)].map((value: undefined, index) => {

                                            /* could return a single row or a row plus a number of empty rows */
                                            return (
                                                <PopulatedTableRow key={index} fullFinancialYearColumns={yearColumnsRequired} columnDataFieldIndex={index} />
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};


// in the column data below there are some special prenames for fields:
// fieldcenter_ adds a class that causes data to be text align center when displayed in a table cell
// headerline_ adds a class that causes the row to be displayed as an internal header - usually just increasing the font weight
// totalline_ adds a class that causes the row to be displayed differently - usually background coplor and double underline
// calculation_ for calculation not display

/**
 * Data for a column in the table
 */
export interface FinancialsColumnData {
    fieldcenter_startOfPeriod: string,
    fieldcenter_endOfPeriod: string,
    fieldcenter_financialYear: string,
    fieldcenter_ppaSwitch: string,
    headerline_revenue: string,
    totalExported: string,
    totalGeneration: string,
    exportTarrif: string,
    ppaTariff: string,
    revenue: string,
    totalline_revenue: string,
    headerline_expenses: string,
    oAndM: string,
    oAndMManager: string,
    businessRates: string,
    transmissionFee: string,
    landLease: string,
    insurance: string,
    audit: string,
    managementFee: string,
    refurbishmentFee: string,
    totalExpenses: string,
    totalline_ebitda: string,
    depreciation: string,
    totalline_ebit: string,
    interestExpense: string,
    totalline_ebt: string,
    taxOutlay: string,
    totalline_netIncome: string
}

