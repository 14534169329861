import { RouteEntry } from "../shared/routes";
import { apiAuthorizationRoutes } from "../components/api-authorization/routes";
import { accountRoutes } from "../components/account/routes";
import { profileRoutes } from "../components/profiles/routes";
import { NotFound } from "../components/shared/NotFound";
import { homeRoutes } from "../components/home/routes";
import { administrationRoutes, administrationSidebarRoutes } from "../components/administration/routes";
import { administratorsRoutes } from "../components/users/administrators/routes";
import { dashboardRoutes } from "../components/dashboard/routes";
import { financialModelRoutes } from "../components/financialModels/routes";
import { registeredUsersRoutes } from "../components/users/registeredUsers/routes";
import { startRoutes } from "../components/start/routes";
import { baseValueRoutes } from "../components/baseValues/routes";

export const appRoutes: Array<RouteEntry> = [
    ...apiAuthorizationRoutes,
    ...accountRoutes,
    ...homeRoutes,
    ...profileRoutes,

    ...administrationRoutes,
    ...administratorsRoutes,
    ...registeredUsersRoutes,
    ...dashboardRoutes,
    ...startRoutes,

    ...financialModelRoutes,
    ...baseValueRoutes,

    { path: undefined, component: NotFound }
];

export const sidebarRoutes: Array<RouteEntry> = [
    ...administrationSidebarRoutes,
];