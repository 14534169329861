import './footer.scss';
import { AppFooter } from '@coreui/react';
import { Col, Nav, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

export const Footer = () => {
    const { t } = useTranslation();

    return (
        <AppFooter className="footer">
            <Row className="call-to-action-container">
                <Col xs={12}>
                    <a className="btn pink-button" target="_blank" rel="noreferrer" href={`https://www.olympuspower.co.uk/contact-us/`}> {t('footer.contact', 'Get in touch')}</a>
                    {/*<a className="btn black-button" target="_blank" rel="noreferrer" href={`https://knowledge.olympuspower.co.uk/helpcenter`}> {t('footer.help', 'Help Centre')}</a>*/}
                </Col>
            </Row>
            <Row className="footer-columns-container">
                <Col className="footer-col footer-col-1" xs={12} sm={4}>
                    {t('footer-address', 'The Sustainability Hub, Exeter EX6 7BE')}
                    <br/>
                    {t('footer-company', 'Company Number 9682843')}
                </Col>
                <Col className="footer-col footer-col-2" xs={12} sm={4}>
                    {t('footer-phone','01392 549 700')}
                </Col>
                <Col className="footer-col footer-col-3" xs={12} sm={4}>
                    <a target="_blank" rel="noreferrer" href={`https://lp.olympuspower.co.uk/standard-terms-conditions`}> {t('footer-terms', 'Terms and Conditions')}</a>
                </Col>
            </Row>

            <Nav className="footer-nav">
            </Nav>
        </AppFooter>
    );
};

