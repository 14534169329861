import * as React from 'react';
import { BaseValueCreateInput, BaseValueUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateBaseValueCallback } from './useCreateBaseValueCallback';
import { useUpdateBaseValueCallback } from './useUpdateBaseValueCallback';
import { BaseValue } from '../models/BaseValue';

/**
 * Get a callback to save a BaseValue in the store using either a create or update.
 */
export function useSaveBaseValueCallback(options: SaveInStoreOptions<BaseValue, string> = {}) {
    const [_create, createStatus] = useCreateBaseValueCallback();
    const create = React.useCallback((model: Partial<BaseValue>) => _create(model as BaseValueCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateBaseValueCallback();
    const update = React.useCallback((id: string, changes: Partial<BaseValue>) => _update(id, changes as BaseValueUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
