import * as React from "react";
import { Row, Col, Form, Alert, FormGroup, Label, Spinner, FormText, Card, CardHeader, CardBody } from "reactstrap";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { Link, useHistory, useParams } from "react-router-dom";
import { useChanges } from "../../shared/useChanges";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync"
import { LoadingIndicator } from "../../components/shared/LoadingIndicator";
import { useExternalAuthenticationSchemes, useStartExternalLoginCallback, useRegisterAccountCallback, useResendConfirmationEmailCallback } from "../../api/account";
import { Register as RegisterModel } from '../../api/account/models/Register';
import { useTranslation } from "react-i18next";
import { ExternalLoginButton } from "./ExternalLoginButton";
import { FormButtons } from "../shared/FormButtons";
import { usePasswordValidation } from "../../shared/passwordValidation";
import { RegisterProfileDetails, useRegisterProfileDetailsProps } from "../profiles/RegisterProfileDetails";
import { ConditionalFragment } from "react-conditionalfragment";
import { MainContainer } from "../shared/MainContainer";
import { Background } from "../shared/background/Background";

/**
 * Register screen.
 */
export const Register = () => {
    const { t } = useTranslation();

    const { financialModelId } = useParams<{ financialModelId: string | undefined }>();

    const { data: { externalAuthenticationSchemes }, isLoading, errors: loadErrors } = useExternalAuthenticationSchemes();
    const [register, { isExecuting: isRegistering, errors: registerErrors }] = useRegisterAccountCallback();
    const [startExternalLogin] = useStartExternalLoginCallback();
    const [isPendingEmailConfirmation, setIsPendingEmailConfirmation] = React.useState<boolean>(false);
    const [resendConfirmationEmail, { isExecuting: isResendingConfirmationEmail, errors: resendConfirmationEmailErrors }] = useResendConfirmationEmailCallback();
    const history = useHistory();

    const { checkPassword, passwordRequirements } = usePasswordValidation();

    const { model, change } = useChanges<RegisterModel>({ email: '', password: '', confirmPassword: '' });

    const registerProfileDetailsProps = useRegisterProfileDetailsProps();

    // Need the URL to pass on to registration or 2faif we redirect to it.
    const params = new URLSearchParams(window.location.search);
    const returnUrl = params.get('returnUrl') ?? params.get('ReturnUrl') ?? '';
 
    /**
     * Validate the model before trying to use it.
     */
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            email: () => !model.email ? t('register.emailRequired', 'Email is required') : '',
            password: () => !model.password ? t('register.passwordRequired', 'Password is required')
                : checkPassword(model.password).errorDescription,
            confirmPassword: () => model.confirmPassword !== model.password ? t('register.passwordsDoNotMatch', 'The password and confirmation password do not match') : '',
        };

        validation.checkRules(rules, fieldsToCheck);

        // Check the profile details as well (ensuring an error reflects in our error state).
        validation.singleCheck('profileDetails', !registerProfileDetailsProps.validate(fieldsToCheck), 'Profile details has some validation errors');
    }, [model, registerProfileDetailsProps.validate]);

    /**
     * Perform a login by and handle the result.
     */
    const [isDoingFullPageRedirect, setIsDoingFullPageRedirect] = React.useState<boolean>(false);
    const performRegister = React.useCallback(async (): Promise<void> => {
        if (!validate()) {
            return;
        }

        const result = await register(model);

        if (result) {
            setIsPendingEmailConfirmation(result.requiresEmailConfirmation);

            // Save the profile.
            await registerProfileDetailsProps.registerProfile(result.userId, financialModelId);

            // Now send the initial email confirmation as we have the profile saved, so can address the user by name.
            await resendConfirmationEmail(model.email);

            if (result.requiresTwoFactor) {
                setIsDoingFullPageRedirect(true);
                history.push(`/account/loginWithTwoFactor?returnUrl=${encodeURIComponent(result.returnUrl)}`);
            }

            // Redirect the whole page (not just the react app) as its likely the returnUrl is handled on the server.
            if (result.succeeded) {
                // Redirect the whole page (not just react) to the returnUrl to let the server handle as well as the client.
                if (!result.requiresEmailConfirmation && !result.requiresTwoFactor) {
                    setIsDoingFullPageRedirect(true);
                    window.location.href = result.returnUrl;
                }
            }
        }
    }, [register, model, setIsPendingEmailConfirmation, history, validate, registerProfileDetailsProps, setIsDoingFullPageRedirect, resendConfirmationEmail, financialModelId]);
    
    // Render the UI.
    return (
        <Background centerChildren="vertically">
            <MainContainer color="transparent">
                <Card>
                    <CardHeader>
                        <h1>
                            {t('register.registerNewAccountHeading', 'Register a new account')}
                        </h1>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md="">
                                <Form onSubmit={async e => { e.preventDefault(); await performRegister(); }}>
                                    <AlertOnErrors simple errors={[loadErrors, registerErrors, resendConfirmationEmailErrors, registerProfileDetailsProps.registerProfileErrors]} />

                                    <ConditionalFragment showIf={isPendingEmailConfirmation}>
                                        <Alert color="success" >
                                            <Row>
                                                <Col>
                                                    {t('register.confirmationEmailHasBeenResent', 'Please check your email to confirm your account.  You won\'t be able to login until you have confirmed your account.')}
                                                </Col>
                                                <Col xs="auto">
                                                    <ButtonAsync type="button" color="success" onClick={async e => { e.preventDefault(); await resendConfirmationEmail(model.email); }}
                                                        isExecuting={isResendingConfirmationEmail}
                                                        executingChildren={<><Spinner size="sm" />{t('common.sending', 'Sending...')}</>}>
                                                        {t('common.resendEmail', 'Resend email')}
                                                    </ButtonAsync>
                                                </Col>
                                            </Row>
                                        </Alert>
                                    </ConditionalFragment>

                                    <RegisterProfileDetails {...registerProfileDetailsProps}>
                                        <FormGroup>
                                            <Label htmlFor="email">{t('register.email', 'Email')}</Label>
                                            <ValidatedInput type="email" name="email" autoComplete="username" value={model.email} onChange={e => change({ email: e.currentTarget.value })} onBlur={e => validate('email')} validationErrors={validationErrors['email']} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label htmlFor="password">{t('register.password', 'Password')}</Label>
                                            <ValidatedInput type="password" name="password" autoComplete="new-password" value={model.password} onChange={e => change({ password: e.currentTarget.value })} onBlur={e => validate('password')} validationErrors={validationErrors['password']} />
                                            <FormText color="white">
                                                {passwordRequirements}
                                            </FormText>
                                        </FormGroup>
                                        <FormGroup>
                                            <Label htmlFor="confirmPassword">{t('register.confirmPassword', 'Confirm password')}</Label>
                                            <ValidatedInput type="password" name="confirmPassword" autoComplete="new-password" value={model.confirmPassword} onChange={e => change({ confirmPassword: e.currentTarget.value })} onBlur={e => validate('confirmPassword')} validationErrors={validationErrors['confirmPassword']} />
                                        </FormGroup>
                                    </RegisterProfileDetails>
                                    


                                    {/* Repeated because the height of the form on screen is too long for the user to see this at the top */}
                                    <ConditionalFragment showIf={isPendingEmailConfirmation}>
                                        <Alert color="success" >
                                            <Row>
                                                <Col>
                                                    {t('register.confirmationEmailHasBeenResent', 'Please check your email to confirm your account.  You won\'t be able to login until you have confirmed your account.')}
                                                </Col>
                                                <Col xs="auto">
                                                    <ButtonAsync type="button" color="success" onClick={async e => { e.preventDefault(); await resendConfirmationEmail(model.email); }}
                                                        isExecuting={isResendingConfirmationEmail}
                                                        executingChildren={<><Spinner size="sm" />{t('common.sending', 'Sending...')}</>}>
                                                        {t('common.resendEmail', 'Resend email')}
                                                    </ButtonAsync>
                                                </Col>
                                            </Row>
                                        </Alert>
                                    </ConditionalFragment>

                                    <FormButtons>
                                        <Row>
                                            <Col>
                                                <Link to={`/account/login?returnUrl=${encodeURIComponent(returnUrl)}`}>
                                                    {t('register.login', 'Already have an account?  Sign in instead.')}
                                                </Link>
                                            </Col>
                                            <Col xs="auto">
                                                <ButtonAsync type="submit" color="primary" disabled={isPendingEmailConfirmation}
                                                    isExecuting={isRegistering || isDoingFullPageRedirect}
                                                    executingChildren={<><Spinner size="sm" /> {t('register.registering', 'Registering...')}</>}>
                                                    {t('register.register', 'Register')}
                                                </ButtonAsync>
                                            </Col>
                                        </Row>
                                    </FormButtons>
                                </Form>
                            </Col>
                            <ConditionalFragment showIf={!!externalAuthenticationSchemes?.length}>
                                <Col md={4}>
                                    {
                                        isLoading ? (
                                            <LoadingIndicator />
                                        ) : (
                                            <>
                                                <h6>{t('register.useExternalService', 'Or register using an identity provider')}</h6>
                                                <div>
                                                    {
                                                        !externalAuthenticationSchemes ? null
                                                            : externalAuthenticationSchemes.length !== 0 ? (
                                                                <>
                                                                    {
                                                                        externalAuthenticationSchemes.map((item) => (
                                                                            <div key={item.name}>
                                                                                <ExternalLoginButton provider={item.name} providerDisplayName={item.displayName} type="button" onClick={() => startExternalLogin(item.name, returnUrl)} />
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </>
                                                            ) : null
                                                    }
                                                </div>
                                            </>
                                        )
                                    }
                                </Col>
                            </ConditionalFragment>
                        </Row>
                    </CardBody>
                </Card>
            </MainContainer>
        </Background>
    );
};
