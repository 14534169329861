import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { FinancialModelCreateUnauthenticatedInput } from '../generated/globalTypes';
import { createFinancialModelUnauthenticatedMutation, createFinancialModelUnauthenticatedMutationVariables } from '../generated/createFinancialModelUnauthenticatedMutation';
import { financialModelFieldsFragment } from '../models/FinancialModel';

/**
 * Get a callback to create a FinancialModel in the store when the user is Unauthenticated.
 */
export function useCreateFinancialModelUnauthenticatedCallback(): [(model: FinancialModelCreateUnauthenticatedInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createFinancialModelUnauthenticatedMutation, createFinancialModelUnauthenticatedMutationVariables>(
        gql`
            mutation createFinancialModelUnauthenticatedMutation ($model: FinancialModelCreateUnauthenticatedInput!) {
                createFinancialModelUnauthenticated(model: $model) {
                    ...financialModelFields
                }
            }

            ${financialModelFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: FinancialModelCreateUnauthenticatedInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
