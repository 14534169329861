import { useCurrentUser } from "../../api/main/users/useCurrentUser";
import { Dashboard } from "./Dashboard";

/**
 * Dashboard for a the current user.
 */
export const MyDashboard = () => {
    const user = useCurrentUser();

    if (!user) {
        return null;
    }

    return (
        <Dashboard userId={user.id} />
        )
};