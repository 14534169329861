import { Button, Row, Col, Label, FormGroup, Spinner, NavItem, NavLink } from 'reactstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { useParams, useHistory } from 'react-router';
import { useFinancialModel } from '../../api/main/financialModels/useFinancialModel';
import { useChanges } from '../../shared/useChanges';
import { useSaveFinancialModelCallback } from '../../api/main/financialModels/useSaveFinancialModelCallback';
import { useValidatorCallback } from 'pojo-validator-react';
import { ValidatedInput } from 'pojo-validator-reactstrap';
import { ButtonAsync } from 'reactstrap-buttonasync';
import { useAsyncCallback } from 'react-use-async-callback';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { Background } from '../shared/background/Background';
import { financialModelDefaultValues } from '../../api/main/models/FinancialModel';
import { useCurrentUser } from '../../api/main/users/useCurrentUser';
import { useJsonObject } from '../../shared/useJsonObject';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { FinancialModelCalculator } from './FinancialModelCalculator';
import { useDebouncedCallback } from 'use-debounce/lib';
import { StickyToolbar } from '../shared/StickyToolbar';
import './editFinancialModel.scss';
import { PillsNavBar } from '../shared/pillsNavBar/PillsNavBar';
import { AssumptionsTab } from './edit/assumptions/AssumptionsTab';
import { ClientFacingTab } from './edit/clientFacing/ClientFacingTab';
import { ModelTab } from './edit/model/ModelTab';
import { FinancialsTab } from './edit/financials/FinancialsTab';
import { ValuationTab } from './edit/valuation/ValuationTab';
import { ProposalsTab } from './edit/proposals/ProposalTab';
import { AuthorizeContainer } from '../../shared/authorizeRoute';
import { IdentityRoles } from '../../configure/security/IdentityRoles';
import { calculateForYear, YearCalculation, yearCalculationDefaultValues } from './utilities/useCalculateForYear';
import { calculateIRR, } from './utilities/useIRRCalculator';
import { useBaseValuesUnauthenticated } from '../../api/main/baseValues/useBaseValuesUnauthenticated';
import { Footer } from '../layout/Footer';
import { useSaveFinancialModelUnauthenticatedCallback } from '../../api/main/financialModels/useSaveFinancialModelUnauthenticatedCallback';
import moment from 'moment';

interface EditFinancialModelProps {
    isCreate?: boolean,
}

/**
 * Create and invite a new financialModel.
 */
export const CreateFinancialModel = () => (<EditFinancialModel isCreate={true} />);

/**
 * Edit a financialModel.
 */
export const EditFinancialModel = (props: EditFinancialModelProps) => {
    const { isCreate } = props;
    const { t } = useTranslation();
    const { id, userId: paramUserId } = useParams<{ id: string | undefined, userId: string | undefined, }>();

    // use lazy here because the user may not be logged in and for create we don't get any data anyway
    const { data: { model: storeModel }, isLoading: _isLoading, errors: loadErrors, refresh: loadRefresh } = useFinancialModel(id, { lazy: true });
    // if we are editing we need to really load the data 
    useEffect(() => {
        if (!isCreate) {
            // load the data
            loadRefresh();
        }

    }, [isCreate, loadRefresh]);

    // lookup all the baseValues
    const { data: { items: allBaseValues }, isLoading: _isBaseLoading, errors: loadBaseErrors, } = useBaseValuesUnauthenticated();

    const isLoading = _isLoading || _isBaseLoading;
    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...financialModelDefaultValues(), userId: paramUserId, } : undefined);

    const [saveUnauthenticated, { errors: saveUnauthenticatedErrors }] = useSaveFinancialModelUnauthenticatedCallback();
    const [save, { errors: saveErrors }] = useSaveFinancialModelCallback();
    const history = useHistory();

    // Default new records to the current user.
    const currentUser = useCurrentUser();
    useEffect(() => {
        // If we are not a create or not ready, do nothing.
        if (!isCreate
            || ! currentUser
            || !model
        ) {
            return;
        }

        // If we have the user to use supplied by the routing, do nothing here, we've handled it already when initialisting useChanges().
        if (paramUserId) {
            return;
        }

        // If we've already set the user do nothing.
        if (model.userId === currentUser.id) {
            return;
        }
        // Set the user.
        // and make sure the versionNumber is back to the default in case we were using it for an unauthenticated model
        change({ userId: currentUser.id, versionNumber: '1' });

    }, [isCreate, currentUser, model, paramUserId, change,]);

    // Inputted values get stored in JSON to ease the development overhead of storing new items.
    const [inputsModel, _setInputsModel] = useJsonObject<any>(model?.inputsJson ?? '{}', (json) => change({ inputsJson: json }));

    // Calculated values get stored in their own JSON separate to input values (see below), so we could wipe them and repeat if we find an issue.
    const [calculationsModel, setCalculationsModel] = useJsonObject<any>(model?.calculationsJson ?? '{}', (json) => change({ calculationsJson: json }));


    // This number represents the number of years on the Proposal tab.
    // It means we will calculate 40 years of figuresbased on the basic inputs and calculations
    // and pass them around to the tabs that need them.
    // We also include a year zero so it is actually 1 more than this figure in the array.
    const fullProposalYearsRequired = inputsModel?.proposalTerm ?? 40;

    const fullFinancialYearsRequired = 30;

    // A snapshot of the BaseValues values get stored in their own JSON  (see below), so that changes to the basevalues don't affect pre-existing FinancialModels.
    // This happens when the Financial Model is first created or if we come across one that hasn't got a snapshot for some reason.
    useEffect(() => {
        if (!allBaseValues) {
            return;
        }

        const baseValues = JSON.parse(storeModel?.baseValuesJson ?? '{}');

        // if we don't have any values - take a snapshot now.
        if (isCreate || Object.keys(baseValues).length === 0) {
            let newBaseValues: { [key: string]: any } = {};
            for (let i = 0; i < allBaseValues.length; i++) {
                // get the name
                const thisName = allBaseValues[i].name;

                // get the Json values
                newBaseValues[thisName] = JSON.parse(allBaseValues[i].valuesJson);
            }

            const json = JSON.stringify(newBaseValues);

            change({ baseValuesJson: json });
        }
    }, [isCreate, storeModel, allBaseValues, change,]);


    // Turn the snapshot baseValues string into a JSON object to work with
    // We may have just created these or they may be already on the model if editing
    const baseValuesModel: any = useMemo(() => {
        if (!model || !model.baseValuesJson) {
            return;
        }

        return JSON.parse(model.baseValuesJson);
    }, [model]);


    // The target return ratio is dependent on the number of years entered as the ppa term.
    // It is a straight lookup from the baseValues. It includes a ratio and the corresponding energy price required to hit that ratio.
    // TODO we should be able to calculate either the ratio or the price when one or other is known - not sure how this fits with the flow of the process.
    const targetReturnRatio: {ratio: number, energySalesPrice: number } = useMemo(() => {
        if (!baseValuesModel || !baseValuesModel['Target Return Ratios'] || !inputsModel || !inputsModel?.ppaTerm) {
            return { ratio: 0, energySalesPrice: 0 };
        }

        var thisRatio = baseValuesModel['Target Return Ratios']['targetRatio' + inputsModel.ppaTerm];
        var thisPriceRequired = baseValuesModel['Target Return Ratios']['ppaPriceRequired' + inputsModel.ppaTerm];

        return { ratio: thisRatio ?? 0, energySalesPrice: thisPriceRequired ?? 0 };


    }, [inputsModel, baseValuesModel]);


    // get the co2Savings from the snapshot baseValues
    const co2Savings = useMemo(() => {
        if (!baseValuesModel || !inputsModel) {
            return;
        }

        return (baseValuesModel['CO2 Unit Conversion']?.scope2 ?? 0) + (baseValuesModel['CO2 Unit Conversion']?.scope3 ?? 0);

    }, [inputsModel, baseValuesModel]);


    // Do the year calculations for all required years and pass an array of them into each tab that needs them
    // This array will also hold a year zero set of values
    const allYearCalculations: Array<YearCalculation> = useMemo(() => {

        let newYearsArray: Array<YearCalculation> = [];
        // loop round 1 more than the years required so we have a year zero
        // number of years calculated needs to be at least 30 so we have all the figures for the financials tab

        for (let y = 0; y <= fullProposalYearsRequired || y <= fullFinancialYearsRequired; y++) {
            const yearCalculations = calculateForYear(inputsModel, calculationsModel, y, targetReturnRatio.energySalesPrice);
            newYearsArray.push(yearCalculations);
        }

        return newYearsArray;

    }, [fullProposalYearsRequired, inputsModel, calculationsModel, targetReturnRatio]);

    // IRR calculations return a number or '#NUM!' in line with what the calculateIRR function will return
    const irrCalculations: { preTaxIRR: number | '#NUM!', postTaxIRR: number | '#NUM!' } = useMemo(() => {
        let preTaxIRR: number | '#NUM!' = 0;
        let postTaxIRR: number | '#NUM!'  = 0;

        if (!allYearCalculations.length) {
            return { preTaxIRR: preTaxIRR, postTaxIRR: postTaxIRR};
        }


        // figures ready for pre-tax IRR calc
        let preTaxIRRFigures: Array<number> = [];
        // first figure is the capex converted to a negative
        preTaxIRRFigures.push((calculationsModel?.totalCapex2 ?? 0) * -1);
        // figures ready for post-tax IRR calc
        let postTaxIRRFigures: Array<number> = [];
        // first figure is the capex converted to a negative
        postTaxIRRFigures.push((calculationsModel?.totalCapex2 ?? 0) * -1);

        for (let y = 1; y <= fullFinancialYearsRequired; y++) {
            const yearCalculations = allYearCalculations[y];
            const previousYearCalculations = y === 1 ? { ...yearCalculationDefaultValues() } : allYearCalculations[y - 1];

            const changeInWorkingCapital = (previousYearCalculations?.workingCapital ?? 0) - (yearCalculations?.workingCapital ?? 0);
            const fcfPostTax = (yearCalculations?.ebitda ?? 0) - (yearCalculations?.taxOutlay ?? 0) + changeInWorkingCapital;
            const fcfPreTax = (yearCalculations?.ebitda ?? 0) + changeInWorkingCapital;

            // update the preTaxIRR figures
            preTaxIRRFigures.push(fcfPreTax);
            // update the postTaxIRR figures
            postTaxIRRFigures.push(fcfPostTax);

        }

        // IRR calcualations
        postTaxIRR = calculateIRR(postTaxIRRFigures, 0.1);
        preTaxIRR = calculateIRR(preTaxIRRFigures, 0.1);

        return { preTaxIRR: preTaxIRR, postTaxIRR: postTaxIRR };

    }, [allYearCalculations, calculationsModel, fullFinancialYearsRequired]);

    // Perform calculations on the model using our custom calculator.
    // This gets the values we can calculate from the inputs.
    // We'll use the inputs and these calculated values to get to all the inflation adjusted figures across all years of the model.
    const performCalculations = (fields: Array<string> | undefined) => {
        const calculator = new FinancialModelCalculator(inputsModel, calculationsModel);
        const newValues = calculator.recalculate(fields);
        setCalculationsModel((prevState: any) => ({
            ...prevState,
            ...newValues,
        }));
    };

    const [scheduledCalculationFields, setScheduledCalculationFields] = useState<Array<string>>([]);

    const performCalculationsDebounced = useDebouncedCallback(() => {
        const fields = [...scheduledCalculationFields];
        setScheduledCalculationFields([]);
        performCalculations(fields);
    });

    const scheduleCalculations = useCallback((fields: Array<string>) => {
        setScheduledCalculationFields(prevState => [
            ...prevState,
            ...fields,
        ]);

        performCalculationsDebounced();
    }, [setScheduledCalculationFields, performCalculationsDebounced]);


    // Change the value of an input model.  Will also trigger any required recalculations.
    const changeInputsModel = useCallback((changes: any) => {
        // Update the data.
        _setInputsModel((prevState: any) => ({
            ...(prevState ?? {}),
            ...changes,
        }));

        // Schedule recalculation.
        scheduleCalculations(Object.keys(changes));
    }, [_setInputsModel, scheduleCalculations]);

    const inputsDefaults = {
        startDate: moment('2023-09-01'),
        ppaTerm: 25,
        currentEnergyCost: 15,
        exportTariff: 6.5,
        rpi: 2.2,
        annualInflation: 2.2,
        energyInflation: 5,
        oAndM: 3,
        oAndMManager: 1,
        transmissionFee: 1,
        landLease: 1,
        insurance: 1.5,
        insuranceandLCInflation: 1.5,
        managementFeeInflation: 2,
        refurbishmentFee: 1,
        receivablesDays: 60,
        payablesDays: 30,
        depreciationYears: 60,
        debtInterestRate: 6,
        debtTerm: 15,
        taxFuture: 25,
        taxNow: 25,
        sizeOfArray: 999,
        energyUsedOnSite: 85,
        degradation: 0.4,
        specificYield: 850,  // this is the default specific production / default array size (849150 / 999)
        epcPrice: 62,
    };


    // Need some defaults when creatin g as we may never save the model but we need somewhere to start
    useEffect(() => {
        if (!inputsModel || !inputsDefaults || !isCreate) {
            return;
        }

        // Only do this once
        if (!!inputsModel.startDate) {
            return;
        }

        // Set the defaults -- use inputsModel values if we have any to avoid accidentally overwriting if someone removes their start date
        changeInputsModel({
            startDate: inputsDefaults.startDate,
            ppaTerm: inputsModel.ppaTerm ?? inputsDefaults.ppaTerm,
            currentEnergyCost: inputsModel.currentEnergyCost ?? inputsDefaults.currentEnergyCost,
            exportTariff: inputsModel.exportTariff ?? inputsDefaults.exportTariff,
            rpi: inputsModel.rpi ?? inputsDefaults.rpi,
            annualInflation: inputsModel.annualInflation ?? inputsDefaults.annualInflation,
            energyInflation: inputsModel.energyInflation ?? inputsDefaults.energyInflation,
            oAndM: inputsModel?.oAndM ?? inputsDefaults.oAndM,
            oAndMManager: inputsModel?.oAndMManager ?? inputsDefaults.oAndMManager,
            transmissionFee: inputsModel?.transmissionFee ?? inputsDefaults.transmissionFee,
            landLease: inputsModel?.landLease ?? inputsDefaults.landLease,
            insurance: inputsModel?.insurance ?? inputsDefaults.insurance,
            insuranceandLCInflation: inputsModel?.insuranceandLCInflation ?? inputsDefaults.insuranceandLCInflation,
            managementFeeInflation: inputsModel?.managementFeeInflation ?? inputsDefaults.managementFeeInflation,
            refurbishmentFee: inputsModel?.refurbishmentFee ?? inputsDefaults.refurbishmentFee,
            receivablesDays: inputsModel?.receivablesDays ?? inputsDefaults.receivablesDays,
            payablesDays: inputsModel?.payablesDays ?? inputsDefaults.payablesDays,
            depreciationYears: inputsModel?.depreciationYears ?? inputsDefaults.depreciationYears,
            debtInterestRate: inputsModel?.debtInterestRate ?? inputsDefaults.debtInterestRate,
            debtTerm: inputsModel?.debtTerm ?? inputsDefaults.debtTerm,
            taxFuture: inputsModel?.debtTerm ?? inputsDefaults.taxFuture,
            taxNow: inputsModel?.taxNow ?? inputsDefaults.taxNow,
            sizeOfArray: inputsModel?.sizeOfArray ?? inputsDefaults.sizeOfArray,
            energyUsedOnSite: inputsModel?.energyUsedOnSite ?? inputsDefaults.energyUsedOnSite,
            degradation: inputsModel?.degradation ?? inputsDefaults.degradation,
            specificYield: inputsModel?.specificYield ?? inputsDefaults.specificYield,  
            epcPrice: inputsModel?.epcPrice ?? inputsDefaults.epcPrice,
        });

    });



    // Validate.
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editFinancialModel.nameRequired', 'Name is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Tab tracking.
    const [activeTab, setActiveTab] = useState<'InputsOutputs' | 'Assumptions' | 'Model' | 'Financials' | 'Valuation' | 'Proposal'>('InputsOutputs');

    // Save changes.
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!validate()) {
            return;
        }

        if (!currentUser) {
            // user is not logged in so do an unauthenticated save and pass user to the login screen 
            // with the new model id so we can find their financial model that we stored before they logged in or registered
            await saveUnauthenticated(model.id, changes, true)
            history.push(`/account/register/${model.id}`);
        } else {
            await save(model.id, changes, !!isCreate);
            history.goBack();
        }
    }, [validate, save, model, changes, isCreate, history]);

    return (
        <Background>
            <Banner fluid className="edit-financial-model no-print">
                <StickyToolbar>
                    <Row>
                        <Col xs={12}>
                            <h1>
                                {
                                    model?.name ? (
                                        <>{model.name}</>
                                    ) : (
                                        <>{t('editFinancialModel.defaultHeading', 'Calculate savings')}</>
                                    )
                                }
                            </h1>
                        </Col>
                        <Col className="d-none d-lg-flex" sm={3}></Col>
                        <Col className="text-center" xs={12} lg={6}>
                            <PillsNavBar>
                                <NavItem>
                                    <NavLink active={activeTab === 'InputsOutputs'} onClick={() => setActiveTab('InputsOutputs')}>
                                        <><FontAwesomeIcon icon="solar-panel" className="nav-icon" /> <span className="nav-text">{t('editFinancialModel.tabs.inputsCalculations', 'Inputs/Outputs')}</span></>
                                    </NavLink>
                                </NavItem>
                                <AuthorizeContainer requireRole={IdentityRoles.Administration}>
                                    <NavItem>
                                        <NavLink active={activeTab === 'Assumptions'} onClick={() => setActiveTab('Assumptions')}>
                                            <><FontAwesomeIcon icon="project-diagram" className="nav-icon" /> <span className="nav-text">{t('editFinancialModel.tabs.assumptions', 'Assumptions')}</span></>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink active={activeTab === 'Model'} onClick={() => setActiveTab('Model')}>
                                            <><FontAwesomeIcon icon="cogs" className="nav-icon" /> <span className="nav-text">{t('editFinancialModel.tabs.model', 'Model')}</span></>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink active={activeTab === 'Financials'} onClick={() => setActiveTab('Financials')}>
                                            <><FontAwesomeIcon icon="pound-sign" className="nav-icon" /> <span className="nav-text">{t('editFinancialModel.tabs.financials', 'Financials')}</span></>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink active={activeTab === 'Valuation'} onClick={() => setActiveTab('Valuation')}>
                                            <><FontAwesomeIcon icon="clipboard-check" className="nav-icon" /> <span className="nav-text">{t('editFinancialModel.tabs.valuation', 'Valuation')}</span></>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink active={activeTab === 'Proposal'} onClick={() => setActiveTab('Proposal')}>
                                            <><FontAwesomeIcon icon="flag-checkered" className="nav-icon" /> <span className="nav-text">{t('editFinancialModel.tabs.Proposal', 'Proposal')}</span></>
                                        </NavLink>
                                    </NavItem>
                                </AuthorizeContainer>
                            </PillsNavBar>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col className="actions-container" xs={12} lg={3}>
                            <ConditionalFragment showIf={!isLoading}>
                                <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                                    executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                    <FontAwesomeIcon icon="save" />
                                    <> {t('common.save', 'Save')}</>
                                </ButtonAsync>
                            </ConditionalFragment>
                            <> </>
                            <Button type="button" color="primary" outline onClick={() => history.goBack()}>
                                {t('common.cancel', 'Cancel')}
                            </Button>
                            <> </>
                            <AuthorizeContainer requireRole={IdentityRoles.Administration}>
                                <Button type="button" color="danger" onClick={() => performCalculations(undefined)}>
                                    {t('common.cancel', 'Force Recaclulate')}
                                </Button>
                            </AuthorizeContainer>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer className="edit-financial-model" fluid>
                <AlertOnErrors errors={[loadErrors, saveFormErrors, saveErrors, saveUnauthenticatedErrors, loadBaseErrors]} />

                <h1>{t('editFinancialModel.heading','Olympus Power Quote')}</h1>

                <div>
                    <FormGroup>
                        <Label htmlFor="name">{t('editFinancialModel.name.label', 'Quote name')}</Label>
                        <ValidatedInput name="name" type="text" value={model.name ?? ''}
                            onChange={e => change({ name: e.currentTarget.value })}
                            onBlur={e => validate('name')}
                            validationErrors={validationErrors['name']}
                            placeholder={t('editFinancialModel.name.placeholder','Enter a name for your quote so you can come back to it later ...')}
                        />
                    </FormGroup>
                </div>

                <ConditionalFragment showIf={activeTab === 'InputsOutputs'}>
                    <ClientFacingTab
                        change={change}
                        isExcludeInflation={model.isExcludeInflation}
                        inputsDefaults={inputsDefaults}
                        inputsModel={inputsModel}
                        changeInputsModel={changeInputsModel}
                        //calculationsModel={calculationsModel}
                        targetReturnRatio={targetReturnRatio}
                        co2Savings={co2Savings}
                        allYearCalculations={allYearCalculations}
                        fullProposalYearsRequired={fullProposalYearsRequired}
                        //irrCalculations={irrCalculations}
                    />
                </ConditionalFragment>
                <ConditionalFragment showIf={activeTab === 'Assumptions'}>
                    <AssumptionsTab
                        inputsModel={inputsModel}
                        isExcludeInflation={model.isExcludeInflation}
                        changeInputsModel={changeInputsModel}
                        calculationsModel={calculationsModel}
                        targetReturnRatio={targetReturnRatio}
                    />
                </ConditionalFragment>
                <ConditionalFragment showIf={activeTab === 'Model'}>
                    <ModelTab
                        inputsModel={inputsModel}
                        changeInputsModel={changeInputsModel}
                        calculationsModel={calculationsModel}
                        targetReturnRatio={targetReturnRatio}
                    />
                </ConditionalFragment>
                <ConditionalFragment showIf={activeTab === 'Financials'}>
                    <FinancialsTab
                        inputsModel={inputsModel}
                        calculationsModel={calculationsModel}
                        allYearCalculations={allYearCalculations}
                    />
                </ConditionalFragment>
                <ConditionalFragment showIf={activeTab === 'Valuation'}>
                    <ValuationTab
                        inputsModel={inputsModel}
                        calculationsModel={calculationsModel}
                        allYearCalculations={allYearCalculations}
                        irrCalculations={irrCalculations}
                   />
                </ConditionalFragment>
                <ConditionalFragment showIf={activeTab === 'Proposal'}>
                    <ProposalsTab
                        inputsModel={inputsModel}
                        co2Savings={co2Savings}
                        allYearCalculations={allYearCalculations}
                    />
                </ConditionalFragment>

            </MainContainer>
            <Footer />
        </Background>
    );
};

