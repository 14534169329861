import moment from "moment";
import { CalculationsProcessor, NameValuePairs } from "../../services/calculationsProcessor/CalculationsProcessor";

/**
 * Calculator that will process the inputs and calculations to generate all calculated values.
 */
export class FinancialModelCalculator extends CalculationsProcessor {

    public constructor(inputs: NameValuePairs, calculations: NameValuePairs) {
        super(inputs, calculations);
    }

    /**
     * Initialize all calculations here.  You can calculate one or more fields for each calculation.  List the dependent fields (inputted or calculated) for you
     * calculation to ensure it only runs when needed.
     */
    protected initializeCalculations() {

        // Originator's Fee
        this.addCalculation(() => ({ originatorsFee: this._calculations.installedCostsEPC * 0.05 }), ['installedCostsEPC']);

        // Estimated Output (kWh / annum)
        this.addCalculation(() => ({ estimatedOutput: this._inputs.sizeOfArray * this._inputs.specificYield }), ['sizeOfArray', 'specificYield']);

        // Installed Costs - EPC (ex VAT)
        this.addCalculation(() => ({ installedCostsEPC: this._inputs.sizeOfArray * this._inputs.epcPrice * 10 }), ['sizeOfArray', 'epcPrice']);

        // Installed Costs - EPC (inc VAT)
        this.addCalculation(() => ({ installedCostsIncVat: this._calculations.totalCapex * 1.2 }), ['totalCapex']);

        // Model End Date
        this.addCalculation(() => ({
            endDate:
                moment(
                    moment(this._inputs.startDate).add(this._inputs.ppaTerm, 'years')
                        .subtract(1, 'days'))
        }), ['startDate', 'ppaTerm']);

        // Export
        this.addCalculation(() => ({ export: this._calculations.estimatedOutput * ((100 - this._inputs.energyUsedOnSite) / 100) }), ['estimatedOutput', 'energyUsedOnSite']);

        // Take or Pay
        this.addCalculation(() => ({ takeOrPay: this._calculations.estimatedOutput * (this._inputs.energyUsedOnSite / 100)}), ['estimatedOutput', 'energyUsedOnSite']);

        // EPC Cost
        this.addCalculation(() => ({ epcCost: this._inputs.sizeOfArray * this._inputs.epcPrice * 10 }), ['sizeOfArray', 'epcPrice']);

        // EV Charging Cost
        this.addCalculation(() => ({ eVChargingCost: this._inputs.sizeOfArray * 27 }), ['sizeOfArray']);

        // Total Capex
        this.addCalculation(() => ({ totalCapex: this._calculations.epcCost + this._calculations.originatorsFee + this._calculations.eVChargingCost }), ['epcCost', 'originatorsFee', 'eVChargingCost']);
        // Total Capex2 - alternative
        this.addCalculation(() => ({ totalCapex2: ((this._inputs.epcPrice / 100) * this._inputs.sizeOfArray * 1000) + this._calculations.originatorsFee }), ['epcCost', 'originatorsFee']);

        // Business Rates
        this.addCalculation(() => ({ businessRates: this._inputs.sizeOfArray * 3 }), ['sizeOfArray']);

        // Management Fee
        this.addCalculation(() => ({ managementFee: this._inputs.sizeOfArray * 3 }), ['sizeOfArray']);

        // Refurbishment Fee financial figure named with calculation in name so we don't accidently us inputs.refurbishmentFee
        this.addCalculation(() => ({ refurbishmentFeeCalculation: this._inputs.refurbishmentFee * this._inputs.sizeOfArray}), ['refurbishmentFee', 'sizeOfArray']);

        // Depreciation Rate
        this.addCalculation(() => ({ depreciationRate: (1 / this._inputs.depreciationYears) * 100 }), ['depreciationYears']);

        // NondegradationMultiplier 
        this.addCalculation(() => ({ nonDegradationMultiplier: 1 - (this._inputs.degradation / 100) }), ['degradation']);

        // oandM financial figure named with calculation in name so we don't accidently us inputs.oAndM
        this.addCalculation(() => ({ oAndMCalculation: this._inputs.oAndM * this._inputs.sizeOfArray }), ['oAndM','sizeOfArray']);

        // insurance financial figure named with calculation in name so we don't accidently us inputs.insurance
        this.addCalculation(() => ({ insuranceCalculation: this._inputs.insurance * this._inputs.sizeOfArray }), ['oAndM', 'sizeOfArray']);

        // depreciation
        this.addCalculation(() => ({ depreciation: this._calculations.totalCapex2 / this._calculations.depreciationRate }), []);

    }
}
