import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { Background } from '../shared/background/Background';
import { Banner } from '../shared/Banner';
import { MainContainer } from '../shared/MainContainer';

/**
 * Start page for users who are not logged in.
 */
export const Start = () => {
    const { t } = useTranslation();

    return (
        <Background>
            <Banner>
                <Row>
                    <Col>
                        <h1>{t('start.heading', 'Olympus Power savings calculator')}</h1>
                    </Col>
                </Row>
            </Banner>

            <MainContainer style={{ textAlign: 'center' }}>
                <div className="mb-4">
                    <p>
                        {t('start.calculator.text', 'Let us calculate your savings by helping create a financial model')}
                    </p>
                    <LinkContainer to="/calculator" style={{ fontSize: '2rem', }}>
                        <Button color="primary">
                            <FontAwesomeIcon icon="calculator" />
                            <> </>
                            {t('start.add', 'Calculate savings')}
                        </Button>
                    </LinkContainer>
                </div>

                <div>
                    <p className="text-muted">
                        {t('start.login.text', 'Already calculated your savings?  Sign in to view or updated your saved financial models.')}
                    </p>
                    <Link to="/authentication/login">
                        <Button size="sm" color="primary" outline>
                            {t('start.login', 'Sign in with your email')}
                        </Button>
                    </Link>
                </div>
            </MainContainer>
        </Background>
        );
};