import { useTranslation } from "react-i18next";
import { FormGroup, Label, Row, } from "reactstrap";
import { NumberInput } from "../../shared/numberInput/NumberInput";
import "./targetReturnRatiosNode.scss";

export interface TargetReturnRatiosNodeProps {
    valuesModel: any | undefined,
    changeValuesModel: (changes: any) => void,
}

/**
 * node for maintaining the BaseValue TargetReturnRatios.
 * @param props
 */
export const TargetReturnRatiosNode = (props: TargetReturnRatiosNodeProps) => {
    const {
        valuesModel, changeValuesModel,
    } = props;

    const { t } = useTranslation();

    return (
        <div className="target-return-ratios-node">
            <Row>
                <Label className="ratio-header" htmlFor="term">{t('targetReturnRatiosNode.term1.label', 'Term 1')}</Label>
                <FormGroup>
                    <Label htmlFor="targetRatio">{t('targetReturnRatiosNode.targetRatio1.label', 'Target ratio 1')}</Label>
                    <NumberInput
                        value={valuesModel.targetRatio1}
                        onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                        onChange={e => changeValuesModel({ targetRatio1: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ppaPriceRequired">{t('targetReturnRatiosNode.ppaPriceRequired1.label', 'PPA price required 1')}</Label>
                    <NumberInput
                        value={valuesModel.ppaPriceRequired1}
                        onFormat={value => t('common.number.10Decimals', `{{value, #,0.0000000000}}`, { value })}
                        onChange={e => changeValuesModel({ ppaPriceRequired1: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <Label className="ratio-header" htmlFor="term">{t('targetReturnRatiosNode.term2.label', 'Term 2')}</Label>
                <FormGroup>
                    <Label htmlFor="targetRatio">{t('targetReturnRatiosNode.targetRatio2.label', 'Target ratio 2')}</Label>
                    <NumberInput
                        value={valuesModel.targetRatio2}
                        onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                        onChange={e => changeValuesModel({ targetRatio2: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ppaPriceRequired">{t('targetReturnRatiosNode.ppaPriceRequired2.label', 'PPA price required 2')}</Label>
                    <NumberInput
                        value={valuesModel.ppaPriceRequired2}
                        onFormat={value => t('common.number.10Decimals', `{{value, #,0.0000000000}}`, { value })}
                        onChange={e => changeValuesModel({ ppaPriceRequired2: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <Label className="ratio-header" htmlFor="term">{t('targetReturnRatiosNode.term3.label', 'Term 3')}</Label>
                <FormGroup>
                    <Label htmlFor="targetRatio">{t('targetReturnRatiosNode.targetRatio3.label', 'Target ratio 3')}</Label>
                    <NumberInput
                        value={valuesModel.targetRatio3}
                        onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                        onChange={e => changeValuesModel({ targetRatio3: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ppaPriceRequired">{t('targetReturnRatiosNode.ppaPriceRequired3.label', 'PPA price required 3')}</Label>
                    <NumberInput
                        value={valuesModel.ppaPriceRequired3}
                        onFormat={value => t('common.number.10Decimals', `{{value, #,0.0000000000}}`, { value })}
                        onChange={e => changeValuesModel({ ppaPriceRequired3: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <Label className="ratio-header" htmlFor="term">{t('targetReturnRatiosNode.term4.label', 'Term 4')}</Label>
                <FormGroup>
                    <Label htmlFor="targetRatio">{t('targetReturnRatiosNode.targetRatio4.label', 'Target ratio 4')}</Label>
                    <NumberInput
                        value={valuesModel.targetRatio4}
                        onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                        onChange={e => changeValuesModel({ targetRatio4: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ppaPriceRequired">{t('targetReturnRatiosNode.ppaPriceRequired4.label', 'PPA price required 4')}</Label>
                    <NumberInput
                        value={valuesModel.ppaPriceRequired4}
                        onFormat={value => t('common.number.10Decimals', `{{value, #,0.0000000000}}`, { value })}
                        onChange={e => changeValuesModel({ ppaPriceRequired4: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <Label className="ratio-header" htmlFor="term">{t('targetReturnRatiosNode.term5.label', 'Term 5')}</Label>
                <FormGroup>
                    <Label htmlFor="targetRatio">{t('targetReturnRatiosNode.targetRatio5.label', 'Target ratio 5')}</Label>
                    <NumberInput
                        value={valuesModel.targetRatio5}
                        onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                        onChange={e => changeValuesModel({ targetRatio5: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ppaPriceRequired">{t('targetReturnRatiosNode.ppaPriceRequired5.label', 'PPA price required 5')}</Label>
                    <NumberInput
                        value={valuesModel.ppaPriceRequired5}
                        onFormat={value => t('common.number.10Decimals', `{{value, #,0.0000000000}}`, { value })}
                        onChange={e => changeValuesModel({ ppaPriceRequired5: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <Label className="ratio-header" htmlFor="term">{t('targetReturnRatiosNode.term6.label', 'Term 6')}</Label>
                <FormGroup>
                    <Label htmlFor="targetRatio">{t('targetReturnRatiosNode.targetRatio6.label', 'Target ratio 6')}</Label>
                    <NumberInput
                        value={valuesModel.targetRatio6}
                        onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                        onChange={e => changeValuesModel({ targetRatio6: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ppaPriceRequired">{t('targetReturnRatiosNode.ppaPriceRequired6.label', 'PPA price required 6')}</Label>
                    <NumberInput
                        value={valuesModel.ppaPriceRequired6}
                        onFormat={value => t('common.number.10Decimals', `{{value, #,0.0000000000}}`, { value })}
                        onChange={e => changeValuesModel({ ppaPriceRequired6: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <Label className="ratio-header" htmlFor="term">{t('targetReturnRatiosNode.term7.label', 'Term 7')}</Label>
                <FormGroup>
                    <Label htmlFor="targetRatio">{t('targetReturnRatiosNode.targetRatio7.label', 'Target ratio 7')}</Label>
                    <NumberInput
                        value={valuesModel.targetRatio7}
                        onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                        onChange={e => changeValuesModel({ targetRatio7: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ppaPriceRequired">{t('targetReturnRatiosNode.ppaPriceRequired7.label', 'PPA price required 7')}</Label>
                    <NumberInput
                        value={valuesModel.ppaPriceRequired7}
                        onFormat={value => t('common.number.10Decimals', `{{value, #,0.0000000000}}`, { value })}
                        onChange={e => changeValuesModel({ ppaPriceRequired7: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <Label className="ratio-header" htmlFor="term">{t('targetReturnRatiosNode.term8.label', 'Term 8')}</Label>
                <FormGroup>
                    <Label htmlFor="targetRatio">{t('targetReturnRatiosNode.targetRatio8.label', 'Target ratio 8')}</Label>
                    <NumberInput
                        value={valuesModel.targetRatio8}
                        onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                        onChange={e => changeValuesModel({ targetRatio8: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ppaPriceRequired">{t('targetReturnRatiosNode.ppaPriceRequired8.label', 'PPA price required 8')}</Label>
                    <NumberInput
                        value={valuesModel.ppaPriceRequired8}
                        onFormat={value => t('common.number.10Decimals', `{{value, #,0.0000000000}}`, { value })}
                        onChange={e => changeValuesModel({ ppaPriceRequired8: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <Label className="ratio-header" htmlFor="term">{t('targetReturnRatiosNode.term9.label', 'Term 9')}</Label>
                <FormGroup>
                    <Label htmlFor="targetRatio">{t('targetReturnRatiosNode.targetRatio9.label', 'Target ratio 9')}</Label>
                    <NumberInput
                        value={valuesModel.targetRatio9}
                        onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                        onChange={e => changeValuesModel({ targetRatio9: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ppaPriceRequired">{t('targetReturnRatiosNode.ppaPriceRequired9.label', 'PPA price required 9')}</Label>
                    <NumberInput
                        value={valuesModel.ppaPriceRequired9}
                        onFormat={value => t('common.number.10Decimals', `{{value, #,0.0000000000}}`, { value })}
                        onChange={e => changeValuesModel({ ppaPriceRequired9: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <Label className="ratio-header" htmlFor="term">{t('targetReturnRatiosNode.term10.label', 'Term 10')}</Label>
                <FormGroup>
                    <Label htmlFor="targetRatio">{t('targetReturnRatiosNode.targetRatio10.label', 'Target ratio 10')}</Label>
                    <NumberInput
                        value={valuesModel.targetRatio10}
                        onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                        onChange={e => changeValuesModel({ targetRatio10: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ppaPriceRequired">{t('targetReturnRatiosNode.ppaPriceRequired10.label', 'PPA price required 10')}</Label>
                    <NumberInput
                        value={valuesModel.ppaPriceRequired10}
                        onFormat={value => t('common.number.10Decimals', `{{value, #,0.0000000000}}`, { value })}
                        onChange={e => changeValuesModel({ ppaPriceRequired10: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <Label className="ratio-header" htmlFor="term">{t('targetReturnRatiosNode.term11.label', 'Term 11')}</Label>
                <FormGroup>
                    <Label htmlFor="targetRatio">{t('targetReturnRatiosNode.targetRatio11.label', 'Target ratio 11')}</Label>
                    <NumberInput
                        value={valuesModel.targetRatio11}
                        onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                        onChange={e => changeValuesModel({ targetRatio11: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ppaPriceRequired">{t('targetReturnRatiosNode.ppaPriceRequired11.label', 'PPA price required 11')}</Label>
                    <NumberInput
                        value={valuesModel.ppaPriceRequired11}
                        onFormat={value => t('common.number.10Decimals', `{{value, #,0.0000000000}}`, { value })}
                        onChange={e => changeValuesModel({ ppaPriceRequired11: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <Label className="ratio-header" htmlFor="term">{t('targetReturnRatiosNode.term12.label', 'Term 12')}</Label>
                <FormGroup>
                    <Label htmlFor="targetRatio">{t('targetReturnRatiosNode.targetRatio12.label', 'Target ratio 12')}</Label>
                    <NumberInput
                        value={valuesModel.targetRatio12}
                        onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                        onChange={e => changeValuesModel({ targetRatio12: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ppaPriceRequired">{t('targetReturnRatiosNode.ppaPriceRequired12.label', 'PPA price required 12')}</Label>
                    <NumberInput
                        value={valuesModel.ppaPriceRequired12}
                        onFormat={value => t('common.number.10Decimals', `{{value, #,0.0000000000}}`, { value })}
                        onChange={e => changeValuesModel({ ppaPriceRequired12: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <Label className="ratio-header" htmlFor="term">{t('targetReturnRatiosNode.term13.label', 'Term 13')}</Label>
                <FormGroup>
                    <Label htmlFor="targetRatio">{t('targetReturnRatiosNode.targetRatio13.label', 'Target ratio 13')}</Label>
                    <NumberInput
                        value={valuesModel.targetRatio13}
                        onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                        onChange={e => changeValuesModel({ targetRatio13: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ppaPriceRequired">{t('targetReturnRatiosNode.ppaPriceRequired13.label', 'PPA price required 13')}</Label>
                    <NumberInput
                        value={valuesModel.ppaPriceRequired13}
                        onFormat={value => t('common.number.10Decimals', `{{value, #,0.0000000000}}`, { value })}
                        onChange={e => changeValuesModel({ ppaPriceRequired13: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <Label className="ratio-header" htmlFor="term">{t('targetReturnRatiosNode.term14.label', 'Term 14')}</Label>
                <FormGroup>
                    <Label htmlFor="targetRatio">{t('targetReturnRatiosNode.targetRatio14.label', 'Target ratio 14')}</Label>
                    <NumberInput
                        value={valuesModel.targetRatio14}
                        onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                        onChange={e => changeValuesModel({ targetRatio14: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ppaPriceRequired">{t('targetReturnRatiosNode.ppaPriceRequired14.label', 'PPA price required 14')}</Label>
                    <NumberInput
                        value={valuesModel.ppaPriceRequired14}
                        onFormat={value => t('common.number.10Decimals', `{{value, #,0.0000000000}}`, { value })}
                        onChange={e => changeValuesModel({ ppaPriceRequired14: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <Label className="ratio-header" htmlFor="term">{t('targetReturnRatiosNode.term15.label', 'Term 15')}</Label>
                <FormGroup>
                    <Label htmlFor="targetRatio">{t('targetReturnRatiosNode.targetRatio15.label', 'Target ratio 15')}</Label>
                    <NumberInput
                        value={valuesModel.targetRatio15}
                        onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                        onChange={e => changeValuesModel({ targetRatio15: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ppaPriceRequired">{t('targetReturnRatiosNode.ppaPriceRequired15.label', 'PPA price required 15')}</Label>
                    <NumberInput
                        value={valuesModel.ppaPriceRequired15}
                        onFormat={value => t('common.number.10Decimals', `{{value, #,0.0000000000}}`, { value })}
                        onChange={e => changeValuesModel({ ppaPriceRequired15: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <Label className="ratio-header" htmlFor="term">{t('targetReturnRatiosNode.term16.label', 'Term 16')}</Label>
                <FormGroup>
                    <Label htmlFor="targetRatio">{t('targetReturnRatiosNode.targetRatio16.label', 'Target ratio 16')}</Label>
                    <NumberInput
                        value={valuesModel.targetRatio16}
                        onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                        onChange={e => changeValuesModel({ targetRatio16: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ppaPriceRequired">{t('targetReturnRatiosNode.ppaPriceRequired16.label', 'PPA price required 16')}</Label>
                    <NumberInput
                        value={valuesModel.ppaPriceRequired16}
                        onFormat={value => t('common.number.10Decimals', `{{value, #,0.0000000000}}`, { value })}
                        onChange={e => changeValuesModel({ ppaPriceRequired16: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <Label className="ratio-header" htmlFor="term">{t('targetReturnRatiosNode.term17.label', 'Term 17')}</Label>
                <FormGroup>
                    <Label htmlFor="targetRatio">{t('targetReturnRatiosNode.targetRatio17.label', 'Target ratio 17')}</Label>
                    <NumberInput
                        value={valuesModel.targetRatio17}
                        onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                        onChange={e => changeValuesModel({ targetRatio17: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ppaPriceRequired">{t('targetReturnRatiosNode.ppaPriceRequired17.label', 'PPA price required 17')}</Label>
                    <NumberInput
                        value={valuesModel.ppaPriceRequired17}
                        onFormat={value => t('common.number.10Decimals', `{{value, #,0.0000000000}}`, { value })}
                        onChange={e => changeValuesModel({ ppaPriceRequired17: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <Label className="ratio-header" htmlFor="term">{t('targetReturnRatiosNode.term18.label', 'Term 18')}</Label>
                <FormGroup>
                    <Label htmlFor="targetRatio">{t('targetReturnRatiosNode.targetRatio18.label', 'Target ratio 18')}</Label>
                    <NumberInput
                        value={valuesModel.targetRatio18}
                        onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                        onChange={e => changeValuesModel({ targetRatio18: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ppaPriceRequired">{t('targetReturnRatiosNode.ppaPriceRequired18.label', 'PPA price required 18')}</Label>
                    <NumberInput
                        value={valuesModel.ppaPriceRequired18}
                        onFormat={value => t('common.number.10Decimals', `{{value, #,0.0000000000}}`, { value })}
                        onChange={e => changeValuesModel({ ppaPriceRequired18: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <Label className="ratio-header" htmlFor="term">{t('targetReturnRatiosNode.term19.label', 'Term 19')}</Label>
                <FormGroup>
                    <Label htmlFor="targetRatio">{t('targetReturnRatiosNode.targetRatio19.label', 'Target ratio 19')}</Label>
                    <NumberInput
                        value={valuesModel.targetRatio19}
                        onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                        onChange={e => changeValuesModel({ targetRatio19: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ppaPriceRequired">{t('targetReturnRatiosNode.ppaPriceRequired19.label', 'PPA price required 19')}</Label>
                    <NumberInput
                        value={valuesModel.ppaPriceRequired19}
                        onFormat={value => t('common.number.10Decimals', `{{value, #,0.0000000000}}`, { value })}
                        onChange={e => changeValuesModel({ ppaPriceRequired19: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <Label className="ratio-header" htmlFor="term">{t('targetReturnRatiosNode.term20.label', 'Term 20')}</Label>
                <FormGroup>
                    <Label htmlFor="targetRatio">{t('targetReturnRatiosNode.targetRatio20.label', 'Target ratio 20')}</Label>
                    <NumberInput
                        value={valuesModel.targetRatio20}
                        onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                        onChange={e => changeValuesModel({ targetRatio20: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ppaPriceRequired">{t('targetReturnRatiosNode.ppaPriceRequired20.label', 'PPA price required 20')}</Label>
                    <NumberInput
                        value={valuesModel.ppaPriceRequired20}
                        onFormat={value => t('common.number.10Decimals', `{{value, #,0.0000000000}}`, { value })}
                        onChange={e => changeValuesModel({ ppaPriceRequired20: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <Label className="ratio-header" htmlFor="term">{t('targetReturnRatiosNode.term21.label', 'Term 21')}</Label>
                <FormGroup>
                    <Label htmlFor="targetRatio">{t('targetReturnRatiosNode.targetRatio21.label', 'Target ratio 21')}</Label>
                    <NumberInput
                        value={valuesModel.targetRatio21}
                        onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                        onChange={e => changeValuesModel({ targetRatio21: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ppaPriceRequired">{t('targetReturnRatiosNode.ppaPriceRequired21.label', 'PPA price required 21')}</Label>
                    <NumberInput
                        value={valuesModel.ppaPriceRequired21}
                        onFormat={value => t('common.number.10Decimals', `{{value, #,0.0000000000}}`, { value })}
                        onChange={e => changeValuesModel({ ppaPriceRequired21: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <Label className="ratio-header" htmlFor="term">{t('targetReturnRatiosNode.term22.label', 'Term 22')}</Label>
                <FormGroup>
                    <Label htmlFor="targetRatio">{t('targetReturnRatiosNode.targetRatio22.label', 'Target ratio 22')}</Label>
                    <NumberInput
                        value={valuesModel.targetRatio22}
                        onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                        onChange={e => changeValuesModel({ targetRatio22: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ppaPriceRequired">{t('targetReturnRatiosNode.ppaPriceRequired22.label', 'PPA price required 22')}</Label>
                    <NumberInput
                        value={valuesModel.ppaPriceRequired22}
                        onFormat={value => t('common.number.10Decimals', `{{value, #,0.0000000000}}`, { value })}
                        onChange={e => changeValuesModel({ ppaPriceRequired22: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <Label className="ratio-header" htmlFor="term">{t('targetReturnRatiosNode.term23.label', 'Term 23')}</Label>
                <FormGroup>
                    <Label htmlFor="targetRatio">{t('targetReturnRatiosNode.targetRatio23.label', 'Target ratio 23')}</Label>
                    <NumberInput
                        value={valuesModel.targetRatio23}
                        onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                        onChange={e => changeValuesModel({ targetRatio23: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ppaPriceRequired">{t('targetReturnRatiosNode.ppaPriceRequired23.label', 'PPA price required 23')}</Label>
                    <NumberInput
                        value={valuesModel.ppaPriceRequired23}
                        onFormat={value => t('common.number.10Decimals', `{{value, #,0.0000000000}}`, { value })}
                        onChange={e => changeValuesModel({ ppaPriceRequired23: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <Label className="ratio-header" htmlFor="term">{t('targetReturnRatiosNode.term24.label', 'Term 24')}</Label>
                <FormGroup>
                    <Label htmlFor="targetRatio">{t('targetReturnRatiosNode.targetRatio24.label', 'Target ratio 24')}</Label>
                    <NumberInput
                        value={valuesModel.targetRatio24}
                        onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                        onChange={e => changeValuesModel({ targetRatio24: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ppaPriceRequired">{t('targetReturnRatiosNode.ppaPriceRequired24.label', 'PPA price required 24')}</Label>
                    <NumberInput
                        value={valuesModel.ppaPriceRequired24}
                        onFormat={value => t('common.number.10Decimals', `{{value, #,0.0000000000}}`, { value })}
                        onChange={e => changeValuesModel({ ppaPriceRequired24: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <Label className="ratio-header" htmlFor="term">{t('targetReturnRatiosNode.term25.label', 'Term 25')}</Label>
                <FormGroup>
                    <Label htmlFor="targetRatio">{t('targetReturnRatiosNode.targetRatio25.label', 'Target ratio 25')}</Label>
                    <NumberInput
                        value={valuesModel.targetRatio25}
                        onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                        onChange={e => changeValuesModel({ targetRatio25: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ppaPriceRequired">{t('targetReturnRatiosNode.ppaPriceRequired25.label', 'PPA price required 25')}</Label>
                    <NumberInput
                        value={valuesModel.ppaPriceRequired25}
                        onFormat={value => t('common.number.10Decimals', `{{value, #,0.0000000000}}`, { value })}
                        onChange={e => changeValuesModel({ ppaPriceRequired25: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <Label className="ratio-header" htmlFor="term">{t('targetReturnRatiosNode.term26.label', 'Term 26')}</Label>
                <FormGroup>
                    <Label htmlFor="targetRatio">{t('targetReturnRatiosNode.targetRatio26.label', 'Target ratio 26')}</Label>
                    <NumberInput
                        value={valuesModel.targetRatio26}
                        onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                        onChange={e => changeValuesModel({ targetRatio26: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ppaPriceRequired">{t('targetReturnRatiosNode.ppaPriceRequired26.label', 'PPA price required 26')}</Label>
                    <NumberInput
                        value={valuesModel.ppaPriceRequired26}
                        onFormat={value => t('common.number.10Decimals', `{{value, #,0.0000000000}}`, { value })}
                        onChange={e => changeValuesModel({ ppaPriceRequired26: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <Label className="ratio-header" htmlFor="term">{t('targetReturnRatiosNode.term27.label', 'Term 27')}</Label>
                <FormGroup>
                    <Label htmlFor="targetRatio">{t('targetReturnRatiosNode.targetRatio27.label', 'Target ratio 27')}</Label>
                    <NumberInput
                        value={valuesModel.targetRatio27}
                        onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                        onChange={e => changeValuesModel({ targetRatio27: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ppaPriceRequired">{t('targetReturnRatiosNode.ppaPriceRequired27.label', 'PPA price required 27')}</Label>
                    <NumberInput
                        value={valuesModel.ppaPriceRequired27}
                        onFormat={value => t('common.number.10Decimals', `{{value, #,0.0000000000}}`, { value })}
                        onChange={e => changeValuesModel({ ppaPriceRequired27: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <Label className="ratio-header" htmlFor="term">{t('targetReturnRatiosNode.term28.label', 'Term 28')}</Label>
                <FormGroup>
                    <Label htmlFor="targetRatio">{t('targetReturnRatiosNode.targetRatio28.label', 'Target ratio 28')}</Label>
                    <NumberInput
                        value={valuesModel.targetRatio28}
                        onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                        onChange={e => changeValuesModel({ targetRatio28: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ppaPriceRequired">{t('targetReturnRatiosNode.ppaPriceRequired28.label', 'PPA price required 28')}</Label>
                    <NumberInput
                        value={valuesModel.ppaPriceRequired28}
                        onFormat={value => t('common.number.10Decimals', `{{value, #,0.0000000000}}`, { value })}
                        onChange={e => changeValuesModel({ ppaPriceRequired28: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <Label className="ratio-header" htmlFor="term">{t('targetReturnRatiosNode.term29.label', 'Term 29')}</Label>
                <FormGroup>
                    <Label htmlFor="targetRatio">{t('targetReturnRatiosNode.targetRatio29.label', 'Target ratio 29')}</Label>
                    <NumberInput
                        value={valuesModel.targetRatio29}
                        onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                        onChange={e => changeValuesModel({ targetRatio29: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ppaPriceRequired">{t('targetReturnRatiosNode.ppaPriceRequired29.label', 'PPA price required 29')}</Label>
                    <NumberInput
                        value={valuesModel.ppaPriceRequired29}
                        onFormat={value => t('common.number.10Decimals', `{{value, #,0.0000000000}}`, { value })}
                        onChange={e => changeValuesModel({ ppaPriceRequired29: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
            </Row>
            <Row>
                <Label className="ratio-header" htmlFor="term">{t('targetReturnRatiosNode.term30.label', 'Term 30')}</Label>
                <FormGroup>
                    <Label htmlFor="targetRatio">{t('targetReturnRatiosNode.targetRatio30.label', 'Target ratio 30')}</Label>
                    <NumberInput
                        value={valuesModel.targetRatio30}
                        onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                        onChange={e => changeValuesModel({ targetRatio30: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="ppaPriceRequired">{t('targetReturnRatiosNode.ppaPriceRequired30.label', 'PPA price required 30')}</Label>
                    <NumberInput
                        value={valuesModel.ppaPriceRequired30}
                        onFormat={value => t('common.number.10Decimals', `{{value, #,0.0000000000}}`, { value })}
                        onChange={e => changeValuesModel({ ppaPriceRequired30: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                    />
                </FormGroup>
            </Row>

        </div>
    );
};

/**
 * Layout for Target Return Ratios
 */
export interface TargetReturnRatiosBaseValue {
        targetRatio1: number, ppaPriceRequired1: number,
        targetRatio2: number, ppaPriceRequired2: number,
        targetRatio3: number, ppaPriceRequired3: number,
        targetRatio4: number, ppaPriceRequired4: number,
        targetRatio5: number, ppaPriceRequired5: number,
        targetRatio6: number, ppaPriceRequired6: number,
        targetRatio7: number, ppaPriceRequired7: number,
        targetRatio8: number, ppaPriceRequired8: number,
        targetRatio9: number, ppaPriceRequired9: number,
        targetRatio10: number, ppaPriceRequired10: number,
        targetRatio11: number, ppaPriceRequired11: number,
        targetRatio12: number, ppaPriceRequired12: number,
        targetRatio13: number, ppaPriceRequired13: number,
        targetRatio14: number, ppaPriceRequired14: number,
        targetRatio15: number, ppaPriceRequired15: number,
        targetRatio16: number, ppaPriceRequired16: number,
        targetRatio17: number, ppaPriceRequired17: number,
        targetRatio18: number, ppaPriceRequired18: number,
        targetRatio19: number, ppaPriceRequired19: number,
        targetRatio20: number, ppaPriceRequired20: number,
        targetRatio21: number, ppaPriceRequired21: number,
        targetRatio22: number, ppaPriceRequired22: number,
        targetRatio23: number, ppaPriceRequired23: number,
        targetRatio24: number, ppaPriceRequired24: number,
        targetRatio25: number, ppaPriceRequired25: number,
        targetRatio26: number, ppaPriceRequired26: number,
        targetRatio27: number, ppaPriceRequired27: number,
        targetRatio28: number, ppaPriceRequired28: number,
        targetRatio29: number, ppaPriceRequired29: number,
        targetRatio30: number, ppaPriceRequired30: number
};
export const TargetReturnRatiosDefaultValues = (): TargetReturnRatiosBaseValue => {
    return {
        targetRatio1: 0, ppaPriceRequired1: 0,
        targetRatio2: 0, ppaPriceRequired2: 0,
        targetRatio3: 0, ppaPriceRequired3: 0,
        targetRatio4: 0, ppaPriceRequired4: 0,
        targetRatio5: 0, ppaPriceRequired5: 0,
        targetRatio6: 0, ppaPriceRequired6: 0,
        targetRatio7: 0, ppaPriceRequired7: 0,
        targetRatio8: 0, ppaPriceRequired8: 0,
        targetRatio9: 0, ppaPriceRequired9: 0,
        targetRatio10: 0, ppaPriceRequired10: 0,
        targetRatio11: 0, ppaPriceRequired11: 0,
        targetRatio12: 0, ppaPriceRequired12: 0,
        targetRatio13: 0, ppaPriceRequired13: 0,
        targetRatio14: 0, ppaPriceRequired14: 0,
        targetRatio15: 0, ppaPriceRequired15: 0,
        targetRatio16: 0, ppaPriceRequired16: 0,
        targetRatio17: 0, ppaPriceRequired17: 0,
        targetRatio18: 0, ppaPriceRequired18: 0,
        targetRatio19: 0, ppaPriceRequired19: 0,
        targetRatio20: 0, ppaPriceRequired20: 0,
        targetRatio21: 0, ppaPriceRequired21: 0,
        targetRatio22: 0, ppaPriceRequired22: 0,
        targetRatio23: 0, ppaPriceRequired23: 0,
        targetRatio24: 0, ppaPriceRequired24: 0,
        targetRatio25: 0, ppaPriceRequired25: 0,
        targetRatio26: 0, ppaPriceRequired26: 0,
        targetRatio27: 0, ppaPriceRequired27: 0,
        targetRatio28: 0, ppaPriceRequired28: 0,
        targetRatio29: 0, ppaPriceRequired29: 0,
        targetRatio30: 0, ppaPriceRequired30: 0,
    }
};
