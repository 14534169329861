import gql from "graphql-tag";
import { Guid } from "guid-string";
import moment from "moment";
import { baseValueFields } from "../generated/baseValueFields";

export const baseValueFieldsFragment = gql`
    fragment baseValueFields on BaseValue {
        id
        name
        valuesJson
        archived
        createdDate
        updatedDate
    }
`;


export type BaseValue = Omit<baseValueFields, '__typename'>;

/**
 * Default values.
 */
export const baseValueDefaultValues = (): Partial<BaseValue> => {
    const now = moment();

    return {
        id: Guid.newGuid(),
        name: '',
        valuesJson: '{}',
        archived: false,
        createdDate: now.toISOString(),
        updatedDate: now.toISOString(),
    }
};