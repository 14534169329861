/**
 * Inner workings of a CalculationsProcessor, abstracted into a base class to keep the implemention seperate to the business logic.
 */
export class CalculationsProcessor {
    protected _inputs: NameValuePairs;
    protected _calculations: NameValuePairs;
    private _fieldCalculations: Array<FieldCalculation>;

    public constructor(inputs: NameValuePairs, calculations: NameValuePairs) {
        this._inputs = inputs;
        this._calculations = calculations;

        this._fieldCalculations = [];
    }

    /**
     * Initialize all caluclations for calculating fields.  Edit this method if you want to add a new calcualtion.
     */
    protected initializeCalculations(): void {
        // Default implementation does nothing.
    }

    /**
     * Repeats calculations affected by the updated fields.  Once a calculation has been performed, it will trigger a recalculation of fields that it depends on.
     * 
     * Any updated fields will be returned in the returned value.
     */
    recalculate(updatedFields?: Array<string>): NameValuePairs {
        // Initialize the field calculations on first use.
        if (!this._fieldCalculations.length) {
            this.initializeCalculations();
        }

        // Take a snapshot of the _calculations so we can work out what has changed.
        const snapshot = { ...this._calculations };

        // Execute all required calculations (recurssivly if needed).
        this.executeCalculations(updatedFields, 0);

        // Compare the snapshot to any updated values and get a list of updated fields.
        const changedFields = this.getChangedFields(snapshot, this._calculations);

        let ret: NameValuePairs = {};

        for (const field of changedFields) {
            ret[field] = this._calculations[field];
        }

        return ret;
    }

    /**
     * Execute all the calculations needed to handle updatedFields recursivly, with a safeguard ensuring we don't go past 
     * @param updatedFields
     * @param depth
     */
    private executeCalculations(updatedFields: Array<string> | undefined, depth: number): void {
        // If we have exceeded the max depth, throw an error.
        const maxDepth = 20;
        if (depth > maxDepth) {
            throw new Error(`Calculations have gone past the maximum depth of ${maxDepth} recurrsion.`);
        }


        for (const fieldCalculation of this._fieldCalculations) {
            // If we have a list of updatedFields, only run the calculation if that a dependency has been updated.
            if (updatedFields) {
                const anyMatchingDependencies = !!fieldCalculation.dependencies.find(dep => !!updatedFields.find(field => field === dep));
                if (!anyMatchingDependencies) {
                    continue;
                }
            }

            // If we get here we are going to run the calculation.
            const calcualtedValues = fieldCalculation.calculate();

            // Work out if any of the calculatedValues have changed.
            const newlyUpdatedFields = this.getChangedFields(this._calculations, calcualtedValues);
            
            // If any fields did change, updated the calculated values and then execute any calculations with newly updated dependencies via a recursive call.
            if (newlyUpdatedFields.length) {
                // Updated the stored calculated values.
                this._calculations = {
                    ...this._calculations,
                    ...calcualtedValues,
                };

                this.executeCalculations(newlyUpdatedFields, depth + 1);
            }
        }

    }

    /**
     * Returns any fields with values that are different between _calculations and calcualtedValues.
     */
    private getChangedFields(unchanged: NameValuePairs, changed: NameValuePairs): Array<string> {
        let ret: Array<string> = [];
        for (const updatedField of Object.keys(changed)) {
            const newValue = changed[updatedField];
            const oldValue = unchanged[updatedField];

            if (newValue !== oldValue) {
                ret.push(updatedField);
            }
        }
        return ret;
    }

    // Add a calculation to the field calculations for this calculator.
    protected addCalculation(calculate: () => NameValuePairs, deps: Array<string>): void {
        this._fieldCalculations.push({ calculate: calculate, dependencies: deps });
    }
}


/**
 * decreases a base amount for inflation for a number of years.
 **/
export function calculateInflationReducedFigure(year: number, baseAmount: number, indexationRate: number): number {

    if (!baseAmount) {
        return 0;
    }

    // indexation rate might be zero
    if (!indexationRate) {
        return baseAmount;
    }

    return baseAmount * Math.pow(indexationRate, year);
}

/**
 * increase a base amount for inflation for a number of years.
 **/
export function calculateInflatedFigure(year: number, baseAmount: number, indexationRate: number, isYear0Based: boolean = false): number {
    if (!baseAmount) {
        return 0;
    }

    // indexation rate might be zero
    if (!indexationRate) {
        return baseAmount;
    }

    // do the calculation
    if (isYear0Based) {
        return baseAmount * Math.pow((1 + (indexationRate / 100)), year);
    } else {
        return baseAmount * Math.pow((1 + (indexationRate / 100)), year - 1);
    }
}







export type NameValuePairs = { [key: string]: any };

export interface FieldCalculation {
    calculate: () => NameValuePairs,
    dependencies: Array<string>,
}
