import { AsyncActionStatus } from '../../../shared/abstractStore';
import { ProfileCreateInput } from '../generated/globalTypes';
import { registerProfileMutation, registerProfileMutationVariables } from '../generated/registerProfileMutation';
import { profileFieldsFragment } from '../models/Profile';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { useAsyncCallback } from 'react-use-async-callback';

/**
 * Get a callback to create a Profile in the store during registration.
 */
export function useRegisterProfileCallback(): [(model: ProfileCreateInput, financialModelId?: string) => Promise<void>, AsyncActionStatus] {
    //throw new Error('Users cannot self register for this app.');

    const [mutationAction, { error }] = useMutation<registerProfileMutation, registerProfileMutationVariables>(
        gql`
            mutation registerProfileMutation ($model: ProfileCreateInput!, $financialModelId: ID) {
                registerProfile(model: $model, financialModelId: $financialModelId) {
                    ...profileFields
                }
            }

            ${profileFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: ProfileCreateInput, financialModelId?: string) => {
        const result = await mutationAction({ variables: { model: model, financialModelId: financialModelId } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
