import * as React from 'react';
import { FinancialModelCreateInput, FinancialModelUpdateInput } from '../generated/globalTypes';
import { useSaveInStore, SaveInStoreOptions } from '../../../shared/useSaveInStore';
import { useCreateFinancialModelCallback } from './useCreateFinancialModelCallback';
import { useUpdateFinancialModelCallback } from './useUpdateFinancialModelCallback';
import { FinancialModel } from '../models/FinancialModel';

/**
 * Get a callback to save a FinancialModel in the store using either a create or update.
 */
export function useSaveFinancialModelCallback(options: SaveInStoreOptions<FinancialModel, string> = {}) {
    const [_create, createStatus] = useCreateFinancialModelCallback();
    const create = React.useCallback((model: Partial<FinancialModel>) => _create(model as FinancialModelCreateInput), [_create]);

    const [_update, updateStatus] = useUpdateFinancialModelCallback();
    const update = React.useCallback((id: string, changes: Partial<FinancialModel>) => _update(id, changes as FinancialModelUpdateInput), [_update]);

    return useSaveInStore(
        [create, createStatus],
        [update, updateStatus],
        options
    );
}
