import * as React from 'react';
import { Button, Row, Col, ButtonGroup, ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardTitle, CardSubtitle } from 'reactstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { Waypoint } from 'react-waypoint';
import { useReplaceSearchParamsEffect, useSearchParams } from '../../shared/useURLSearchParams';
import { useTranslation } from 'react-i18next';
import { SearchInput } from '../shared/searchInput/SearchInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MainContainer } from '../shared/MainContainer';
import { NoResultsFound } from '../shared/NoResultsFound';
import { StickyToolbar } from '../shared/StickyToolbar';
import { useHistory } from 'react-router';
import { ConditionalFragment } from 'react-conditionalfragment';
import { Banner } from '../shared/Banner';
import { useToggleStateArray } from 'use-toggle-state';
import { Background } from '../shared/background/Background';
import { CardsOrTable } from '../shared/cardsOrTable/CardsOrTable';
import { usePreferredListViewMode } from '../../globalState/preferredListViewMode/usePreferredListViewMode';
import { useUsersListViewBaseViewModel } from '../../api/main/profiles/viewModels/useUsersListViewBaseViewModel';

export interface UsersListBaseProps {
    title: string,
    filterByRoleGroups?: Array<string>,
    roleGroupColumnHidden?: boolean,
    baseRoute: string,
    addText?: string,
}

/**
 * List of administrator users.
 */
export const UsersListBase = (props: UsersListBaseProps) => {
    const {
        title,
        filterByRoleGroups,
        roleGroupColumnHidden,
        baseRoute,
        addText,
    } = props;

    const { t } = useTranslation();
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = React.useState<string>(searchParam ?? '');
    const { data: { items: allItems }, isLoading, errors: loadingErrors, fetchMore, hasMore } = useUsersListViewBaseViewModel({ pageSize: undefined });
    const [isMenuOpen, toggleMenuOpen] = useToggleStateArray();
    const history = useHistory();


    // Filter by the user's search client side so it can work when offline as well as online.
    const items = React.useMemo(() => {
        let ret = (allItems ?? []);

        // Filter by the role groups allowed.
        if (filterByRoleGroups) {
            ret = ret.filter(item => !!filterByRoleGroups.find(it => it === item.user?.roleGroup?.id));
        }

            let lowerSearch = search.toLocaleLowerCase();

            // Filter the items being displayed.
            ret = ret.filter(item =>
                item.firstName.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || item.lastName.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || item.user.email.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.user?.roleGroup?.name?.toLocaleLowerCase()?.indexOf(lowerSearch) ?? -1) >= 0
                || `${item.firstName} ${item.lastName}`.toLocaleLowerCase().indexOf(lowerSearch) >= 0 // Full name in languages "first last"
                || `${item.lastName} ${item.firstName}`.toLocaleLowerCase().indexOf(lowerSearch) >= 0 // Full name in languages "last first"
            );

        return ret;
    }, [allItems, search, filterByRoleGroups]);

    useReplaceSearchParamsEffect({ search: search });

    const [viewMode, setViewMode] = usePreferredListViewMode();


    return (
        <Background>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} md="auto">
                            <h1>
                                {title}
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                        <Col xs="auto">
                            <Row>
                                <ButtonGroup>
                                    <LinkContainer to={`${baseRoute}/add`}>
                                        <Button color="primary">
                                            <FontAwesomeIcon icon="plus" />
                                            <> </>
                                            {addText ?? t('usersListBase.add', 'Add')}
                                        </Button>
                                    </LinkContainer>
                                </ButtonGroup>
                            </Row>
                        </Col>
                        <Col xs={12} md="auto">
                            <ButtonGroup>
                                <Button color="secondary" outline={viewMode !== 'cards'} onClick={() => setViewMode('cards')}>
                                    <FontAwesomeIcon icon="th-large" />
                                    <span className="sr-only">{t('common.cards', 'Cards')}</span>
                                </Button>
                                <Button color="secondary" outline={viewMode !== 'table'} onClick={() => setViewMode('table')}>
                                    <FontAwesomeIcon icon="th-list" />
                                    <span className="sr-only">{t('common.list', 'List')}</span>
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={loadingErrors} />
                <CardsOrTable
                    viewMode={viewMode}
                    items={items}
                    tableHeadings={[
                        t('usersListBase.firstName', 'First name'),
                        t('usersListBase.lastName', 'Last name'),
                        t('usersListBase.email', 'Email or PIN'),
                        roleGroupColumnHidden ? null : t('usersListBase.roleGroup', 'Security'),
                    ]}
                    columns={[
                        (item, view) => view === 'cards' ? (<CardTitle tag="h5">{item.firstName + " " + item.lastName}</CardTitle>) : null,
                        (item, view) => view === 'cards' ? null : item.firstName,
                        (item, view) => view === 'cards' ? null : item.lastName,
                        (item, view) =>
                            view === 'cards' ? (<CardSubtitle tag="h6" className="text-muted">{item.user?.email ?? ''}</CardSubtitle>)
                                : item.user?.email ?? '',
                        (item) => roleGroupColumnHidden ? null : item.user?.roleGroup?.name,
                    ]}
                    buttons={(item) => (
                        <ButtonGroup>
                            <LinkContainer to={`/dashboard/${item.userId}`}>
                                <Button color="primary" outline>
                                    <FontAwesomeIcon icon="solar-panel" />
                                    <> {t('usersListBase.dashboard', 'Dashboard')}</>
                                </Button>
                            </LinkContainer>
                            <LinkContainer to={`${baseRoute}/edit/${item.id}`}>
                                <Button color="primary" outline>
                                    <FontAwesomeIcon icon="edit" />
                                    <> {t('common.edit', 'Edit')}</>
                                </Button>
                            </LinkContainer>
                            <ButtonDropdown isOpen={isMenuOpen(item.id)} toggle={() => toggleMenuOpen(item.id)}>
                                <DropdownToggle color="primary" outline caret>
                                    <span className="sr-only">{t('common.menuDropdown', 'More')}</span>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <LinkContainer to={`${baseRoute}/delete/${item.id}`}>
                                        <DropdownItem className="text-danger">
                                            <FontAwesomeIcon icon="trash" />
                                            <> {t('common.delete', 'Delete')}</>
                                        </DropdownItem>
                                    </LinkContainer>
                                </DropdownMenu>
                            </ButtonDropdown>
                        </ButtonGroup>
                    )}
                    onItemClick={item => history.push(`${baseRoute}/edit/${item.id}`)}
                />
                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && !items?.length}>

                    <NoResultsFound search={search} />

                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && hasMore()}>

                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />

                </ConditionalFragment>
            </MainContainer>
        </Background>
    );
};
