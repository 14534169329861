import { Library, library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner, faLock, faUser, faSearch, faPlus, faCogs, faSave, faEdit, faUsers, faTrash, faCaretUp, faCaretDown, faCaretLeft, faUndo, faMinus, faExclamationCircle, faCaretRight, faTimesCircle, faStopwatch, faUserClock, faTimes, faStop, faDesktop, faRuler, faFlagCheckered, faPlay, faUserCheck, faCreditCard, faPhone, faEnvelope, faSchool, faPoundSign, faVoteYea, faPersonBooth, faTrashAlt, faChevronLeft, faChevronRight, faDownload, faCross, faCheckCircle, faEye, faTrashRestore, faSync, faThumbsDown, faThumbsUp, faCloudDownloadAlt, faPhotoVideo, faTag, faUserTag, faLink, faFileAlt, faInfo, faUserGraduate, faIdCardAlt, faTachometerAlt, faUsersCog, faBuilding, faGraduationCap, faCarCrash, faChalkboardTeacher, faClipboardCheck, faTags, faSolarPanel, faVideo, faExclamationTriangle, faCheck, faMeh, faClone, faThLarge, faThList, faBus, faCommentDots, faTicketAlt, faWindowRestore, faUpload, faFolderMinus, faPlayCircle, faHome, faHistory, faUserShield, faBook, faShippingFast, faCode, faProjectDiagram, faTerminal, faCopy, faEyeSlash, faCalculator } from '@fortawesome/free-solid-svg-icons';
import { faFrown as farFrown, faFrownOpen as farFrownOpen, faCircle as farCircle, faClock as farClock, faCalendarAlt as farCalendarAlt, faListAlt as farListAlt, faCalendarTimes as farCalendarTimes, faSmile as farSmile } from '@fortawesome/free-regular-svg-icons';

// Import the fontawsome icons we actually want to use.
export const initializeIcons = () => {
    configureIcons(library);
};

export const configureIcons = (library: Library) => {
    library.add(
        // solid
        faBook,
        faBuilding,
        faBus,
        faCalculator,
        faCarCrash,
        faCaretUp,
        faCaretDown,
        faCaretLeft,
        faCaretRight,
        faChalkboardTeacher,
        faCheck,
        faCheckCircle,
        faChevronLeft,
        faChevronRight,
        faClipboardCheck,
        faClone,
        faCloudDownloadAlt,
        faCode,
        faCogs,
        faCommentDots,
        faCopy,
        faCreditCard,
        faCross,
        faDesktop,
        faDownload,
        faEdit,
        faExclamationCircle,
        faExclamationTriangle,
        faEye,
        faEyeSlash,
        faFileAlt,
        faFlagCheckered,
        faFolderMinus,
        faGraduationCap,
        faHistory,
        faHome,
        faIdCardAlt,
        faInfo,
        faLink,
        faLock,
        faMeh,
        faMinus,
        faPersonBooth,
        faPhotoVideo,
        faPlay,
        faPlayCircle,
        faPlus,
        faPoundSign,
        faProjectDiagram,
        faRuler,
        faSave,
        faSchool,
        faSearch,
        faShippingFast,
        faSolarPanel,
        faSpinner,
        faStop,
        faStopwatch,
        faSync,
        faTachometerAlt,
        faTag,
        faTags,
        faTerminal,
        faThLarge,
        faThList,
        faThumbsDown,
        faThumbsUp,
        faTicketAlt,
        faTimes,
        faTimesCircle,
        faTrash,
        faTrashAlt,
        faTrashRestore,
        faUndo,
        faUpload,
        faUser,
        faUserCheck,
        faUserClock,
        faUsersCog,
        faUserGraduate,
        faUserTag,
        faUsers,
        faUserShield,
        faVideo,
        faVoteYea,
        faWindowRestore,

        // regular
        farCalendarAlt,
        farCalendarTimes,
        farCircle,
        farClock,
        farFrown,
        farFrownOpen,
        farListAlt,
        farSmile,
        faPhone,
        faEnvelope,
    );
};
