import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { ConditionalFragment } from 'react-conditionalfragment';
import { useTranslation } from 'react-i18next';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import { Button, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { useFinancialModels } from '../../api/main/financialModels/useFinancialModels';
import { useCurrentUser } from '../../api/main/users/useCurrentUser';
import { AlertOnErrors } from '../../shared/alertOnErrors';
import { Background } from '../shared/background/Background';
import { Banner } from '../shared/Banner';
import { LoadingIndicator } from '../shared/LoadingIndicator';
import { MainContainer } from '../shared/MainContainer';
import { NoResultsFound } from '../shared/NoResultsFound';

export interface DashboardProps {
    userId: string,
}

/**
 * Dashboard for a user.
 */
export const Dashboard = (props: DashboardProps) => {
    const {
        userId
    } = props;

    const { t } = useTranslation();

    const { data: { items: financialModels }, isLoading, errors: loadErrors, } = useFinancialModels({ userId });

    const currentUser = useCurrentUser();

    return (
        <Background>
            <Banner className="dashboard-container">
                <Row>
                    <Col>
                        <h1>{t('dashboard.heading', 'Your saving calculations')}</h1>
                    </Col>
                    <ConditionalFragment showIf={isLoading}>
                        <Col xs="auto">
                            <LoadingIndicator size="sm" />
                        </Col>
                    </ConditionalFragment>
                </Row>
            </Banner>

            <MainContainer className="dashboard-container">
                <AlertOnErrors errors={[loadErrors,]} />

                <div>
                    <Row>
                        <Col></Col>
                        <Col xs="auto">
                            <LinkContainer to={currentUser?.id === userId ? '/calculator' : `/calculator/for/${userId}`}>
                                <Button color="primary">
                                    <FontAwesomeIcon icon="plus" />
                                    <> </>
                                    {t('dashboard.add', 'Calculate savings')}
                                </Button>
                            </LinkContainer>
                        </Col>
                    </Row>

                    <ListGroup className="mt-2">
                        {
                            financialModels?.map(item => (
                                <ListGroupItem key={item.id}>
                                    <Row>
                                        <Col>
                                            <Link to={`/review/${item.id}`}>
                                                {item.name}
                                            </Link>
                                        </Col>
                                        <Col xs={12} md="auto">
                                            {t('common.dateTime', '{{date, DD/MM/YYYY HH:mm}}', { date: moment(item.updatedDate) })}
                                        </Col>
                                    </Row>
                                </ListGroupItem>
                                ))
                        }

                        <ConditionalFragment showIf={!isLoading && !financialModels?.length}>
                            <NoResultsFound>
                                {t('dashboard.noFinancialModels', 'You have not saved any savings financial models yet.')}
                                <br />
                                <Link to="/calculator">
                                    {t('dashboard.noFinancialModels', 'Model your savings now.')}
                                </Link>
                            </NoResultsFound>
                        </ConditionalFragment>
                    </ListGroup>
                </div>
            </MainContainer>
        </Background>
        );
};