import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { BaseValueCreateInput } from '../generated/globalTypes';
import { createBaseValueMutation, createBaseValueMutationVariables } from '../generated/createBaseValueMutation';
import { baseValueFieldsFragment } from '../models/BaseValue';

/**
 * Get a callback to create a BaseValue in the store.
 */
export function useCreateBaseValueCallback(): [(model: BaseValueCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createBaseValueMutation, createBaseValueMutationVariables>(
        gql`
            mutation createBaseValueMutation ($model: BaseValueCreateInput!) {
                createBaseValue(model: $model) {
                    ...baseValueFields
                }
            }

            ${baseValueFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: BaseValueCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
