import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useValidatorCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { useCallback, useEffect, useMemo, } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { useAsyncCallback } from "react-use-async-callback";
import { Button, Col, FormGroup, Label, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useBaseValue } from "../../api/main/baseValues/useBaseValue";
import { useSaveBaseValueCallback } from "../../api/main/baseValues/useSaveBaseValueCallback";
import { baseValueDefaultValues } from "../../api/main/models/BaseValue";
import { BaseValueType, baseValueId, baseValueTypeFromId, } from "../../api/main/models/codeOnly/BaseValue";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useChanges } from "../../shared/useChanges";
import { useJsonObject } from "../../shared/useJsonObject";
import { Background } from "../shared/background/Background";
import { Banner } from "../shared/Banner";
import { LoadingIndicator } from "../shared/LoadingIndicator";
import { MainContainer } from "../shared/MainContainer";
import { StickyToolbar } from "../shared/StickyToolbar";
import { CO2UnitConversionDefaultValues, CO2UnitConversionNode } from "./edit/CO2UnitConversionNode";
import { TargetReturnRatiosDefaultValues, TargetReturnRatiosNode } from "./edit/TargetReturnRatiosNode";
import './editBaseValue.scss';

interface EditBaseValueProps {
    isCreate?: boolean,
}

/**
 * Create a new baseValue.
 */
export const CreateBaseValue = () => (<EditBaseValue isCreate={true} />);

/**
 * Edit a baseValue.
 */
export const EditBaseValue = (props: EditBaseValueProps) => {
    const { isCreate } = props;
    const { t } = useTranslation();
    const history = useHistory();

    const { id } = useParams<{ id: string | undefined,}>();

    const { data: { model: storeModel }, isLoading: _isLoading, errors: loadErrors } = useBaseValue(id);
    const isLoading = _isLoading;

    const baseValueName = useMemo(() => { return baseValueTypeFromId(id, t); }, [id, t]);
    
    const defaultJsonValues = useMemo(() => {
        if (isLoading || !isCreate) {
            return;
        }

        if (storeModel === null) {
            // have not got an existing record so we'll need to create one

            // get the right defaults for the Base Value
            let defaultValues = '';
            if (id === baseValueId(BaseValueType.CO2UnitConversion)) {
                defaultValues = JSON.stringify({ ...CO2UnitConversionDefaultValues() });
            } else if (id === baseValueId(BaseValueType.TargetReturnRatios)) {
                defaultValues = JSON.stringify({ ...TargetReturnRatiosDefaultValues() });
            }

            return defaultValues;

        }
        return;
    }, [id, storeModel, isLoading, isCreate]);

    // redirect to Create
    useEffect(() => {
        if (isLoading || storeModel !== null || !id) {
            return;
        }

        history.push(`/administration/base-value/create/${id}`);

    }, [isLoading, storeModel, id, history, ]);

    const { model, change, changes } = useChanges(storeModel, isCreate ? { ...baseValueDefaultValues(), id: id, valuesJson: defaultJsonValues, name: baseValueName ?? '' } : undefined );
    const [save, { errors: saveErrors }] = useSaveBaseValueCallback();

    // base values values get stored in JSON to ease the development overhead of storing new items.
    const [valuesModel, _setValuesModel] = useJsonObject<any>(model?.valuesJson ?? '{}', (json) => change({ valuesJson: json }));

    // Change the value of an input model.
    const changeValuesModel = useCallback((changes: any) => {
        // Update the data.
        _setValuesModel((prevState: any) => ({
            ...(prevState ?? {}),
            ...changes,
        }));

    }, [_setValuesModel,]);

    // Validate.
    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        const rules = {
            name: () => !model?.name ? t('editBaseValue.nameRequired', 'Name is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    // Save changes.
    const [saveForm, { isExecuting: isSaving, errors: saveFormErrors }] = useAsyncCallback(async () => {
        if (!validate()) {
            return;
        }

        await save(model.id, changes, !!isCreate);

        history.push('/administration/financial-models');

    }, [validate, save, model, changes, storeModel, history]);

    return (
        <Background>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} md={'auto'}>
                            <h1>
                                {
                                    model?.name ? (
                                        <>{t('editBaseValue.Heading', `Manage base data: ${model.name}`)}</>
                                    ) : (
                                            <>{t('editBaseValue.defaultHeading', 'Manage base data')}</>
                                    )
                                }
                            </h1>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                        <Col xs={12} md="auto">
                            <ConditionalFragment showIf={!isLoading}>
                                <ButtonAsync color="primary" isExecuting={isSaving} onClick={() => saveForm()}
                                    executingChildren={<><Spinner size="sm" /> {t('common.saving', 'Saving...')}</>}>
                                    <FontAwesomeIcon icon="save" />
                                    <> {t('common.save', 'Save')}</>
                                </ButtonAsync>
                            </ConditionalFragment>
                            <> </>
                            <Button type="button" color="primary" outline onClick={() => history.goBack()}>
                                {t('common.cancel', 'Cancel')}
                            </Button>
                            <> </>
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer className="edit-base-value" fluid>
                <AlertOnErrors errors={[loadErrors, saveFormErrors, saveErrors]} />

                <div>
                    <FormGroup>
                        <Label htmlFor="name">{t('editBaseValue.name.label', 'Model name')}</Label>
                        <ValidatedInput name="name" type="text"
                            value={model?.name ?? ''}
                            onChange={e => change({ name: e.currentTarget.value })}
                            onBlur={e => validate('name')}
                            validationErrors={validationErrors['name']} />
                    </FormGroup>
                    <ConditionalFragment showIf={id === baseValueId(BaseValueType.CO2UnitConversion)}>
                        <CO2UnitConversionNode valuesModel={valuesModel} changeValuesModel={changeValuesModel} />
                    </ConditionalFragment>
                    <ConditionalFragment showIf={id === baseValueId(BaseValueType.TargetReturnRatios)}>
                        <TargetReturnRatiosNode valuesModel={valuesModel} changeValuesModel={changeValuesModel} />
                    </ConditionalFragment>
                </div>

            </MainContainer>
        </Background>
    );
};

