import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { FinancialModelCreateInput } from '../generated/globalTypes';
import { createFinancialModelMutation, createFinancialModelMutationVariables } from '../generated/createFinancialModelMutation';
import { financialModelFieldsFragment } from '../models/FinancialModel';

/**
 * Get a callback to create a FinancialModel in the store.
 */
export function useCreateFinancialModelCallback(): [(model: FinancialModelCreateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<createFinancialModelMutation, createFinancialModelMutationVariables>(
        gql`
            mutation createFinancialModelMutation ($model: FinancialModelCreateInput!) {
                createFinancialModel(model: $model) {
                    ...financialModelFields
                }
            }

            ${financialModelFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (model: FinancialModelCreateInput) => {
        const result = await mutationAction({ variables: { model: model } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
