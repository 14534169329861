import { IdentityRoles } from "../../../configure/security/IdentityRoles";
import { RouteEntry } from "../../../shared/routes";
import { DeleteRegisteredUser } from "./DeleteRegisteredUser";
import { CreateRegisteredUser, EditRegisteredUser } from "./EditRegisteredUser";
import { RegisteredUsersList } from "./RegisteredUsersList";


export const registeredUsersRoutes: Array<RouteEntry> = [
    { path: '/administration/registered-users', exact: true, component: RegisteredUsersList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/registered-users/edit/:id', component: EditRegisteredUser, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/registered-users/add', component: CreateRegisteredUser, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/registered-users/delete/:id', component: DeleteRegisteredUser, authorize: true, requireRole: IdentityRoles.Administration },
];
