import { useMemo } from "react";
import { calculateInflatedFigure, calculateInflationReducedFigure } from "../../../services/calculationsProcessor/CalculationsProcessor";

export interface YearCalculation {
    totalExported: number,
    totalGeneration: number,
    exportTarrif: number,
    ppaTariff: number,
    revenue: number,
    oAndM: number,
    oAndMManager: number,
    businessRates: number,
    transmissionFee: number,
    landLease: number,
    insurance: number,
    audit: number,
    managementFee: number,
    refurbishmentFee: number,
    totalExpenses: number,
    ebitda: number,
    openingBalance: number,
    closingBalance: number,
    systemYield: number,
    energyCost: number,
    solarEnergyCost: number,
    receivables: number,
    payables: number,
    workingCapital: number,
    ebit: number,
    interestExpense: number,
    ebt: number,
    taxOutlay: number,
    netIncome: number,
    energyCostWithoutSolar: number,
    energyCostWithSolar: number,
    annualSavings: number,
}

export const yearCalculationDefaultValues = (): YearCalculation => {

    return {
        totalExported: 0,
        totalGeneration: 0,
        exportTarrif: 0,
        ppaTariff: 0,
        revenue: 0,
        oAndM: 0,
        oAndMManager: 0,
        businessRates: 0,
        transmissionFee: 0,
        landLease: 0,
        insurance: 0,
        audit: 0,
        managementFee: 0,
        refurbishmentFee: 0,
        totalExpenses: 0,
        ebitda: 0,
        openingBalance: 0,
        closingBalance: 0,
        systemYield: 0,
        energyCost: 0,
        solarEnergyCost: 0,
        receivables: 0,
        payables: 0,
        workingCapital: 0,
        ebit: 0,
        interestExpense: 0,
        ebt: 0,
        taxOutlay: 0,
        netIncome: 0,
        energyCostWithoutSolar: 0,
        energyCostWithSolar: 0,
        annualSavings: 0,
    }
};
/**
 * Returns all the figures required for each year of the model adjusted by te appropriate indexation rate.
 **/
export function useCalculateForYear(inputsModel: any | undefined, calculationsModel: any | undefined, year: number, energySalesPriceFromSnapshot: number,) {
    const yearCalculations = useMemo(() => {
        return calculateForYear(inputsModel, calculationsModel, year, energySalesPriceFromSnapshot, );


    }, [inputsModel, calculationsModel, year, energySalesPriceFromSnapshot, ]);

    return yearCalculations;
}

export function calculateForYear(inputsModel: any | undefined, calculationsModel: any | undefined, year: number, energySalesPriceFromSnapshot: number,) {
    const isYearWithinppaTerm = inputsModel?.ppaTerm >= year;
    const isYearUnderppaTerm = inputsModel?.ppaTerm > year;

    const totalExported = calculateInflationReducedFigure(year, calculationsModel?.export, calculationsModel?.nonDegradationMultiplier);
    const totalGeneration = calculateInflationReducedFigure(year, calculationsModel?.takeOrPay, calculationsModel?.nonDegradationMultiplier);
    const ppaTariff = isYearWithinppaTerm ? calculateInflatedFigure(year, energySalesPriceFromSnapshot, inputsModel?.annualInflation) : 0;
    const exportTarrif = isYearWithinppaTerm ? calculateInflatedFigure(year, inputsModel?.exportTariff, inputsModel?.rpi) : 0;
    const oAndM = calculateInflatedFigure(year, calculationsModel?.oAndMCalculation, inputsModel?.rpi);
    const oAndMManager = calculateInflatedFigure(year, inputsModel?.oAndMManager, inputsModel?.rpi);
    const businessRates = calculateInflatedFigure(year, calculationsModel?.businessRates, inputsModel?.rpi);
    const transmissionFee = calculateInflatedFigure(year, inputsModel?.transmissionFee, inputsModel?.rpi);
    const landLease = calculateInflatedFigure(year, inputsModel?.landLease, inputsModel?.rpi);
    const insurance = calculateInflatedFigure(year, calculationsModel?.insuranceCalculation, inputsModel?.insuranceandLCInflation);
    const audit = calculateInflatedFigure(year, inputsModel?.audit, inputsModel?.rpi);
    const managementFee = calculateInflatedFigure(year, calculationsModel?.managementFee, inputsModel?.managementFeeInflation);
    const refurbishmentFee = calculateInflatedFigure(year, calculationsModel?.refurbishmentFeeCalculation, inputsModel?.rpi);
    const openingBalance = calculationsModel?.totalCapex2 - (calculationsModel?.depreciation * (year - 1));
    const closingBalance = calculationsModel?.totalCapex2 - (calculationsModel?.depreciation * year);
    const systemYield = calculateInflationReducedFigure(year, calculationsModel?.estimatedOutput, calculationsModel?.nonDegradationMultiplier);
    const energyCost = calculateInflatedFigure(year, inputsModel?.currentEnergyCost, inputsModel?.energyInflation, true);
    const solarEnergyCost = calculateInflatedFigure(year, energySalesPriceFromSnapshot, inputsModel?.annualInflation, true);


    // some things calculated here and not in calculator as they rely on more than 1 different rate for inflation calculation
    const revenue = isYearWithinppaTerm ? ((totalExported * (exportTarrif / 100)) + (totalGeneration * (ppaTariff / 100))) : 0;
    const totalExpenses = isYearWithinppaTerm ? oAndM + oAndMManager + businessRates + transmissionFee + landLease + insurance + audit + managementFee + refurbishmentFee : 0;
    const ebitda = revenue - totalExpenses;
    const receivables = revenue * (inputsModel?.receivablesDays / 365);
    const payables = totalExpenses * (inputsModel?.payablesDays / 365);
    const workingCapital = receivables - payables;
    const ebit = isYearWithinppaTerm ? ebitda - calculationsModel?.depreciation : 0;
    // TODO no calc for interest rate provided
    const interestExpense = 0;
    const ebt = isYearWithinppaTerm ? ebit - interestExpense : 0;
    const taxOutlay = isYearWithinppaTerm ? (year === 1 ? ebt * (inputsModel?.taxNow?? 0) / 100 : ebt * (inputsModel?.taxFuture ?? 0) / 100) : 0;
    const netIncome = ebt - taxOutlay;
    const energyCostWithoutSolar = totalGeneration * energyCost / 100;
    const energyCostWithSolar = isYearUnderppaTerm ? totalGeneration * solarEnergyCost / 100 : 0;
    const annualSavings = energyCostWithoutSolar - energyCostWithSolar;

    return {
        totalExported: totalExported,
        totalGeneration: totalGeneration,
        exportTarrif: exportTarrif,
        ppaTariff: ppaTariff,
        revenue: revenue,
        oAndM: oAndM,
        oAndMManager: oAndMManager,
        businessRates: businessRates,
        transmissionFee: transmissionFee,
        landLease: landLease,
        insurance: insurance,
        audit: audit,
        managementFee: managementFee,
        refurbishmentFee: refurbishmentFee,
        totalExpenses: totalExpenses,
        ebitda: ebitda,
        openingBalance: openingBalance,
        closingBalance: closingBalance,
        systemYield: systemYield,
        energyCost: energyCost,
        solarEnergyCost: solarEnergyCost,
        receivables: receivables,
        payables: payables,
        workingCapital: workingCapital,
        ebit: ebit,
        interestExpense: interestExpense,
        ebt: ebt,
        taxOutlay: taxOutlay,
        netIncome: netIncome,
        energyCostWithoutSolar: energyCostWithoutSolar,
        energyCostWithSolar: energyCostWithSolar,
        annualSavings: annualSavings,
    } as YearCalculation
}

