import { CreateUserBase, EditUserBase } from "../EditUserBase";

/**
 * Create a user.
 */
export const CreateRegisteredUser = () => (
    <CreateUserBase
        onCreateDefaultValues={() => ({ /* No defaults needed */ })}
        defaultRoleGroup=""
        filterRoleGroups={(groups) => groups.filter(item => item.id === 'Users')}
    />);

/**
 * Edit a user.
 */
export const EditRegisteredUser = () => (
    <EditUserBase
        defaultRoleGroup=""
        filterRoleGroups={(groups) => groups.filter(item => item.id === 'Users')}
    />);
