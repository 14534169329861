import { useTranslation } from "react-i18next";
import { FormGroup, Label, } from "reactstrap";
import { formatNumber } from "../../financialModels/utilities/useFormattedNumber";
import { NumberInput } from "../../shared/numberInput/NumberInput";

export interface CO2UnitConversionNodeProps {
    valuesModel: any | undefined,
    changeValuesModel: (changes: any) => void
}

/**
 * node for maintaining the BaseValue CO2UnitConversion.
 * @param props
 */
export const CO2UnitConversionNode = (props: CO2UnitConversionNodeProps) => {
    const {
        valuesModel, changeValuesModel,
    } = props;

    const { t } = useTranslation();

    return (
        <>
            <FormGroup>
                <Label htmlFor="scope2">{t('cO2UnitConversionNode.scope2.label', 'Scope 2 (Generation)')}</Label>
                <NumberInput
                    value={valuesModel?.scope2 ?? 0}
                    onFormat={value => t('common.number', `{{value, #,0.00000}}`, { value })}
                    onChange={e => changeValuesModel({ scope2: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                />
            </FormGroup>
            <FormGroup>
                <Label htmlFor="scope3">{t('cO2UnitConversionNode.scope3.label', 'Scope 3 (T&D and WTT)')}</Label>
                <NumberInput
                    value={valuesModel?.scope3 ?? 0}
                    onFormat={value => t('common.number', `{{value, #,0.00000}}`, { value })}
                    onChange={e => changeValuesModel({ scope3: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                />
            </FormGroup>
            <FormGroup>
                <Label htmlFor="total">{t('cO2UnitConversionNode.total.label', 'Total')}</Label>
                <div className="input-value">
                    {formatNumber((valuesModel?.scope2 ?? 0) + (valuesModel.scope3 ?? 0), { isDecimals5: true}) }
                </div>
            </FormGroup>
        </>
    );
};

/**
 * Layout for CO2 Unit Conversion
 */
export interface CO2UnitConversionBaseValue {
    scope2: number,
    scope3: number,
}
export const CO2UnitConversionDefaultValues = (): CO2UnitConversionBaseValue => {
    return {
        scope2: 0,
        scope3: 0,
    }
};