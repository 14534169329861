import { useParams } from "react-router";
import { Dashboard } from "./Dashboard";
import "./dashboard.scss"
/**
 * Dashboard for a user with the userId given on the route.
 */
export const UserDashboard = () => {
    const { userId } = useParams<{ userId: string }>();

    return (
        <Dashboard userId={userId} />
        )
};