import { TFunction } from "i18next";

/**
 * Base Values
 */
export enum BaseValueType {
    CO2UnitConversion = "CO2UnitConversion",
    TargetReturnRatios = "TargetReturnRatios",
}


/**
 * Return base value fixed id.
 * @param baseValue
 */
export function baseValueId(baseValue: BaseValueType | undefined): string | undefined{
    switch (baseValue) {
        case BaseValueType.CO2UnitConversion: return '28D15C72-5568-4396-AB25-7110E7F2AAD7';
        case BaseValueType.TargetReturnRatios: return '434CEFBB-A38F-410C-8195-56C651450FB8';
    }
}

/**
 * Return base value display name.
 * @param baseValue
 * @param t
 */
export function baseValuedisplayName(baseValue: BaseValueType | undefined, t: TFunction): string | undefined{
    switch (baseValue) {
        case BaseValueType.CO2UnitConversion: return t('baseValueTypeDisplayName.cO2Savings', 'CO2 savings');
        case BaseValueType.TargetReturnRatios: return t('baseValueDisplayName.targetReturnRatios', 'Target return ratios');
    }
}

/**
 * Return base value from id.
 * @param requestedBaseValueId
 * @param t
 */
export function baseValueFromId(requestedBaseValueId: string): BaseValueType | undefined {
    switch (requestedBaseValueId) {
        case baseValueId(BaseValueType.CO2UnitConversion): return BaseValueType.CO2UnitConversion;
        case baseValueId(BaseValueType.TargetReturnRatios): return BaseValueType.TargetReturnRatios;
        default: return undefined;
    }
}

/**
 * Return base value type from id.
 * @param requestedBaseValueId
 * @param t
 */
export function baseValueTypeFromId(requestedBaseValueId: string | undefined, t: TFunction): string | undefined {
    switch (requestedBaseValueId) {
        case baseValueId(BaseValueType.CO2UnitConversion): return t('baseValueType.cO2Savings', 'CO2UnitConversion');
        case baseValueId(BaseValueType.TargetReturnRatios): return t('baseValueType.targetReturnRatios', 'TargetReturnRatios');
        default: return '';
    }
}


/**
 * Return base value display name from id.
 * @param requestedBaseValueId
 * @param t
 */
export function baseValueDisplayNameFromId(requestedBaseValueId: string | undefined, t: TFunction): string | undefined {
    switch (requestedBaseValueId) {
        case baseValueId(BaseValueType.CO2UnitConversion): return t('baseValueTypeDisplayName.cO2Savings', 'CO2 savings');
        case baseValueId(BaseValueType.TargetReturnRatios): return t('baseValueTypeDisplayName.targetReturnRatios', 'Target return ratios');
        default: return '';
    }
}

