import * as React from "react";
import { FormGroup, Label } from "reactstrap";
import { useChanges } from "../../shared/useChanges";
import { useValidatorCallback, ValidateCallback } from "pojo-validator-react";
import { ValidatedInput } from "pojo-validator-reactstrap";
import { useTranslation } from "react-i18next";
import { Profile as ProfileModel, profileDefaultValues } from '../../api/main/models/Profile';
import { useRegisterProfileCallback } from "../../api/main/profiles/useRegisterProfileCallback";
import { ProfileCreateInput } from "../../api/main/generated/globalTypes";
import { ValidationErrors } from "pojo-validator";

export interface RegisterProfileDetailsProps {
    // Public
    registerProfile: (userId: string, financialModelId?: string) => Promise<void>,
    isRegisteringProfile: boolean,
    registerProfileErrors: any,

    validate: ValidateCallback,
    validationErrors: ValidationErrors

    // Private
    model: ProfileModel,
    change: (changes: Partial<ProfileModel>) => void,
    changes: Partial<ProfileModel>,

}

/**
 * Custom hook that lets users of RegisterProfileDetails own the state of the Profile without being aware of its format.
 * @param userId
 */
export function useRegisterProfileDetailsProps(): RegisterProfileDetailsProps {
    const { t } = useTranslation();

    const [_registerProfile, { isExecuting: isRegisteringProfile, errors: registerProfileErrors }] = useRegisterProfileCallback();
    const { model, change, changes } = useChanges<ProfileModel>(null, { ...profileDefaultValues() });

    const [validate, validationErrors] = useValidatorCallback((validation, fieldsToCheck) => {
        if (!model) {
            return;
        }

        const rules = {
            firstName: () => !model.firstName ? t('registerProfileDetails.firstNameRequired', 'First name is required') : '',
            lastName: () => !model.lastName ? t('registerProfileDetails.lastNameRequired', 'Last name is required') : '',
        };

        validation.checkRules(rules, fieldsToCheck);
    }, [model]);

    const registerProfile = React.useCallback(async (userId: string, financialModelId?: string) => {
        await _registerProfile({ ...model, userId: userId } as ProfileCreateInput, financialModelId);
    }, [_registerProfile, model]);

    return {
        registerProfile: registerProfile,
        isRegisteringProfile: isRegisteringProfile,
        registerProfileErrors: registerProfileErrors,

        validate: validate,
        validationErrors: validationErrors,

        model: model,
        change: change,
        changes: changes,
    };
};


/**
 * Allow the user to set their personal details on their profile during registration.
 */
export const RegisterProfileDetails = (props: RegisterProfileDetailsProps & { children?: React.ReactNode }) => {
    const { model, change, validate, validationErrors, children, } = props;
    const { t } = useTranslation();

    // Render the UI.
    //
    return (
        <>
            <FormGroup>
                <Label htmlFor="firstName">{t('profileDetails.firstName', 'First name')}</Label>
                <ValidatedInput name="firstName" type="text" value={model.firstName ?? ''} onChange={e => change({ firstName: e.currentTarget.value })} onBlur={e => validate('firstName')} validationErrors={validationErrors['firstName']} />
            </FormGroup>
            <FormGroup>
                <Label htmlFor="lastName">{t('profileDetails.lastName', 'Last name')}</Label>
                <ValidatedInput name="lastName" type="text" value={model.lastName ?? ''} onChange={e => change({ lastName: e.currentTarget.value })} onBlur={e => validate('lastName')} validationErrors={validationErrors['lastName']} />
            </FormGroup>

            {children}
        </>
    );
};
