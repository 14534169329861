import './header.scss';

import { Nav, Container } from 'reactstrap';
import { AppHeader, AppSidebarToggler } from '@coreui/react';
import { UserNav } from './UserNav';
import { Link } from 'react-router-dom';
import { Navigation } from '../home/Navigation';
import { AppNavbarBrand } from './appNavbarBrand/AppNavbarBrand';

export const Header = () => {
    return (
        <AppHeader fixed className="header">
            <Container fluid>
                <AppSidebarToggler className="d-lg-none" display="md" mobile />

                <Link to="/">
                    <AppNavbarBrand />
                </Link>
                <AppSidebarToggler className="d-md-down-none flex-shrink" display="lg" />

                <Nav className="menu-nav d-none d-md-flex flex-grow-1">
                    <Navigation />
                </Nav>
                <Nav className="menu-nav-xs d-flex d-md-none flex-grow-1">        
                </Nav>               
            
                <Nav className="signin-nav">
                    <UserNav />
                </Nav>

                {/*<Nav className="language-nav">*/}
                {/*    <NavItem>*/}
                {/*        <LanguageSelect />*/}
                {/*    </NavItem>*/}
                {/*</Nav>*/}
            </Container>
        </AppHeader>
    );
};
