import moment from "moment";
import { useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { ISODateTimeInput } from "../../../shared/ISODateTimeInput";
import { NumberInput } from "../../../shared/numberInput/NumberInput";
import { StyledSlider } from "../../../shared/StyledSlider";
import { formatNumber } from "../../utilities/useFormattedNumber";
import './assumptionsTab.scss';

export interface AssumptionsTabProps {
    inputsModel: any | undefined,
    isExcludeInflation: boolean,
    changeInputsModel: (changes: any) => void,
    calculationsModel: any | undefined,
    targetReturnRatio: { ratio: number, energySalesPrice: number },
}

/**
 * Tab for maintaining the assumptions on a financial model.
 * @param props
 */
export const AssumptionsTab = (props: AssumptionsTabProps) => {
    const {
        inputsModel, // all inputs
        isExcludeInflation,
        changeInputsModel, // use this for the input fields on this tab
        calculationsModel, // holds the bottom level calculations created by the calculator from the inputs
        targetReturnRatio, // from snapshot basevalues
    } = props;

    const { t } = useTranslation();

    const energySalesPrice = useMemo(() => { return !!targetReturnRatio ? targetReturnRatio.energySalesPrice : 0; }, [targetReturnRatio]);

    return (
        <>
            <Row className="assumptions-container">
                <Col>
                    <div className="assumptions">
                        <FormGroup>
                            <Label htmlFor="sizeOfArray">{t('assumptionsTab.sizeOfArray.label', 'Size of Array kWp')}</Label>
                            <NumberInput
                                value={inputsModel?.sizeOfArray ?? 0}
                                onFormat={value => t('common.number.noDecimal', `{{value, #,0}}`, { value })}
                                onChange={e => changeInputsModel({ sizeOfArray: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                            />
                            <div className="slider-container">
                                <StyledSlider step={10} min={0} max={2000}
                                    value={inputsModel?.sizeOfArray ?? 0} onChange={val => changeInputsModel({ sizeOfArray: val })}
                                    marksStartPoint={0}
                                    marksEndPoint={2000}
                                    marksStep={100}
                                />
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="annualInflation">{t('assumptionsTab.annualInflation.label', 'Annual Inflation - RPI')}</Label>
                            <ConditionalFragment showIf={isExcludeInflation}>
                                <div>
                                    <Label className="small-label" htmlFor="annualInflation">{t('assumptionsTab.inflation.excluded.label', '(inflation set to not included on Inputs/Outputs tab)')}</Label>
                                </div>
                            </ConditionalFragment>
                            <NumberInput
                                value={inputsModel?.annualInflation ?? 0}
                                onFormat={value => t('common.number.percentage', `{{value, #,0.00%}}`, { value: value / 100 })}
                                onChange={e => changeInputsModel({ annualInflation: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                                disabled={isExcludeInflation}
                            />
                            <div className="slider-container">
                                <StyledSlider step={0.25} min={0} max={20}
                                    value={inputsModel?.annualInflation ?? 0} onChange={val => changeInputsModel({ annualInflation: val })}
                                    marksStartPoint={0}
                                    marksEndPoint={20}
                                    marksStep={0.5}
                                    disabled={isExcludeInflation}
                                />
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="energyInflation">{t('assumptionsTab.energyInflation.label', 'Energy Inflation - estimate')}</Label>
                            <ConditionalFragment showIf={isExcludeInflation}>
                                <div>
                                    <Label className="small-label" htmlFor="annualInflation">{t('assumptionsTab.inflation.excluded.label', '(inflation set to not included on Inputs/Outputs tab)')}</Label>
                                </div>
                            </ConditionalFragment>
                            <NumberInput
                                value={inputsModel?.energyInflation ?? 0}
                                onFormat={value => t('common.number.percentage', `{{value, #,0.00%}}`, { value: value / 100 })}
                                onChange={e => changeInputsModel({ energyInflation: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                                disabled={isExcludeInflation}
                            />
                            <div className="slider-container">
                                <StyledSlider step={0.25} min={0} max={20}
                                    value={inputsModel?.energyInflation ?? 0} onChange={val => changeInputsModel({ energyInflation: val })}
                                    marksStartPoint={0}
                                    marksEndPoint={20}
                                    marksStep={0.5}
                                    disabled={isExcludeInflation}
                                />
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="energyUsedOnSite">{t('assumptionsTab.energyUsedOnSite.label', 'Energy Used On Site')}</Label>
                            <NumberInput
                                value={inputsModel?.energyUsedOnSite ?? 0}
                                onFormat={value => t('common.number.percentage.nodecimals', `{{value, #,0%}}`, { value: value / 100 })}
                                onChange={e => changeInputsModel({ energyUsedOnSite: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                            />
                            <div className="slider-container">
                                <StyledSlider step={1} min={0} max={100}
                                    value={inputsModel?.energyUsedOnSite ?? 0} onChange={val => changeInputsModel({ energyUsedOnSite: val })}
                                    marksStartPoint={0}
                                    marksEndPoint={100}
                                    marksStep={10}
                                />
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="currentEnergyCost">{t('assumptionsTab.currentEnergyCost.label', 'Current cost of energy (p / kWh)')}</Label>
                            <div className="inputs-value">
                                {formatNumber(inputsModel?.currentEnergyCost ?? 0, { isPence: true, isDecimals: true, })}
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="specificYield">{t('assumptionsTab.specificYield.label', 'Specific Yield (kWh/kWp)')}</Label>
                            <NumberInput
                                value={inputsModel?.specificYield ?? 0}
                                onFormat={value => t('common.number.nodecimals', `{{value, #,0}}`, { value: value })}
                                onChange={e => changeInputsModel({ specificYield: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                            />
                            <div className="slider-container">
                                <StyledSlider step={10} min={0} max={1500}
                                    value={inputsModel?.specificYield ?? 0} onChange={val => changeInputsModel({ specificYield: val })}
                                    marksStartPoint={0}
                                    marksEndPoint={1500}
                                    marksStep={50}
                                />
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="energySalesPrice">{t('assumptionsTab.energySalesPrice.label', 'Energy Sales Price (p / kWh)')}</Label>
                            <div className="inputs-value">
                                {formatNumber(energySalesPrice ?? 0, { isDecimals: true, isPence: true, })}
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="epcPrice">{t('assumptionsTab.epcPrice.label', 'EPC Price - £/Wp')}</Label>
                            <NumberInput
                                value={inputsModel?.epcPrice ?? 0}
                                onFormat={value => t('common.currency.noDecimals', `£{{value, #,0}}`, { value: value })}
                                onChange={e => changeInputsModel({ epcPrice: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                            />
                            <div className="slider-container">
                                <StyledSlider step={1} min={0} max={200}
                                    value={inputsModel?.epcPrice ?? 0} onChange={val => changeInputsModel({ epcPrice: val })}
                                    marksStartPoint={0}
                                    marksEndPoint={200}
                                    marksStep={10}
                                />
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="ppaTerm">{t('assumptionsTab.ppaTerm.label', 'PPA Term (years)')}</Label>
                            <div className="inputs-value">
                                {inputsModel?.ppaTerm ?? 0}
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="originatorsFee">{t('assumptionsTab.originatorsFee.label', 'Originator\'s Fee')}</Label>
                            <div className="inputs-value">
                                {formatNumber(calculationsModel?.originatorsFee, { isDecimals: false, isCurrency: true, })}
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <Row>
                                <Col xs={12}>
                                    <Label htmlFor="startDate">{t('assumptionsTab.startDate', 'Expected start date')}</Label>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs="auto">
                                    <ISODateTimeInput name="startDate" type="date" value={moment(inputsModel?.startDate ?? '').toISOString()}
                                        onChange={e => changeInputsModel({ startDate: moment(e.currentTarget.value) })} />
                                </Col>
                            </Row>
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="estimatedOutput">{t('assumptionsTab.estimatedOutput.label', 'Estimated Output (kWh / annum)')}</Label>
                            <div className="inputs-value">
                                {formatNumber(calculationsModel?.estimatedOutput, { isDecimals: false, })}
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="degradation">{t('assumptionsTab.degradation.label', 'Degradation - per annum')}</Label>
                            <NumberInput
                                value={inputsModel?.degradation ?? 0}
                                onFormat={value => t('common.percentage', `{{value, #,0.00%}}`, { value: value / 100 })}
                                onChange={e => changeInputsModel({ degradation: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                            />
                            <div className="slider-container">
                                <StyledSlider step={0.05} min={0} max={1}
                                    value={inputsModel?.degradation ?? 0} onChange={val => changeInputsModel({ degradation: val })}
                                    marksStartPoint={0}
                                    marksEndPoint={1}
                                    marksStep={0.10}
                                />
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="installedCostsEPC">{t('assumptionsTab.installedCostsEPC.label', 'Installed Costs - EPC (ex VAT)')}</Label>
                            <div className="inputs-value">
                                {formatNumber(calculationsModel?.installedCostsEPC, { isDecimals: false, isCurrency: true })}
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="totalCapex">{t('assumptionsTab.totalCapex.label', 'Total Installed Cost  (ex VAT)')}</Label>
                            <div className="inputs-value">
                                {formatNumber(calculationsModel?.totalCapex, { isDecimals: false, isCurrency: true })}
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="installedCostsIncVat">{t('assumptionsTab.installedCostsIncVat.label', 'Total Installed Cost (inc VAT)')}</Label>
                            <div className="inputs-value">
                                {formatNumber(calculationsModel?.installedCostsIncVat, { isDecimals: false, isCurrency: true })}
                            </div>
                        </FormGroup>

                    </div>
                </Col>
            </Row>
        </>
    );
};