import './styledSlider.scss';

import Slider, { SliderProps } from 'rc-slider';
import { useMemo } from 'react';

export interface StyledSliderProps extends SliderProps {
    marksStartPoint?: number,
    marksEndPoint?: number,
    marksStep?: number,
}

/**
 * Slider, styled to look like the owning site
 */
export const StyledSlider = (props: StyledSliderProps) => {
    const { className, marksStartPoint, marksEndPoint = 0, marksStep, ...rest } = props;

    // get slider marks
    const sliderMarks = useMemo(() => {
        let marks: Record<number, React.ReactNode | {
            style?: React.CSSProperties;
            label?: string;
        }> = {};

        for (let i = marksStartPoint ?? 1; i <= (marksEndPoint ?? 0); i += (marksStep ?? 1)) {
            const useIndex = Math.round(i * 100) / 100; // use round because we got some weird results with figures < 0
            marks[useIndex] = { label: useIndex.toString(), }
        }

        return marks;
    }, [marksStartPoint, marksEndPoint, marksStep]);

    return (
        <Slider className={`styled-slider ${className ? className : ''}`} marks={!!marksEndPoint ? sliderMarks : undefined} {...rest} />
        );
};