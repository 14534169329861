import { useMutation } from "@apollo/react-hooks";
import { useAsyncCallback } from 'react-use-async-callback';
import gql from "graphql-tag";
import { AsyncActionStatus } from '../../../shared/abstractStore';
import { BaseValueUpdateInput } from '../generated/globalTypes';
import { updateBaseValueMutation, updateBaseValueMutationVariables } from '../generated/updateBaseValueMutation';
import { baseValueFieldsFragment } from '../models/BaseValue';

/**
 * Get a callback to update a BaseValue in the store.
 */
export function useUpdateBaseValueCallback(): [(id: string, model: BaseValueUpdateInput) => Promise<void>, AsyncActionStatus] {
    const [mutationAction, { error }] = useMutation<updateBaseValueMutation, updateBaseValueMutationVariables>(
        gql`
            mutation updateBaseValueMutation ($model: BaseValueUpdateInput!) {
                updateBaseValue(model: $model) {
                    ...baseValueFields
                }
            }

            ${baseValueFieldsFragment}
        `
    );

    // Wrap the mutation in a standard callback.
    const [action, { isExecuting, errors: actionErrors }] = useAsyncCallback(async (id: string, model: BaseValueUpdateInput) => {
        const result = await mutationAction({ variables: { model: { ...model, id: id } } });
        if (result.errors && result.errors.length) {
            throw result.errors;
        }
    }, [mutationAction]);

    return [action, { isExecuting: isExecuting, errors: error ? error.message : actionErrors }];
}
