import { useMemo } from "react";
import i18n from 'i18next';

/**
 * Returns a string formatted version of a number.
 **/
export function useFormattedNumber(value: number, options?: {
    isDecimals?: boolean, isPence?: boolean, isCurrency?: boolean, isPercentage?: boolean,
    isDecimals5?: boolean, isDecimals10?: boolean, 
}) {
    const formattedNumber = useMemo(() => {
        return formatNumber(value, {
            isDecimals: options?.isDecimals, isPence: options?.isPence, isCurrency: options?.isCurrency, isPercentage: options?.isPercentage,
            isDecimals5: options?.isDecimals, isDecimals10: options?.isDecimals,
        });

    }, [value, options?.isDecimals, options?.isPence, options?.isCurrency, options?.isPercentage,]);

    return formattedNumber;
}


export function formatNumber(value: number, options?: {
    isDecimals?: boolean, isPence?: boolean, isCurrency?: boolean, isPercentage?: boolean,
    isDecimals1?: boolean, isDecimals5?: boolean, isDecimals10?: boolean, 
}) {

    // Get the value formatted as the user wants it formatted.
    let ret = '';

    if (!!options?.isCurrency) {
        // currency with £ symbol
        ret = !!options.isDecimals ? i18n.t('common.currency', `£{{ value, #, 0.00}}`, { value }) : ret =i18n.t('common.currency.nodecimals', `£{{ value, #, 0}}`, { value });
    } else if (options?.isPence) {
        ret = options.isDecimals ?i18n.t('common.pence', `{{ value, #, 0.00}}p`, { value }) : ret =i18n.t('common.pence.nodecimals', `{{ value, #, 0}}p`, { value });
    } else if (options?.isPercentage) {
        ret = options.isDecimals ?i18n.t('common.percentage', `{{ value, #, 0.00%}}`, { value }) : ret =i18n.t('common.pence.nodecimals', `{{ value, #, 0%}}`, { value });
    } else if (options?.isDecimals1) {
        ret = i18n.t('common.number.1decimals', `{{ value, #, 0.0}}`, { value });
    } else if (options?.isDecimals5) {
        ret = i18n.t('common.number.5decimals', `{{ value, #, 0.00000}}`, { value });
    } else if (options?.isDecimals10) {
        ret =i18n.t('common.number.5decimals', `{{ value, #, 0.0000000000}}`, { value });
    } else {
        // normal number
        ret = options?.isDecimals ?i18n.t('common.number', `{{ value, #, 0.00}}`, { value }) : ret =i18n.t('common.number.nodecimals', `{{ value, #, 0}}`, { value });
    }

    return ret;

}