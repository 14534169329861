import { useTranslation } from "react-i18next";
import { UsersListBase } from "../UsersListBase";

/**
 * List of registered users.
 */
export const RegisteredUsersList = () => {
    const { t } = useTranslation();

    return (
        <UsersListBase
            title={t('registeredUsersList.title', 'Registered users')}
            filterByRoleGroups={['User']}
            baseRoute={'/administration/registered-users'}
            addText={t('registeredUsersList.invite', 'Invite user')}
        />
        );
};