import moment from "moment";
import { useCallback, useMemo, } from "react";
import { useTranslation } from "react-i18next";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { NumberInput } from "../../../shared/numberInput/NumberInput";
import { formatNumber } from "../../utilities/useFormattedNumber";
import './modelTab.scss';

export interface ModelTabProps {
    inputsModel: any | undefined,
    changeInputsModel: (changes: any) => void,
    calculationsModel: any | undefined,
    targetReturnRatio: { ratio: number, energySalesPrice: number },
}

/**
 * Tab for maintaining the financial model.
 * @param props
 */
export const ModelTab = (props: ModelTabProps) => {
    const {
        inputsModel, // all inputs
        changeInputsModel, // use this for the input fields on this tab
        calculationsModel, // holds the bottom level calculations created by the calculator from the inputs
        targetReturnRatio, // from snapshot basevalues
    } = props;

    const { t } = useTranslation();

    const energySalesPrice = useMemo(() => { return !!targetReturnRatio ? targetReturnRatio.energySalesPrice : 0; }, [targetReturnRatio]);

    // a formatted node for a display item - this is just to keep the UI a bit easier to read
    const DisplayFormGroup = useCallback((props: {
        numericFieldValue?: number, displayValue: string, fieldName: string, label: string, subLabel?: string,
        isNumericForFormatting?: boolean, isDecimals?: boolean, isPence?: boolean, isCurrency?: boolean, isPercentage?: boolean,
    }) => {
        const {
            numericFieldValue, displayValue, fieldName, label, subLabel = '', isNumericForFormatting = false, isDecimals = true, isPence = false, isCurrency = false, isPercentage = false,
        } = props;

        return (
            <FormGroup>
                <Row>
                    <Col xs={7} sm={7}>
                        <Label htmlFor={fieldName}>{label}</Label>
                    </Col>
                    <Col xs={5} sm={2} className="sub-label-container">
                        <Label className="sub-Label">{subLabel ?? ''}</Label>
                    </Col>
                    <Col xs={12} sm={3}>
                        <div className="inputs-value">
                            {
                                isNumericForFormatting ?
                                    formatNumber(numericFieldValue ?? 0, { isDecimals: isDecimals, isPence: isPence, isCurrency: isCurrency, isPercentage: isPercentage })
                                    :
                                    displayValue
                            }
                        </div>
                    </Col>
                </Row>
            </FormGroup>
        )
    }, []);

    // a formatted node for an input item - this is just to keep the UI a bit easier to read
    const InputFormGroup = useCallback((props: { fieldName: string, label: string, subLabel?: string, children: React.ReactNode }) => {
        const {
            fieldName, label, subLabel = '', children,
        } = props;

        return (
            <FormGroup>
                <Row>
                    <Col xs={8} sm={7}>
                        <Label htmlFor={fieldName}>{label}</Label>
                    </Col>
                    <Col xs={4} sm={2} className="sub-label-container">
                        <Label className="sub-Label">{subLabel}</Label>
                    </Col>
                    <Col xs={12} sm={3}>
                        <div className="inputs-value">
                            {children}
                        </div>
                    </Col>
                </Row>
            </FormGroup>
        )
    }, []);

    return (
        <>
            <Row className="model-container">
                <Col xs={12} lg={6}>
                    <div className="model">

                        {/**** Inputs Section ****/}

                        <div className="modelSubHeading">{t('modelTab.subheading.inputs', 'Inputs')}</div>
                        <DisplayFormGroup displayValue={(!!inputsModel?.startDate ? moment(inputsModel?.startDate ?? '').format('L') : '')} fieldName={'startDate'} label={t('modelTab.startDate.label', 'Start Date')} />
                        <DisplayFormGroup displayValue={(inputsModel?.ppaTerm ?? 0)} fieldName={'ppaTerm'} label={t('modelTab.ppaTerm.label', 'PPA Tenure')} subLabel={t('common.unit.years', 'Years')} />
                        <DisplayFormGroup displayValue={(!!calculationsModel?.endDate ? moment(calculationsModel?.endDate ?? '').format('L') : '')} fieldName={'endDate'} label={t('modelTab.endDate.label', 'Model End Date')} />

                        {/**** Project Details Section ****/}

                        <div className="modelSubHeading">{t('modelTab.subheading.projectDetails', 'Project Details')}</div>
                        <DisplayFormGroup displayValue={(inputsModel?.sizeOfArray ?? 0)} fieldName={'sizeOfArray'} label={t('modelTab.sizeOfArray.label', 'Total kWp')} subLabel={t('common.unit.kWdc', 'kWdc')}
                            isNumericForFormatting={true} numericFieldValue={inputsModel?.sizeOfArray} isDecimals={false} />
                        <DisplayFormGroup displayValue={(calculationsModel?.estimatedOutput ?? 0)} fieldName={'estimatedOutput'} label={t('modelTab.estimatedOutput.label', 'Specific Production')} subLabel={t('common.unit.kWhKWp', 'kWh/KWp')}
                            isNumericForFormatting={true} numericFieldValue={calculationsModel?.estimatedOutput} isDecimals={false}/>
                        <DisplayFormGroup displayValue={(inputsModel?.degradation ?? 0)} fieldName={'degradation'} label={t('modelTab.degradation.label', 'Degradation')} subLabel={t('common.unit.percentage', '%')}
                            isNumericForFormatting={true} numericFieldValue={inputsModel?.degradation / 100} isPercentage={true} /> 
                        <DisplayFormGroup displayValue={(calculationsModel?.export ?? 0)} fieldName={'export'} label={t('modelTab.export.label', 'Export')} subLabel={t('common.unit.kWhannum', 'kWh / annum')}
                            isNumericForFormatting={true} numericFieldValue={calculationsModel?.export} isDecimals={false} /> 
                        <DisplayFormGroup displayValue={(calculationsModel?.takeOrPay ?? 0)} fieldName={'takeOrPay'} label={t('modelTab.takeOrPay.label', 'Take or Pay')} subLabel={t('common.unit.kWhannum', 'kWh / annum')}
                            isNumericForFormatting={true} numericFieldValue={calculationsModel?.takeOrPay} isDecimals={false} /> 
                        <DisplayFormGroup displayValue={(inputsModel?.energyUsedOnSite ?? 0)} fieldName={'energyUsedOnSite'} label={t('modelTab.energyUsedOnSite.label', 'Utilisation')} subLabel={t('common.unit.percentage', '%')}
                            isNumericForFormatting={true} numericFieldValue={inputsModel?.energyUsedOnSite / 100} isPercentage={true} isDecimals={false} /> 

                        <InputFormGroup fieldName={'exportTariff'} label={t('modelTab.exportTariff.label', 'Export Tariff')} subLabel={t('common.unit.pencekWh', 'pence/kWh')}>
                            <NumberInput
                                value={inputsModel?.exportTariff ?? 0}
                                onFormat={value => t('common.pence', `{{value, #,0.00}}p`, { value })}
                                onChange={e => changeInputsModel({ exportTariff: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                            />
                        </InputFormGroup>

                        <DisplayFormGroup displayValue={(energySalesPrice ?? 0).toString()} fieldName={'energySalesPrice'} label={t('modelTab.energySalesPrice.label', 'TPPA Tariff')} subLabel={t('common.unit.pencekWh', 'pence/kWh')}
                            isNumericForFormatting={true} numericFieldValue={energySalesPrice} isPence={true} /> 

                        <InputFormGroup fieldName={'rpi'} label={t('modelTab.rpi.label', 'RPI')} subLabel={t('common.unit.percentage', '%')}>
                            <NumberInput
                                value={inputsModel?.rpi ?? 0}
                                onFormat={value => t('common.percentage', `{{value, #,0.00%}}`, { value: value / 100 })}
                                onChange={e => changeInputsModel({ rpi: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                            />
                        </InputFormGroup>

                        <DisplayFormGroup displayValue={(inputsModel?.annualInflation ?? 0)} fieldName={'annualInflation'} label={t('modelTab.annualInflation.label', 'Tariff Indexation Rate')} subLabel={t('common.unit.percentage', '%')}
                            isNumericForFormatting={true} numericFieldValue={inputsModel?.annualInflation / 100} isPercentage={true} /> 

                        &nbsp;
                        <div className="modelSubHeading">{t('modelTab.subheading.generationDetails', 'Generation Details')}</div>
                        &nbsp;
                        &nbsp;

                        {/**** Capex Section ****/}

                        <div className="modelSubHeading">{t('modelTab.subheading.capex', 'Capex')}</div>
                        <DisplayFormGroup displayValue={(inputsModel?.epcPrice ?? 0)} fieldName={'epcPrice'} label={t('modelTab.epcPrice.label', 'Turnkey EPC price')} subLabel={t('common.unit.penceWdc', 'pence / Wdc')}
                            isNumericForFormatting={true} numericFieldValue={inputsModel?.epcPrice} isPence={true} isDecimals={false} /> 
                        <DisplayFormGroup displayValue={(calculationsModel?.epcCost ?? 0)} fieldName={'epcCost'} label={t('modelTab.epcCost.label', 'EPC Cost')} subLabel={t('common.unit.gbp', 'GBP')}
                            isNumericForFormatting={true} numericFieldValue={calculationsModel?.epcCost} isCurrency={true} isDecimals={false} /> 
                        <DisplayFormGroup displayValue={(calculationsModel?.originatorsFee ?? 0)} fieldName={'originatorsFee'} label={t('modelTab.originatorsFee.label', 'Origination Fee')} subLabel={t('common.unit.gbp', 'GBP')}
                            isNumericForFormatting={true} numericFieldValue={calculationsModel?.originatorsFee} isCurrency={true} isDecimals={false} /> 
                        <DisplayFormGroup displayValue={(calculationsModel?.eVChargingCost ?? 0)} fieldName={'eVChargingCost'} label={t('modelTab.eVChargingCost.label', 'EV Charging Cost')} subLabel={t('common.unit.gbp', 'GBP')}
                            isNumericForFormatting={true} numericFieldValue={calculationsModel?.eVChargingCost} isCurrency={true} isDecimals={false} /> 
                        <DisplayFormGroup displayValue={(calculationsModel?.totalCapex ?? 0)} fieldName={'totalCapex'} label={t('modelTab.totalCapex.label', 'Total Capex')} subLabel={t('common.unit.gbp', 'GBP')}
                            isNumericForFormatting={true} numericFieldValue={calculationsModel?.totalCapex} isCurrency={true} isDecimals={false} /> 
                        &nbsp;
                        &nbsp;
                        <DisplayFormGroup displayValue={(calculationsModel?.totalCapex2 ?? 0)} fieldName={'totalCapex2'} label={t('modelTab.totalCapex.label', 'Total Capex')} subLabel={t('common.unit.gbp', 'GBP')}
                            isNumericForFormatting={true} numericFieldValue={calculationsModel?.totalCapex2} isCurrency={true} isDecimals={false} /> 
                    </div>
                </Col>
                <Col xs={12} lg={6}>
                    <div className="model">

                        {/**** Expenses Section ****/}

                        <div className="modelSubHeading">{t('modelTab.subheading.expenses', 'Expenses')}</div>

                        <InputFormGroup fieldName={'oAndM'} label={t('modelTab.oAndM.label', 'O&M')} subLabel={t('common.unit.gbpkW', 'GBP/kW')}>
                            <NumberInput
                                value={inputsModel?.oAndM ?? 0}
                                onFormat={value => t('common.currency.noDecimals', `£{{value, #,0}}`, { value })}
                                onChange={e => changeInputsModel({ oAndM: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                            />
                        </InputFormGroup>

                        <InputFormGroup fieldName={'oAndMManager'} label={t('modelTab.oAndMManager.label', 'O&M Manager')} subLabel={t('common.unit.gbpannum', 'GBP/annum')}>
                            <NumberInput
                                value={inputsModel?.oAndMManager ?? 0}
                                onFormat={value => t('common.currency.noDecimals', `£{{value, #,0}}`, { value })}
                                onChange={e => changeInputsModel({ oAndMManager: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                            />
                        </InputFormGroup>

                        <DisplayFormGroup displayValue={(calculationsModel?.businessRates ?? 0)} fieldName={'businessRates'} label={t('modelTab.businessRates.label', 'Business Rates')} subLabel={t('common.unit.gbpannum', 'GBP/annum')}
                            isNumericForFormatting={true} numericFieldValue={calculationsModel?.businessRates} isCurrency={true} isDecimals={false} /> 

                        <InputFormGroup fieldName={'transmissionFee'} label={t('modelTab.transmissionFee.label', 'Transmission Fee')} subLabel={t('common.unit.gbpannum', 'GBP/annum')}>
                            <NumberInput
                                value={inputsModel?.transmissionFee ?? 0}
                                onFormat={value => t('common.currency.noDecimals', `£{{value, #,0}}`, { value })}
                                onChange={e => changeInputsModel({ transmissionFee: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                            />
                        </InputFormGroup>

                        <InputFormGroup fieldName={'landLease'} label={t('modelTab.landLease.label', 'Land Lease')} subLabel={t('common.unit.gbpannum', 'GBP/annum')}>
                            <NumberInput
                                value={inputsModel?.landLease ?? 0}
                                onFormat={value => t('common.currency.noDecimals', `£{{value, #,0}}`, { value })}
                                onChange={e => changeInputsModel({ landLease: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                            />
                        </InputFormGroup>

                        <InputFormGroup fieldName={'insurance'} label={t('modelTab.insurance.label', 'Insurance')} subLabel={t('common.unit.gbpkW', 'GBP/kW')}>
                            <NumberInput
                                value={inputsModel?.insurance ?? 0}
                                onFormat={value => t('common.currency', `£{{value, #,0.00}}`, { value })}
                                onChange={e => changeInputsModel({ insurance: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                            />
                        </InputFormGroup>

                        <InputFormGroup fieldName={'insuranceandLCInflation'} label={t('modelTab.insuranceandLCInflation.label', 'Insurance & LC Inflation')} subLabel={t('common.unit.percentage', '%')}>
                            <NumberInput
                                value={inputsModel?.insuranceandLCInflation ?? 0}
                                onFormat={value => t('common.percentage', `{{value, #,0.00%}}`, { value: value / 100 })}
                                onChange={e => changeInputsModel({ insuranceandLCInflation: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                            />
                        </InputFormGroup>

                        <InputFormGroup fieldName={'audit'} label={t('modelTab.audit.label', 'Audit')} subLabel={t('common.unit.gbpannum', 'GBP/annum')}>
                            <NumberInput
                                value={inputsModel?.audit ?? 0}
                                onFormat={value => t('common.currency.noDecimals', `£{{value, #,0}}`, { value })}
                                onChange={e => changeInputsModel({ audit: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                            />
                        </InputFormGroup>

                        <DisplayFormGroup displayValue={(calculationsModel?.managementFee ?? 0)} fieldName={'managementFee'} label={t('modelTab.managementFee.label', 'Management fee (3p per watt DC on the capex)')} subLabel={t('common.unit.gbpannum', 'GBP/annum')}
                            isNumericForFormatting={true} numericFieldValue={calculationsModel?.managementFee} isCurrency={true} isDecimals={false} /> 

                        <InputFormGroup fieldName={'managementFeeInflation'} label={t('modelTab.managementFeeInflation.label', 'Management fee Inflation')} subLabel={t('common.unit.percentage', '%')}>
                            <NumberInput
                                value={inputsModel?.managementFeeInflation ?? 0}
                                onFormat={value => t('common.currency.noDecimals', `{{value, #,0.00%}}`, { value: value / 100 })}
                                onChange={e => changeInputsModel({ managementFeeInflation: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                            />
                        </InputFormGroup>

                        <InputFormGroup fieldName={'refurbishmentFee'} label={t('modelTab.refurbishmentFee.label', 'Refurbishment Fee')} subLabel={t('common.unit.gbpkW', 'GBP/kW')}>
                            <NumberInput
                                value={inputsModel?.refurbishmentFee ?? 0}
                                onFormat={value => t('common.currency', `£{{value, #,0.00}}`, { value })}
                                onChange={e => changeInputsModel({ refurbishmentFee: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                            />
                        </InputFormGroup>

                        {/**** Working Capital Section ****/}

                        <div className="modelSubHeading">{t('modelTab.subheading.workingCapital', 'Working Capital')}</div>
                        <InputFormGroup fieldName={'receivablesDays'} label={t('modelTab.receivablesDays.label', 'Receivables days')} subLabel={t('common.unit.days', 'Days')}>
                            <NumberInput
                                value={inputsModel?.receivablesDays ?? 0}
                                onFormat={value => t('common.number.noDecimals', `{{value, #,0}}`, { value })}
                                onChange={e => changeInputsModel({ receivablesDays: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                            />
                        </InputFormGroup>

                        <InputFormGroup fieldName={'payablesDays'} label={t('modelTab.payablesDays.label', 'Payables days')} subLabel={t('common.unit.days', 'Days')}>
                            <NumberInput
                                value={inputsModel?.payablesDays ?? 0}
                                onFormat={value => t('common.number.noDecimals', `{{value, #,0}}`, { value })}
                                onChange={e => changeInputsModel({ payablesDays: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                            />
                        </InputFormGroup>

                        {/**** Depreciation Section ****/}

                        <div className="modelSubHeading">{t('modelTab.subheading.depreciation', 'Depreciation')}</div>
                        <DisplayFormGroup displayValue={(calculationsModel?.depreciationRate ?? 0)} fieldName={'depreciationRate'} label={t('modelTab.depreciationRate.label', 'Rate')} subLabel={t('common.unit.percentage', '%')}
                            isNumericForFormatting={true} numericFieldValue={calculationsModel?.depreciationRate / 100 } isPercentage={true} isDecimals={false} /> 

                        <InputFormGroup fieldName={'depreciationYears'} label={t('modelTab.depreciationYears.label', 'Number of Years')} subLabel={t('common.unit.years', 'Years')}>
                            <NumberInput
                                value={inputsModel?.depreciationYears ?? 0}
                                onFormat={value => t('common.number.noDecimals', `{{value, #,0}}`, { value })}
                                onChange={e => changeInputsModel({ depreciationYears: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                            />
                        </InputFormGroup>

                        {/**** Debt Section ****/}

                        <div className="modelSubHeading">{t('modelTab.subheading.debt', 'Debt')}</div>

                        <InputFormGroup fieldName={'debtInterestRate'} label={t('modelTab.debtInterestRate.label', 'Interest Rate')} subLabel={t('common.unit.percentage', '%')}>
                            <NumberInput
                                value={inputsModel?.debtInterestRate ?? 0}
                                onFormat={value => t('common.percentage', `{{value, #,0.00%}}`, { value: value / 100 })}
                                onChange={e => changeInputsModel({ debtInterestRate: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                            />
                        </InputFormGroup>

                        <InputFormGroup fieldName={'debtTerm'} label={t('modelTab.debtTerm.label', 'Debt Term')} subLabel={t('common.unit.years', 'Years')}>
                            <NumberInput
                                value={inputsModel?.debtTerm ?? 0}
                                onFormat={value => t('common.number.noDecimals', `{{value, #,0}}`, { value })}
                                onChange={e => changeInputsModel({ debtTerm: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                            />
                        </InputFormGroup>


                        {/**** Tax Section ****/}

                        <div className="modelSubHeading">{t('modelTab.subheading.tax', 'Tax')}</div>
                        <InputFormGroup fieldName={'taxFuture'} label={t('modelTab.taxFuture.label', '3 onwards')} subLabel={t('common.unit.percentage', '%')}>
                            <NumberInput
                                value={inputsModel?.taxFuture ?? 0}
                                onFormat={value => t('common.percentage', `{{value, #,0.00%}}`, { value: value / 100 })}
                                onChange={e => changeInputsModel({ taxFuture: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                            />
                        </InputFormGroup>
                        <InputFormGroup fieldName={'taxNow'} label={t('modelTab.taxNow.label', 'Rate Now')} subLabel={t('common.unit.percentage', '%')}>
                            <NumberInput
                                value={inputsModel?.taxNow ?? 0}
                                onFormat={value => t('common.percentage', `{{value, #,0.00%}}`, { value: value / 100 })}
                                onChange={e => changeInputsModel({ taxNow: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                            />
                        </InputFormGroup>
                   </div>
                </Col>
            </Row>
        </>
    );
};