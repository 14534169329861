import { RouteEntry } from "../../shared/routes";
import { FinancialModelsList } from './FinancialModelsList';
import { EditFinancialModel, CreateFinancialModel } from "./EditFinancialModel";
import { IdentityRoles } from "../../configure/security/IdentityRoles";
import { DeleteFinancialModel } from "./DeleteFinancialModel";

export const financialModelRoutes: Array<RouteEntry> = [
    { path: '/administration/financial-models', exact: true, component: FinancialModelsList, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/review/:id', component: EditFinancialModel, authorize: false, },
    { path: '/calculator', exact: true, component: CreateFinancialModel, authorize: false },
    { path: '/calculator/for/:userId', exact: true, component: CreateFinancialModel, authorize: true, requireRole: IdentityRoles.Administration },
    { path: '/administration/financial-models/delete/:id', component: DeleteFinancialModel, authorize: true, requireRole: IdentityRoles.Administration },
];
