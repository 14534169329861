import { useCallback, useMemo, } from "react";
import { useTranslation } from "react-i18next";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { NumberInput } from "../../../shared/numberInput/NumberInput";
import { StyledSlider } from "../../../shared/StyledSlider";
import { YearCalculation } from "../../utilities/useCalculateForYear";
import { formatNumber } from "../../utilities/useFormattedNumber";
import { ProposalsTab } from "../proposals/ProposalTab";
import './clientFacingTab.scss';

export interface ClientFacingTabProps {
    change: (changes: any) => void,
    isExcludeInflation: boolean,
    inputsDefaults: any,
    inputsModel: any | undefined,
    changeInputsModel: (changes: any) => void,
    //calculationsModel: any | undefined,
    targetReturnRatio: { ratio: number, energySalesPrice: number },
    co2Savings: number,
    allYearCalculations: YearCalculation[],
    fullProposalYearsRequired: number,
    //irrCalculations: { preTaxIRR: number | '#NUM!', postTaxIRR: number | '#NUM!' }
}

/**
 * Tab for maintaining Client Facing inputs and calculations.
 * @param props
 */
export const ClientFacingTab = (props: ClientFacingTabProps) => {
    const {
        change, // change the main financial model
        isExcludeInflation,
        inputsDefaults,
        inputsModel, // all inputs
        changeInputsModel, // use this for the input fields on this tab
        //calculationsModel, // holds the bottom level calculations created by the calculator from the inputs
        targetReturnRatio, // from snapshot basevalues
        co2Savings, // from snapshot basevalues
        allYearCalculations, // holds all the calculations for all years of the proposal including year zero
        fullProposalYearsRequired, // if the number of years needs changing do it once in the editFinancialModel component
        //irrCalculations, // pre and post tax irr calculations
    } = props;

    const { t } = useTranslation();


    // Default years to show in reduced proposal section of client facing page
    const defaultProposalYears = useMemo(() => { return [1, 5, 10, 25, 40]; }, []);

    // Calculation of size of array from sq ft
    const arraySizeToSqFtRatio = 0.0195;

    // lookups and calculationsa needed for the display
    const energySalesPrice = useMemo(() => { return !!targetReturnRatio ? targetReturnRatio.energySalesPrice : 0; }, [targetReturnRatio]);
    //const [year1Ebitda, setYear1Ebitda] = useState<number>(0);
    //const interestPayable = useMemo(() => { return !inputsModel || !calculationsModel ? 0 : (calculationsModel?.totalCapex ?? 0) * ((inputsModel?.debtInterestRate ?? 0) / 100); }, [calculationsModel, inputsModel]);
    //const debtServiceRatio = useMemo(() => { return !year1Ebitda || !interestPayable ? 0 : year1Ebitda / interestPayable;  }, [year1Ebitda, interestPayable]);
    //const availableCapital = useMemo(() => {
    //return !year1Ebitda || !interestPayable ? 0 : (year1Ebitda - interestPayable) * (inputsModel?.energyUsedOnSite / 100);
    //}, [year1Ebitda, interestPayable, inputsModel]);


    // get the total figures for display across all years of the proposal
    const totalSavings: { totalPounds: number, totalCo2: number, totalCostWithout: number, totalCostsWith: number, totalEbitda: number, } = useMemo(() => {
        let totalPounds = 0;
        let totalCo2 = 0;
        let totalCostWithout = 0;
        let totalCostsWith = 0;
        let totalEbitda = 0;

        if (!allYearCalculations.length) {
            return { totalPounds: totalPounds, totalCo2: totalCo2, totalCostWithout: totalCostWithout, totalCostsWith: totalCostsWith, totalEbitda: totalEbitda };
        }

        for (let y = 1; y <= fullProposalYearsRequired; y++) {

            // this year's figures that are based on base calculations and inputs
            const yearCalculation = allYearCalculations[y];

            // we need the year 1 ebitda for the display
            //if (y === 1) {
            //    setYear1Ebitda(yearCalculation.ebitda);
            //}

            // accumulate the year totals figures
            totalPounds += yearCalculation.annualSavings > 0 ? yearCalculation.annualSavings : 0;
            totalCo2 += yearCalculation.systemYield * co2Savings;
            totalCostWithout += yearCalculation.energyCostWithoutSolar;
            totalCostsWith += yearCalculation.energyCostWithSolar;
            totalEbitda += yearCalculation.ebitda;
        }

        return { totalPounds: totalPounds, totalCo2: totalCo2, totalCostWithout: totalCostWithout, totalCostsWith: totalCostsWith, totalEbitda: totalEbitda };

    }, [co2Savings, fullProposalYearsRequired, allYearCalculations]);

    const onChangeIsExcludeInflation = useCallback((isChecked: boolean) => {

        let excludeInflation = !isChecked;

        if (excludeInflation) {
            // Set all inflation related input variables to 0
            changeInputsModel({ rpi: 0, annualInflation: 0, energyInflation: 0 });
        } else {
            // Set all inflation related input variables to defaults
            changeInputsModel({ rpi: inputsDefaults.rpi, annualInflation: inputsDefaults.annualInflation, energyInflation: inputsDefaults.energyInflation });
        }


        change({ isExcludeInflation: excludeInflation })

    }, [inputsDefaults, change, changeInputsModel]);

    // handle changes to the selection of years to be shown in the mini proposal on this page - up to 5
    //const manageProposalYears = useCallback((isChecked: boolean, yearSelected: number) => {

    //    let newProposalYears: Array<number> = inputsModel?.proposalYears ?? defaultProposalYears;

    //    // if item is unchecked then remove from proposal years
    //    if (!isChecked) {
    //        let yearIndex = newProposalYears.indexOf(yearSelected);
    //        if (yearIndex > -1) {
    //            newProposalYears.splice(yearIndex, 1);
    //        }
    //    } else {
    //        // if item is checked then add to proposal years
    //        // unless we already have 5 - then ignore
    //        if (newProposalYears.length < 5) {
    //            newProposalYears.push(yearSelected);
    //        }
    //    }

    //    // change the inputs model to save the new settings
    //    changeInputsModel({ proposalYears: newProposalYears.sort((a, b) => a < b ? -1 : a > b ? 1 : 0) });

    //}, [inputsModel, changeInputsModel, defaultProposalYears,]);

    return (
        <div className="client-facing-container">
            <Row>
                <Col sm={9}>
                    <div className="client-facing inputs-container">
                        <FormGroup>
                            <Label htmlFor="currentEnergyCost">{t('clientFacingTab.currentEnergyCost.label', 'Current Cost of Energy (p/KWh)')}</Label>
                            <NumberInput
                                value={inputsModel?.currentEnergyCost ?? 0}
                                onFormat={value => t('common.number', `{{value, #,0.00}}`, { value })}
                                onChange={e => changeInputsModel({ currentEnergyCost: !!e.currentTarget.value ? e.currentTarget.valueAsNumber : 0 })}
                            />
                            <div className="slider-container">
                                <StyledSlider step={0.01} min={0} max={50}
                                    value={inputsModel?.currentEnergyCost ?? 0} onChange={val => changeInputsModel({ currentEnergyCost: val })}
                                    marksStartPoint={0}
                                    marksEndPoint={50}
                                    marksStep={5}
                                />
                            </div>
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="ppaTerm">{t('clientFacingTab.ppaTerm.label', 'Term (Years)')}</Label>
                            <Input type="number" value={inputsModel?.ppaTerm ?? 0} onChange={e => changeInputsModel({ ppaTerm: e.currentTarget.valueAsNumber })} />
                            <div className="slider-container">
                                <div className="d-none d-lg-block">
                                    <StyledSlider step={1} min={3} max={30}
                                        value={inputsModel?.ppaTerm ?? 0} onChange={val => changeInputsModel({ ppaTerm: val })}
                                        marksStartPoint={3}
                                        marksEndPoint={30}
                                        marksStep={1}
                                    />
                                </div>
                                <div className="d-block d-lg-none">
                                    <StyledSlider step={1} min={3} max={30}
                                        value={inputsModel?.ppaTerm ?? 0} onChange={val => changeInputsModel({ ppaTerm: val })}
                                        marksStartPoint={3}
                                        marksEndPoint={30}
                                        marksStep={3}
                                    />
                                </div>
                            </div>
                        </FormGroup>

                        {/* The following form group relates directly to the sizeOfArray. It is never stored anywhere. */}
                        <FormGroup>
                            <Label htmlFor="squareFeet">{t('clientFacingTab.buildingArea.label', 'Building Area sq ft')}</Label>
                            {/* 1 slider for xs and another for sm and above to make scales look right */}
                            <div className="d-sm-none">
                                <div>{t("clientFacingTab.buildingArea.label2", "(thousands)")}</div>
                                <NumberInput
                                    value={!!inputsModel && !!inputsModel.sizeOfArray ? (inputsModel?.sizeOfArray / arraySizeToSqFtRatio) / 1000 : 0}
                                    onFormat={value => t('common.number.noDecimal', `{{value, #,0}}`, { value })}
                                    onChange={e => changeInputsModel({ sizeOfArray: !!e.currentTarget.value ? (e.currentTarget.valueAsNumber * 1000) * arraySizeToSqFtRatio : 0 })}
                                />
                                <div className="slider-container">
                                    <StyledSlider step={1} min={5} max={100}
                                        value={!!inputsModel && !!inputsModel.sizeOfArray ? (inputsModel?.sizeOfArray / arraySizeToSqFtRatio) / 1000 : 0}
                                        onChange={val => changeInputsModel({ sizeOfArray: !!val ? (val * 1000) * arraySizeToSqFtRatio : 0 })}
                                        marksStartPoint={5}
                                        marksEndPoint={100}
                                        marksStep={10}
                                    />
                                </div>
                            </div>
                            <div className="d-none d-sm-block">
                                <NumberInput
                                    value={!!inputsModel && !!inputsModel.sizeOfArray ? inputsModel?.sizeOfArray / arraySizeToSqFtRatio : 0}
                                    onFormat={value => t('common.number.noDecimal', `{{value, #,0}}`, { value })}
                                    onChange={e => changeInputsModel({ sizeOfArray: !!e.currentTarget.value ? e.currentTarget.valueAsNumber * arraySizeToSqFtRatio : 0 })}
                                />
                                <div className="slider-container">
                                    <StyledSlider className="d-none d-sm-block" step={100} min={5000} max={100000}
                                        value={!!inputsModel && !!inputsModel.sizeOfArray ? inputsModel?.sizeOfArray / arraySizeToSqFtRatio : 0}
                                        onChange={val => changeInputsModel({ sizeOfArray: !!val ? val * arraySizeToSqFtRatio : 0 })}
                                        marksStartPoint={5000}
                                        marksEndPoint={100000}
                                        marksStep={5000}
                                    />
                                </div>
                            </div>
                        </FormGroup>


                    </div>
                </Col>
                <Col sm={3}>
                    <div className="client-facing calculations-container">
                        <div className="calculations">
                            <FormGroup>
                                <Label htmlFor="energySalesPrice">{t('clientFacingTab.energySalesPrice.label', 'Energy Sales Price (p / kWh)')}</Label>
                                <div className="inputs-value">
                                    {formatNumber(Math.round(energySalesPrice * 100) / 100 ?? 0, { isDecimals: true, isPence: true })}
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="totalCarbonSavings">{t('clientFacingTab.totalCarbonSavings.label', 'Total Carbon Savings (tonnes)')}</Label>
                                <div className="inputs-value">
                                    {/* Convert from kg to tonnes as a last minute client request */}
                                    {formatNumber(totalSavings.totalCo2 / 1000)}
                                </div>
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="totalCostWithout">{t('clientFacingTab.totalCostWithout.label', 'Total costs without solar')}</Label>
                                <div className="inputs-value">
                                    {formatNumber(totalSavings.totalCostWithout, { isCurrency: true })}
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="totalCostsWith">{t('clientFacingTab.totalCostsWith.label', 'Total costs with solar')}</Label>
                                <div className="inputs-value">
                                    {formatNumber(totalSavings.totalCostsWith, { isCurrency: true })}
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label htmlFor="totalSavings">{t('clientFacingTab.totalSavings.label', 'Total Savings')}</Label>
                                <div className="inputs-value">
                                    {formatNumber(totalSavings.totalPounds, { isCurrency: true })}
                                </div>
                            </FormGroup>
                        </div>
                    </div>
                </Col>
            </Row>

            <Row className="proposal-tab-container">
                <Col>
                    <ProposalsTab
                        inputsModel={inputsModel}
                        co2Savings={co2Savings}
                        yearsRequired={inputsModel?.proposalYears ?? defaultProposalYears}
                        allYearCalculations={allYearCalculations}
                    />

                    <FormGroup className="inflation-input">
                        <Input name="isExcludeInflation" type="checkbox" checked={!isExcludeInflation}
                            onChange={e => onChangeIsExcludeInflation(e.currentTarget.checked)}
                        />
                        <Label htmlFor="isExcludeInflation">{t('clientFacingTab.isExcludeInflation.label', 'Include inflation')}</Label>
                    </FormGroup>

                    {/*    <FormGroup className="proposal-years no-print">*/}
                    {/*        <Label htmlFor="yearsToInclude">{t('clientFacingTab.yearsToInclude.label', 'Include up to 5 years in the proposal')}</Label>*/}
                    {/*        <div className="radio">*/}
                    {/*            {*/}
                    {/*                [...Array(inputsModel?.proposalTerm ?? 40)].map((item, index) => (*/}
                    {/*                    <label key={index}>*/}
                    {/*                        {index + 1}*/}
                    {/*                        <input*/}
                    {/*                            type="radio"*/}
                    {/*                            value={index + 1}*/}
                    {/*                            name={"year" + (index + 1)}*/}
                    {/*                            checked={!!inputsModel?.proposalYears ? inputsModel?.proposalYears.includes(index + 1)*/}
                    {/*                                : defaultProposalYears.includes(index + 1) ? true*/}
                    {/*                                    : false}*/}
                    {/*                            onClick={e => manageProposalYears(!e.currentTarget.checked, Number(e.currentTarget.value))}*/}
                    {/*                            onChange={e => manageProposalYears(e.currentTarget.checked, Number(e.currentTarget.value))}*/}
                    {/*                        />*/}
                    {/*                    </label>*/}
                    {/*                ))*/}
                    {/*            }*/}
                    {/*        </div>*/}
                    {/*    </FormGroup>*/}
                </Col>
            </Row>
            <Row className="client-images pagebreak-before">
                <Col xs={12} sm={6}>
                    <Label>{t("clientFacingTab.priceGraph.label", "Energy Price Protection")}</Label>
                    <Label className="sub-label">{t("clientFacingTab.priceGraph.sublabel1", "Our Power Purchase Agreements are locked into retail price inflation, so our clients are protected from long term energy price increases.")}</Label>
                    <Label>{t("clientFacingTab.priceGraph.sublabel2", "Current price of electricity V Olympus Power PPA")}</Label>
                    <img className="secondary-background part-width-image" src=".\img\Current_Price_Of_Electricity_VS_Olympus_Power_PPA.png" alt="Price comparison graph" />
                </Col>
                <Col xs={12} sm={6}>
                    <Label>{t("clientFacingTab.fundingTable.label", "Olympus fully funded V Self funded")}</Label>
                    <img className="" src=".\img\PPA_v_CF_Table.png" alt="Funding table" />
                </Col>
            </Row>
        </div>
    );
};