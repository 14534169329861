
export interface EmptyTableRowsProps {
    rows?: number,
    columns: number
}

/**
 * A variable number of empty rows in a table
 */
export const EmptyTableRows = (props: EmptyTableRowsProps) => {
    const {
        rows = 1,
        columns,
    } = props;

    return (
        <>
            {
                [...Array(rows)].map((value: undefined, rowindex) => {
                    return (
                        <tr key={rowindex}>
                            {
                                [...Array(columns)].map((value: undefined, colindex) => {
                                    return (
                                        <td key={colindex}>&nbsp;</td>
                                    )
                                })
                            }
                        </tr>
                    )
                })
            }
        </>
    )
};