import { NavItem, NavLink } from 'reactstrap';
import { AuthorizeContainer } from '../../shared/authorizeRoute';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLinkTrackActive } from '../shared/NavLinkTrackActive';
import { IdentityRoles } from '../../configure/security/IdentityRoles';
import { SidebarNavTitle } from '../../shared/sidebarNav';
import { BaseValueType, baseValueId } from '../../api/main/models/codeOnly/BaseValue';

/**
 * Administration navigation menu for the app. 
 */
export const AdministrationNavigation = () => {
    const { t } = useTranslation();

    return (
        <>
            <AuthorizeContainer requireRole={IdentityRoles.Administration}>
                <SidebarNavTitle>
                    {t('administrationNavigation.dashboardTitle', 'Financial models')}
                </SidebarNavTitle>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration/financial-models">
                        <FontAwesomeIcon icon="solar-panel" className="nav-icon" />
                        <> {t('administrationNavigation.financialModels', 'Financial models')}</>
                    </NavLink>
                </NavItem>

                <SidebarNavTitle>
                    {t('administrationNavigation.usersTitle', 'User management')}
                </SidebarNavTitle>


                <NavItem>
                    <NavLink to="/administration/registered-users" tag={NavLinkTrackActive}>
                        <FontAwesomeIcon icon="user" className="nav-icon" />
                        <> {t('administrationNavigation.registeredUsers', 'Registered users')}</>
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink to="/administration/administrators" tag={NavLinkTrackActive}>
                        <FontAwesomeIcon icon="user-shield" className="nav-icon" />
                        <> {t('administrationNavigation.administrators', 'Administrators')}</>
                    </NavLink>
                </NavItem>

                <SidebarNavTitle>
                    {t('administrationNavigation.baseData', 'Base data')}
                </SidebarNavTitle>

                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to={`/administration/base-value/edit/${baseValueId(BaseValueType.CO2UnitConversion)}`}>
                        <FontAwesomeIcon icon="solar-panel" className="nav-icon" />
                        <> {t('administrationNavigation.cO2Savings', 'CO2 savings')}</>
                    </NavLink>
                </NavItem>

                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to={`/administration/base-value/edit/${baseValueId(BaseValueType.TargetReturnRatios)}`}>
                        <FontAwesomeIcon icon="solar-panel" className="nav-icon" />
                        <> {t('administrationNavigation.targetReturnRatios', 'Target return ratios')}</>
                    </NavLink>
                </NavItem>

            </AuthorizeContainer>
        </>
    );
};
