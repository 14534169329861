import gql from "graphql-tag";
import { Guid } from "guid-string";
import moment from "moment";
import { financialModelFields } from "../generated/financialModelFields";

export const financialModelFieldsFragment = gql`
    fragment financialModelFields on FinancialModel {
        id
        name
        versionNumber
        userId
        archived
        inputsJson
        calculationsJson
        baseValuesJson
        createdDate
        updatedDate
        isExcludeInflation
    }
`;


export type FinancialModel = Omit<financialModelFields, '__typename'>;

/**
 * Default values.
 */
export const financialModelDefaultValues = (): Partial<FinancialModel> => {
    const now = moment();

    return {
        id: Guid.newGuid(),
        name: '',
        versionNumber: '1',
        userId: undefined,
        archived: false,
        inputsJson: '{}',
        calculationsJson: '{}',
        baseValuesJson: '{}',
        createdDate: now.toISOString(),
        updatedDate: now.toISOString(),
        isExcludeInflation: false,
    }
};