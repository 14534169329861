import { useCallback, useMemo, } from "react";
import { useTranslation } from "react-i18next";
import { Col, Label, Row, Table } from "reactstrap";
import { YearCalculation } from "../../utilities/useCalculateForYear";
import { formatNumber } from "../../utilities/useFormattedNumber";
import './proposalTab.scss';

export interface ProposalsTabProps {
    inputsModel: any | undefined,
    co2Savings: number,
    yearsRequired?: number[],
    allYearCalculations: YearCalculation[],
}

/**
 * Tab for maintaining the proposal table.
 * @param props
 */
export const ProposalsTab = (props: ProposalsTabProps) => {
    const {
        inputsModel, // all inputs
        co2Savings, // from snapshot basevalues
        yearsRequired,
        allYearCalculations, // holds all the calculations for all years of the proposal including year zero
    } = props;

    const { t } = useTranslation();

    // Number of years for full proposal calculations
    const proposalYearsRequired = inputsModel?.proposalTerm ?? 40;

    // Array of all full years' data, formatted and converted to strings
    const rowsYears = useMemo(() => {

        // data for the table
        let newRowData: Array<RowData> = [];

        // loop round creating a row for each full year including year 0 as the figures on the proposal 
        // are the start figures in year 1 not figures adjusted after a year
        for (let y = 0; y < proposalYearsRequired; y++) {

            // this year's figures that are based on base calculations and inputs
            const yearCalculations = allYearCalculations[y];

            // make up a column for this year's figures for the table
            let newRow: RowData = ({
                year: (y + 1).toString(), // y + 1 otherwise we'd show 0 to 39 not 1 to 40 as the year number
                systemYield: formatNumber(Math.round(yearCalculations.systemYield)),
                onSiteUse: formatNumber(Math.round(yearCalculations.totalGeneration)),
                energyCosts: formatNumber(Math.round(yearCalculations.energyCost * 10) / 10, { isDecimals1: true }), // round to 1 decimal
                energyCostsWithoutSolar: formatNumber(Math.round(yearCalculations.energyCostWithoutSolar)),
                solarEnergyCost: formatNumber(yearCalculations.solarEnergyCost, { isDecimals: true }),
                energyCostsWithSolar: formatNumber(Math.round(yearCalculations.energyCostWithSolar)),
                fieldbold_annualSavings: formatNumber(yearCalculations.annualSavings),
                fieldbold_cO2Savings: formatNumber(yearCalculations.systemYield * co2Savings),
            });

            // add the new row to the table data array 
            newRowData.push(newRow);
        }

        // return an array for the table holding a row for each year required
        return newRowData;
    }, [co2Savings, proposalYearsRequired, allYearCalculations,]);

    // A populated table row of as many columns as needed 
    // May return a blank node if years required has been stated and this year is not in the array
    const PopulatedTableRow = useCallback((props: { yearIndex: number }) => {
        const {
            yearIndex
        } = props;

        // check if this year is required
        if (!!yearsRequired) {
            let yearToInclude = yearsRequired.find(item => item === yearIndex + 1);

            if (!yearToInclude) {
                return (<></>);
            }
        }

        // check for any of the special cases defined by the prefix to the field name
        // we can use special line prefixes on the tr, or the td if they are to be applied selectively and not every cell on the row

        // apply special field prefixes to the td when required 

        // check each column
        let fieldClass: Array<string> = [];
        for (let c = 0; c < 9; c++) {
            let thisFieldClassName = 'yearColumn';
            if (Object.keys(rowsYears[0])[c].startsWith('fieldbold_')) {
                thisFieldClassName += ' fieldbold';
            }
            fieldClass.push(thisFieldClassName);
        }

        return (
            <>
                <tr>
                    {
                        /* one row per year */
                        [...Array(Object.keys(rowsYears[0]).length)].map((value: undefined, index) => {
                            return (
                                <td className={fieldClass[index]} key={index}>
                                    {Object.values(rowsYears[yearIndex])[index]}
                                </td>
                            );
                        })
                    }
                </tr>
            </>
        )
    }, [rowsYears, yearsRequired]);

    /** UI
     **/
    return (
        <>
            <Row className="proposal-container">
                <Col>
                    <div className="proposal">
                        <Label>{t("clientFacingTab.proposal", "Proposal")}</Label>
                        <div className="table-responsive">

                            {/* Table */}
                            <Table className="table-bordered">
                                <thead className="thead-dark">
                                    <tr className="headerRow">
                                        <th>{t('proposalsTab.headerColumn.year', 'Year')}</th>
                                        <th>{t('proposalsTab.headerColumn.systemYield', 'System Yield')}</th>
                                        <th>{t('proposalsTab.headerColumn.onSiteUse', 'On Site Use')}</th>
                                        <th>{t('proposalsTab.headerColumn.energyCosts', 'Energy Costs')}</th>
                                        <th>{t('proposalsTab.headerColumn.energyCostsWithoutSolar', 'Energy Costs without Solar')}</th>
                                        <th>{t('proposalsTab.headerColumn.solarEnergyCost ', 'Solar Energy Cost ')}</th>
                                        <th>{t('proposalsTab.headerColumn.energyCostsWithSolar', 'Energy Costs with Solar')}</th>
                                        <th>{t('proposalsTab.headerColumn.annualSavings', 'Annual Savings')}</th>
                                        <th>{t('proposalsTab.headerColumn.cO2Savings', 'CO2 Savings')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="subHeaderRow">
                                        <th>{t('common.', '')}</th>
                                        <th>{t('common.kwh', 'kWh')}</th>
                                        <th>{t('common.kwh', 'kWh')}</th>
                                        <th>{t('common.pKwh', 'p/kWh')}</th>
                                        <th>{t('common.currency.pound', '£')}</th>
                                        <th>{t('common.pKwh', 'p/kWh')}</th>
                                        <th>{t('common.currency.pound', '£')}</th>
                                        <th>{t('common.currency.pound', '£')}</th>
                                        <th>{t('common.kg', 'Kg')}</th>
                                    </tr>
                                    {
                                        [...Array(proposalYearsRequired)].map((value: undefined, index) => {

                                            /* return a single row populated with the correct year's figures */
                                            return (
                                                <PopulatedTableRow key={index} yearIndex={index} />
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};


// in the row data below there are some special prenames for fields:
// fieldbold_ adds a class that causes data to be displayed in bold

/**
 * Data for a row in the table
 */
export interface RowData {
    year: string,
    systemYield: string,
    onSiteUse: string,
    energyCosts: string,
    energyCostsWithoutSolar: string,
    solarEnergyCost: string,
    energyCostsWithSolar: string,
    fieldbold_annualSavings: string,
    fieldbold_cO2Savings: string,
}

