import { useCallback, useMemo, } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Table } from "reactstrap";
import { EmptyTableRows } from "../../../shared/EmptyTableRows";
import { YearCalculation, yearCalculationDefaultValues } from "../../utilities/useCalculateForYear";
import { formatNumber } from "../../utilities/useFormattedNumber";
import './valuationTab.scss';

export interface ValuationTabProps {
    inputsModel: any | undefined,
    calculationsModel: any | undefined,
    allYearCalculations: YearCalculation[],
    irrCalculations: { preTaxIRR: number | '#NUM!', postTaxIRR: number | '#NUM!' }
}

/**
 * Tab for maintaining the valuation details - Valuation, Working Capital, and Depreciation.
 * @param props
 */
export const ValuationTab = (props: ValuationTabProps) => {
    const {
        inputsModel, // all inputs
        calculationsModel, // holds the bottom level calculations created by the calculator from the inputs
        allYearCalculations, // holds all the calculations for all years of the proposal including year zero
        irrCalculations, // pre and post tax irr calculations
   } = props;

    const { t } = useTranslation();

    // Number of years for full calculations - excludes year 0
    // this is just for the financials tab and is different from the proposal years
    const yearColumnsRequired = 30;

    // Arrays of items to show one on each row in the header column - the first column in the tables
    // One array for each table
    const headerColumnText = useMemo(() => {
        return {
            valuation: [
                t('valuationTab.headerColumn.ebitda', 'EBITDA'),
                t('valuationTab.headerColumn.taxes', 'Taxes'),
                t('valuationTab.headerColumn.capex', 'Capex'),
                t('valuationTab.headerColumn.changeInWC', 'Change in WC'),
                t('valuationTab.headerColumn.fcfPostTax', 'FCF - post Tax'),
                t('valuationTab.headerColumn.irrPostTax', 'IRR - post Tax'),
                t('valuationTab.headerColumn.fcfPreTax', 'FCF - pre Tax'),
                t('valuationTab.headerColumn.irrPreTax', 'IRR - pre Tax'),
            ],
            workingCapital: [
                t('valuationTab.headerColumn.receivables', 'Receivables'),
                t('valuationTab.headerColumn.payables', 'Payables'),
                t('valuationTab.headerColumn.workingCapital', 'Working capital'),
            ],
            depreciation: [
                t('valuationTab.headerColumn.switch', 'Switch'),
                t('valuationTab.headerColumn.openingBalance', 'Opening Balance'),
                t('valuationTab.headerColumn.depreciation', 'Depreciation'),
                t('valuationTab.headerColumn.closingBalance', 'Closing Balance'),
            ]
        }
    }, [t]);

    // Arrays of items to show one on each row in the unit column - the second column in the tables
    // One array for each table
     const unitHeaderColumnText = useMemo(() => {
        return {
            valuation: [
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                '',
            ],
            workingCapital: [
                '',
                '',
                '',
            ],
            depreciation: [
                '',
                t('common.gbp', 'GBP'),
                t('common.gbp', 'GBP'),
                t('common.gbp', 'GBP'),
            ]
        }
    }, [t]);

    // Arrays of all full years' data, formatted and converted to strings with '-' representing 0 for display fields
    // There will be an array for each table required, and an additional array for the calculation fields which remain as numbers and are not displayed
    const columnsYears = useMemo(() => {

        // data for valuation table
        let newValuationColumnData: Array<ValuationColumnData> = [];
        // data for working capital table
        let newWorkingCapitalColumnData: Array<WorkingCapitalColumnData> = [];
        // data for depreciation table
        let newDepreciationColumnData: Array<DepreciationColumnData> = [];
        // data for non-display calculation table
        let newCalculationColumnData: Array<CalculationColumnData> = [];

        // temporary array to hold previous year's figure to work out the current year for some calculations.
        let previousYearCalculations: YearCalculation = { ...yearCalculationDefaultValues() };

        // loop round creating a column for each full year
        for (let y = 1; y <= yearColumnsRequired; y++) {

            // this year's figures that are based on base calculations and inputs
            const yearCalculations = allYearCalculations[y];

            // calculations that rquire previous and current year calculations
            const changeInWC = !previousYearCalculations.workingCapital || !yearCalculations.workingCapital ? 0 : previousYearCalculations.workingCapital - yearCalculations.workingCapital;
            const fcfPostTax = yearCalculations.ebitda - yearCalculations.taxOutlay + changeInWC;
            const fcfPreTax = yearCalculations.ebitda + changeInWC;

            // make up a column for this year's figures for the valuations table
            let newValuationColumn: ValuationColumnData = ({
                headerline_ebitda: yearCalculations.ebitda > 0 ? formatNumber(Math.round(yearCalculations.ebitda)) : '-',
                headerline_taxes: yearCalculations.taxOutlay === 0 ? '-' : formatNumber(Math.round(yearCalculations.taxOutlay * -1)),
                headerline_capex: '',
                headerline_changeInWC: changeInWC === 0 ? '-' : formatNumber(Math.round(changeInWC)),
                headerline_fcfPostTax: fcfPostTax === 0 ? '-' : formatNumber(Math.round(fcfPostTax)),
                totalline_irrPostTax: '',
                headerline_fcfPreTax: fcfPreTax === 0 ? '-' : formatNumber(Math.round(fcfPreTax)),
                totalline_irrPreTax: '',
            });

            // make up a column for this year's figures for the working capital table
            let newWorkingCapitalColumn: WorkingCapitalColumnData = ({
                receivables: yearCalculations.receivables > 0 ? formatNumber(Math.round(yearCalculations.receivables)) : '-',
                payables: yearCalculations.payables > 0 ? formatNumber(Math.round(yearCalculations.payables)) : '-',
                workingCapital: yearCalculations.workingCapital > 0 ? formatNumber(Math.round(yearCalculations.workingCapital)) : '-',
            });

            // make up a column for this year's figures for the depreciation table
            let newDepreciationColumn: DepreciationColumnData = ({
                fieldcenter_switch: inputsModel?.depreciationYears >= y ? t('common.true', 'TRUE') : t('common.false', 'FALSE'),
                openingBalance: yearCalculations.openingBalance > 0 ? formatNumber(Math.round(yearCalculations.openingBalance)) : '-',
                depreciation: inputsModel?.depreciationYears >= y ? formatNumber(Math.round(calculationsModel?.depreciation)) : '-',
                closingBalance: yearCalculations.closingBalance > 0 ? formatNumber(Math.round(yearCalculations.closingBalance)) : '-',
            });

            // store the non-display calculation fields
            let newCalculationColumn: CalculationColumnData = ({
                calculation_fcfPostTax: fcfPostTax,
                calculation_fcfPreTax: fcfPreTax
            });

            // add the new columns to each of the table data arrays 
            newValuationColumnData.push(newValuationColumn);
            newWorkingCapitalColumnData.push(newWorkingCapitalColumn);
            newDepreciationColumnData.push(newDepreciationColumn);
            newCalculationColumnData.push(newCalculationColumn);

            // update the temporary previous years data 
            previousYearCalculations = yearCalculations;

        }

        // return an array for each table holding a column for each year required
        return { valuationColumnData: newValuationColumnData, workingCapitalColumnData: newWorkingCapitalColumnData, depreciationColumnData: newDepreciationColumnData, calculationColumnData: newCalculationColumnData };
    }, [inputsModel, calculationsModel, t, allYearCalculations, ]);

    // figures needed for the 0 year column - a full years calculations is not required until year 1
    // one array for each table
    const columnYearZero = useMemo(() => {

        return {
            valuation: [
                '', // ebitda
                '', //taxes
                !calculationsModel?.totalCapex2 ? '-' : formatNumber(Math.round(calculationsModel?.totalCapex2 * -1)), //capex
                '', //changeInWC
                !calculationsModel?.totalCapex2 ? '-' : formatNumber(Math.round(calculationsModel?.totalCapex2 * -1)), //fcfPostTax
                irrCalculations.postTaxIRR === '#NUM!' ? '0.00%' : formatNumber(irrCalculations.postTaxIRR, { isDecimals: true, isPercentage: true }), //irrPostTax
                !calculationsModel?.totalCapex2 ? '-' : formatNumber(Math.round(calculationsModel?.totalCapex2 * -1)), //fcfPreTax
                irrCalculations.preTaxIRR === '#NUM!' ? '0.00%' : formatNumber(irrCalculations.preTaxIRR, { isDecimals: true, isPercentage: true }), //irrPreTax
            ],
            workingCapital: [
                '', //receivables
                '', //payables
                '', //workingCapital
            ],
            depreciation: [
                '', //switch
                '', //openingBalance
                '', //depreciation
                '' //closingBalance
            ]
        };
    }, [calculationsModel, irrCalculations,]);


    // a way to work out the number of rows required in each table - excludes empty rows
    // one figure for each table
    const rows = useMemo(() => {
        if (!columnsYears.valuationColumnData.length || !columnsYears.workingCapitalColumnData.length || !columnsYears.depreciationColumnData.length) {
            return { valuation: 0, workingCapital: 0, depreciation: 0 };
        }
        return {
            valuation: Object.keys(columnsYears.valuationColumnData[0]).length,
            workingCapital: Object.keys(columnsYears.workingCapitalColumnData[0]).length,
            depreciation: Object.keys(columnsYears.depreciationColumnData[0]).length
        };
    }, [columnsYears]);

    // empty rows to be generated - key is index of row before, value is number of empty rows required
    // one array for each table
    const emptyRowsToBeGenerated: { valuation: { key: number, value: number }[], workingCapital: { key: number, value: number }[], depreciation: { key: number, value: number }[], } = useMemo(() => {
        return {
            valuation: [
                { key: 3, value: 1 },
                { key: 5, value: 2 },
            ],
            workingCapital: [
            ],
            depreciation: [
           ]
        };
    }, []);

    // a populated table row of as many columns as needed together with empty rows as appropriate
    const PopulatedTableRow = useCallback((props: { fullFinancialYearColumns: number, columnDataFieldIndex: number, tableName: string }) => {
        const {
            fullFinancialYearColumns, columnDataFieldIndex, tableName,
        } = props;

        // we need to get the field name from the right interface for the table being created to check for special processing
        // and at the same time we'll get the year zero figures for the right table and the emoty rows
        let checkFieldName = '';
        let thisYearZero: Array<string> = [];
        let emptyRows = 0;
        if (tableName === 'Valuation') {
            checkFieldName = Object.keys(columnsYears.valuationColumnData[0])[columnDataFieldIndex];
            thisYearZero = columnYearZero.valuation;
            emptyRows = emptyRowsToBeGenerated.valuation.find(item => item.key === columnDataFieldIndex)?.value ?? 0
        } else if (tableName === 'WorkingCapital') {
            checkFieldName = Object.keys(columnsYears.workingCapitalColumnData[0])[columnDataFieldIndex];
            thisYearZero = columnYearZero.workingCapital;
            emptyRows = emptyRowsToBeGenerated.workingCapital.find(item => item.key === columnDataFieldIndex)?.value ?? 0
        } else if (tableName === 'Depreciation') {
            checkFieldName = Object.keys(columnsYears.depreciationColumnData[0])[columnDataFieldIndex];
            thisYearZero = columnYearZero.depreciation;
            emptyRows = emptyRowsToBeGenerated.depreciation.find(item => item.key === columnDataFieldIndex)?.value ?? 0
        }

        // check for any of the special cases defined by the prefix to the field name
        // we can use special line prefixes on the tr, or the td if they are to be applied selectively and not every cell on the row
        let thisRowClassName = '';
        if (checkFieldName.startsWith('totalline_')) {
            /* total line has colored background and bold text and double underline */
            thisRowClassName = 'totalline';
        } else if (checkFieldName.startsWith('headerline_')) {
            /* header with bold text */
            thisRowClassName = 'headerline'
        }

        // apply special field prefixes to the td when required 
        let thisFieldClassName = 'yearColumn';
        if (checkFieldName.startsWith('fieldcenter_')) {
            /* align text centrally */
            thisFieldClassName += ' fieldcenter';
        }

        // the populated row with any empty rows
        return (
            <>
                <tr>
                    {/* header column */}
                    <td className={thisRowClassName}>
                        {
                            tableName === 'Valuation' ? headerColumnText.valuation[columnDataFieldIndex]
                                : tableName === 'WorkingCapital' ? headerColumnText.workingCapital[columnDataFieldIndex]
                                    : headerColumnText.depreciation[columnDataFieldIndex]
                        }


                    </td>
                    {/* sub-header column */}
                    <td className={thisRowClassName}>
                        {
                            tableName === 'Valuation' ? unitHeaderColumnText.valuation[columnDataFieldIndex]
                                : tableName === 'WorkingCapital' ? unitHeaderColumnText.workingCapital[columnDataFieldIndex]
                                    : unitHeaderColumnText.depreciation[columnDataFieldIndex]
                        }
                    </td>
                    {/* year zero column */}
                    <td className={thisFieldClassName + ' ' + thisRowClassName}>{thisYearZero[columnDataFieldIndex]}</td>
                    {
                        /* full financial column for a year */
                        [...Array(fullFinancialYearColumns)].map((value: undefined, index) => {

                            return (
                                <td className={thisFieldClassName} key={index}>
                                    {
                                        tableName === 'Valuation' ? Object.values(columnsYears.valuationColumnData[index])[columnDataFieldIndex]
                                            : tableName === 'WorkingCapital' ? Object.values(columnsYears.workingCapitalColumnData[index])[columnDataFieldIndex]
                                                : Object.values(columnsYears.depreciationColumnData[index])[columnDataFieldIndex]
                                    }
                                </td>
                            );
                        })
                    }
                </tr>
                <EmptyTableRows rows={emptyRows} columns={33} />
            </>
        )
    }, [headerColumnText, unitHeaderColumnText, columnsYears, emptyRowsToBeGenerated, columnYearZero,]);

    /** UI
     **/
    return (
        <>
            <Row className="valuation-container">
                <Col>
                    <div className="valuation">
                        <div className="table-responsive">

                            {/* Valuation Table */}
                            <Table className="table-striped table-bordered">
                                <thead className="thead-dark">
                                    <tr>
                                        <th className="headerColumn">{t('valuationTab.headerColumn.valuation', 'Valuation')}</th>
                                        <th className="unitColumn"> {/*intentionally blank*/}</th>
                                        {
                                            /* one column for each of the numbered years plus a first one for year 0 */
                                            [...Array(yearColumnsRequired + 1)].map((value: undefined, index) => {
                                                return (
                                                    <th className="yearColumn" key={index}>
                                                        {index}
                                                    </th>
                                                );
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        [...Array(rows.valuation)].map((value: undefined, index) => {

                                            /* could return a single row or a row plus a number of empty rows */ 
                                            return (
                                                <PopulatedTableRow key={index} fullFinancialYearColumns={yearColumnsRequired} columnDataFieldIndex={index} tableName={'Valuation'} />
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>

                            {/* Working Capital Table */}
                            <Table className="table-striped table-bordered">
                                <thead className="thead-dark">
                                    <tr>
                                        <th className="headerColumn">{t('valuationTab.headerColumn.workingCapitalCalcs', 'Working Capital')}</th>
                                        <th className="unitColumn"> {/*intentionally blank*/}</th>
                                        {
                                            /* one column for each of the numbered years plus a first one for year 0 */
                                            [...Array(yearColumnsRequired + 1)].map((value: undefined, index) => {
                                                return (
                                                    <th className="yearColumn" key={index}>
                                                        {index}
                                                    </th>
                                                );
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        [...Array(rows.workingCapital)].map((value: undefined, index) => {

                                            /* could return a single row or a row plus a number of empty rows */ 
                                            return (
                                                <PopulatedTableRow key={index} fullFinancialYearColumns={yearColumnsRequired} columnDataFieldIndex={index} tableName={'WorkingCapital'} />
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>

                            {/* Depreciation Table */}
                            <Table className="table-striped table-bordered">
                                <thead className="thead-dark">
                                    <tr>
                                        <th className="headerColumn">{t('valuationTab.headerColumn.depreciation', 'Depreciation')}</th>
                                        <th className="unitColumn"> {/*intentionally blank*/}</th>
                                        {
                                            /* one column for each of the numbered years plus a first one for year 0 */
                                            [...Array(yearColumnsRequired + 1)].map((value: undefined, index) => {
                                                return (
                                                    <th className="yearColumn" key={index}>
                                                        {index}
                                                    </th>
                                                );
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        [...Array(rows.depreciation)].map((value: undefined, index) => {

                                            /* could return a single row or a row plus a number of empty rows */ 
                                            return (
                                                <PopulatedTableRow key={index} fullFinancialYearColumns={yearColumnsRequired} columnDataFieldIndex={index} tableName={'Depreciation'} />
                                            )
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
};


// in the column data below there are some special prenames for fields:
// fieldcenter_ adds a class that causes data to be text align center when displayed in a table cell
// headerline_ adds a class that causes the row to be displayed as an internal header - usually just increasing the font weight
// totalline_ adds a class that causes the row to be displayed differently - usually background coplor and double underline
// calculation_ for calculation not display

/**
 * Data for a column in the valuation table
 */
export interface ValuationColumnData {
    headerline_ebitda: string,
    headerline_taxes: string,
    headerline_capex: string,
    headerline_changeInWC: string,
    headerline_fcfPostTax: string,
    totalline_irrPostTax: string,
    headerline_fcfPreTax: string,
    totalline_irrPreTax: string,
}
/**
 * Data for a column in the working capital table
 */
export interface WorkingCapitalColumnData {
    receivables: string,
    payables: string,
    workingCapital: string,
}
/**
 * Data for a column in the depreciation table
 */
export interface DepreciationColumnData {
    fieldcenter_switch: string,
    openingBalance: string,
    depreciation: string,
    closingBalance: string,
}
/**
 * Equivalent raw data for calculations on a column in the tables
 */
export interface CalculationColumnData {
    calculation_fcfPostTax: number, // used for IRR calc
    calculation_fcfPreTax: number, // used for IRR calc
}