import { NavItem, NavLink } from 'reactstrap';
import { AuthorizeContainer } from '../../shared/authorizeRoute';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLinkTrackActive } from '../shared/NavLinkTrackActive';
import { IdentityRoles } from '../../configure/security/IdentityRoles';

/**
 * Primary navigation menu for the app. 
 */
export const Navigation = () => {
    const { t } = useTranslation();

    return (
        <>
            <AuthorizeContainer>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/dashboard">
                        <FontAwesomeIcon icon="home" className="nav-icon"  />
                        <> {t('navigation.dashboard', 'Dashboard')}</>
                    </NavLink>
                </NavItem>
            </AuthorizeContainer>

            <AuthorizeContainer requireRole={IdentityRoles.Administration}>
                <NavItem>
                    <NavLink tag={NavLinkTrackActive} to="/administration">
                        <FontAwesomeIcon icon="cogs" className="nav-icon" />
                        <> {t('navigation.administration', 'Administration')}</>
                    </NavLink>
                </NavItem>
            </AuthorizeContainer>
        </>
    );
};
